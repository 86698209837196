import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";

import { Pump } from "../../../dtos/pump";
import { useMessage } from "../../messageService";
import MaterialTable, { HeaderCells } from "../../shared/materialTable";
import AddEditPumps from "./addEditPumpModal";
import { useApi } from "../../apiService";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../confirmDialogService";

const PumpsPage = () => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      {role === UserRole.User ? (
        <div>You do not have permissions to view this page.</div>
      ) : (
        <PumpsTable />
      )}
    </div>
  );
};

const PumpsTable = () => {
  const [pumps, setPumps] = useState<Pump[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { pumpsApi } = useApi();
  const { showMessage } = useMessage();
  const serial = new URLSearchParams(window.location.search).get("serial");
  const addPump = new URLSearchParams(window.location.search).get("add");

  const [selectedPump, setSelectedPump] = useState<Pump | null>(null);

  const addUpdatePump = (pump: Pump | null) => {
    setSelectedPump(pump);
    setShowAddNew(true);
  };

  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedPump(null);
    fetchData();
  };

  const tryDeletePump = (pump: Pump) => {
    showConfirmDialog(
      `Are you sure you want to delete pump${pump.name ? " " + pump.name : ""}?`
    ).then(confirmed => {
      if (confirmed) {
        pumpsApi.delete(pump.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("Pump deleted");
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  const fetchData = async () => {
    showMessage("Fetching pumps...");
    pumpsApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Pumps fetched!");
        setPumps(r.data);
      }
    });
  };

  useEffect(() => {
    if (serial || addPump === "true") {
      setShowAddNew(true);
    }
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<Pump>[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      accessor: h => h.name
    },
    {
      id: "serialNumber",
      numeric: false,
      disablePadding: false,
      label: "Serial No",
      accessor: h => h.serialNumber
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: true,
      label: "Customer",
      accessor: h => h.customerName
    },
    {
      id: "typeName",
      numeric: false,
      disablePadding: true,
      label: "Type",
      accessor: h => h.typeName
    }
  ];

  return (
    <>
      <MaterialTable
        title="Pumps"
        headerCells={headCells}
        rows={pumps}
        defaultRowsPerPage={5}
        showAddButton={true}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdatePump}
        handleDelete={tryDeletePump}
      />
      <AddEditPumps
        isVisible={showAddNew}
        close={finishOperation}
        pump={selectedPump}
        addPump={pumpsApi.postOne}
        updatePump={pumpsApi.putOne}
        newPump={serial}
      />
    </>
  );
};

export default PumpsPage;
