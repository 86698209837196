import React from "react";
import { Field } from "../../dtos/field";
import { Card, CardContent, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ApplicationRelativeRoutes } from "../..";
import { fieldStateDisplay, fieldStateDisplayColor } from "../../enums/fieldStatus";
import { THEME_VARIABLES } from "../../themeColors";

export const FieldCard = ({ field }: { field: Field }) => {
  const { fieldCard, currentEng, blue, green, red, gray } = useStyles(); 
  const statusColor = fieldStateDisplayColor(field.position !==null 
    ? field.position : undefined, 
    field.online !== null ? field.online : false);
  const statusName = fieldStateDisplay(field.position !==null 
    ? field.position : undefined, 
    field.online !== null ? field.online : false);
  const fieldTitle = field.name + ": " + statusName;

  let alarmClass: string;
  if (statusColor === THEME_VARIABLES.COLOR_GREEN) {
    alarmClass = green;
  } else if (statusColor === THEME_VARIABLES.COLOR_RED) {
    alarmClass = red;
  } else if (statusColor === THEME_VARIABLES.COLOR_BLUE_1) { 
    alarmClass = blue;
  } else {
    alarmClass = gray;
  }

  return (
    <Link
      to={`${ApplicationRelativeRoutes.PUMPS}/${field.pumpId}/${field.id}`}
      style={{ textDecoration: "none" }}
    >
      <Card key={field.id} className={fieldCard}>
        <CardContent>
          <Grid container justify="space-between">
            <div
              style={{
                color: statusColor
              }}
            >
              <header
                style={{
                  color: statusColor
                }}
              >
                {fieldTitle}
              </header>
              Pump Channel: {field.pumpChannel}
            </div>
            <div className={currentEng}>
              Valve Position {" "}
              <div className={alarmClass}>
                <span />
                {" " + field.position?.toFixed(0)} %
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

const useStyles = makeStyles({
  fieldCard: {
    margin: "10px 0",
    cursor: "pointer",
    "& header": {
      fontWeight: "bold"
    }
  },
  currentEng: {
    "& span": {
      display: "inline-block",
      backgroundColor: "#000",
      borderRadius: "5px",
      width: "10px",
      height: "10px"
    }
  },
  green: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_GREEN
    },
    color: THEME_VARIABLES.COLOR_GREEN
  },
  red: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_RED
    },
    color: THEME_VARIABLES.COLOR_RED
  },
  blue: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_BLUE_1
    },
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  gray: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_DARK_GRAY
    },
    color: THEME_VARIABLES.COLOR_DARK_GRAY
  },
});
