import React, { useState, useContext } from "react";
import { AuthContext } from "./authContext";
import { AjaxResult } from "../enums/ajaxResult";
import { useMessage } from "../components/messageService";
import * as Mui from "@material-ui/core";
import { EmailOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { ThemeOptions } from "@material-ui/core";

let logo = require("../images/aquarius-logo-large.svg");

export const ForgotUsernamePage = ({ close }: { close: () => void }) => {
  const { showMessage } = useMessage();
  const { authApi } = useContext(AuthContext);
  const [userEmail, setUserEmail] = useState("");
  const [errors, setErrors] = useState<string[]>([]);

  const sendUsernameEmail = () => {
    authApi.sendUsernameEmails(userEmail).then(r => {
      if (r.result === AjaxResult.Success) {
        showMessage(
          "An email has been sent with the Username"
        );
        setErrors([]);
      } else if (r.messages) {
        setErrors(r.messages);
      } else {
        setErrors(["ERROR: Failed to send email with Username"]);
      }
    });
  };

  const classes = useStyles();

  return (
    <div className={classes.loginWrapper}>
      <img className={classes.img} src={logo} alt="Logo" />
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault();
          sendUsernameEmail();
        }}
      >
        <div className={classes.title}>Forgot Username</div>
        <Mui.FormControl className={classes.inputBox} variant="outlined">
          <Mui.InputLabel htmlFor="outlined-adornment-userEmail">
            E-mail
          </Mui.InputLabel>
          <Mui.OutlinedInput
            id="outlined-adornment-userEmail"
            value={userEmail}
            onChange={event => setUserEmail(event.target.value)}
            endAdornment={
              <Mui.InputAdornment position="end">
                <EmailOutlined />
              </Mui.InputAdornment>
            }
            labelWidth={45}
          />
        </Mui.FormControl>
        <Mui.Button
          classes={{
            root: classes.loginButtonRoot,
            disabled: classes.loginButtonDisabled
          }}
          type="submit"
          variant="contained"
        >
          Send Username Email
        </Mui.Button>
        <Link to="/" style={{ textDecoration: "none" }}>
          Go Back
        </Link>
        {errors.length ? (
          <div>
            {errors.map(e => (
              <div key={e}>{e}</div>
            ))}
          </div>
        ) : null}
      </form>
    </div>
  );
};
const useStyles = Mui.makeStyles((theme: ThemeOptions) => ({
  loginWrapper: {
    backgroundColor: theme.mainBackgroundColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20%",
    minHeight: "100vh"
  },
  form: {
    maxWidth: "80%"
  },
  img: {
    marginBottom: "2em"
  },
  title: {
    color: theme.selectedItemColor,
    width: "100%",
    marginBottom: theme.standardMargin,
    fontWeight: "bold",
    fontSize: "1.25em"
  },

  inputBox: {
    backgroundColor: theme.whiteColor,
    width: "100%",
    marginBottom: "1em",
    "& input:-internal-autofill-selected": {
      backgroundColor: `${theme.whiteColor}`
    },
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.whiteColor} inset`
    }
  },
  loginButtonRoot: {
    minWidth: "100%",
    background:
      "transparent linear-gradient(97deg, #60bef6 0%, #2991fb 100%) 0% 0% no-repeat padding-box",
    color: `${theme.whiteColor}`,
    boxShadow: theme.standardBoxShadow,
    borderRadius: theme.shape?.borderRadius,
    opacity: 1,
    textTransform: "none",
    height: "3em",
    fontSize: "1em",
    marginBottom: "1em"
  },
  loginButtonDisabled: {
    "&.MuiButton-contained.Mui-disabled": {
      color: `${theme.whiteColor}`
    }
  },
  forgotPasswordButton: {
    color: theme.textColor,
    width: "100%",
    cursor: "pointer"
  }
}));
