import React, { useState, useEffect } from "react";
import { Field } from "../../../dtos/field";
import { makeStyles } from "@material-ui/core/styles";
import { Pump } from "../../../dtos/pump";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ApiResult } from "../../../rest/baseApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../apiService";
import { useMessage } from "../../messageService";
import { ErrorMessage } from "../../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  field: Field | null;
  addField: (field: Field) => Promise<ApiResult<any>>;
  updateField: (field: Field, id: number) => Promise<ApiResult<any>>;
  close: () => void;
  newFieldSerial?: string | null;
  onFieldSaved?: any;
}

const getDefaultField = () =>
({
  name: "",
  lat: 0,
  lon: 0,
  pumpId: 0,
  pumpChannel: 1
} as Field);

const AddEditField = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [field, setField] = useState<Field>(getDefaultField());

  const { pumpsApi } = useApi();
  const [pumps, setPumps] = useState<Pump[]>([]);
  const { showMessage } = useMessage();

  useEffect(() => {
    setField(props.field ? props.field : getDefaultField());
  }, [props.field]);

  useEffect(() => {
    if (!props.isVisible) {
      setField(getDefaultField());
      setMessages([]);
    }
  }, [props.isVisible]);

  useEffect(() => {
    const fetchPumpData = async () => {
      showMessage("Fetching pumps...");
      pumpsApi.getAll().then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Pumps fetched!");
          setPumps(r.data);
        }
      });
    };
    fetchPumpData();
  }, [showMessage, pumpsApi]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setField(f => ({ ...f, lat: position.coords.latitude, lon: position.coords.longitude }));
      });
    } else {
      console.log("can't get location.");
    }

    if (props.newFieldSerial != null) {
      const serialNumber = parseInt(props.newFieldSerial) || 0;
      setField(f => ({ ...f, valveSerialNumber: serialNumber }));
    }

  }, []);

  const addOrUpdateField = async () => {
    var result: ApiResult<any>;
    if (props.field) {
      result = await props.updateField(field, field.id);
    } else {
      result = await props.addField(field);
    }

    if (result.result === AjaxResult.Success) {
      props.close();

      if (props.onFieldSaved !== undefined) {
        // send back the id of the new field
        props.onFieldSaved(result.data);
      }
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editField = (operation: (c: Field) => void) => {
    var editedField: Field = { ...field };
    operation(editedField);
    setField(editedField);
  };

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${props.field ? "Update" : "Add"} Field`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="Field Name"
            variant="outlined"
            value={field.name}
            onChange={value => editField(f => (f.name = value.target.value))}
          />
          <TextField
            label="Crop Name"
            variant="outlined"
            value={field.crop}
            onChange={value => editField(f => (f.crop = value.target.value))}
          />
          <TextField
            label="Latitude"
            variant="outlined"
            type="number"
            value={field.lat}
            helperText={
              field.lat > -180 && field.lat < 180
                ? null
                : "Valid range -180...180"
            }
            onChange={value =>
              editField(f => (f.lat = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Longitude"
            variant="outlined"
            type="number"
            value={field.lon}
            helperText={
              field.lon > -180 && field.lon < 180
                ? null
                : "Valid range -180...180"
            }
            onChange={value =>
              editField(f => (f.lon = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Acreage"
            variant="outlined"
            type="number"
            value={field.acreage}
            onChange={value =>
              editField(f => (f.acreage = parseFloat(value.target.value)))
            }
          />
          <Autocomplete
            id="combo-box-pumps"
            options={pumps}
            getOptionLabel={(option: Pump) => option.name}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} label="Pump Name" variant="outlined" />
            )}
            value={pumps.find(p => p.id === field.pumpId)}
            onChange={(e: any, value: Pump | null) =>
              editField(p => (p.pumpId = value ? value.id : 0))
            }
          />
          <FormControl variant="outlined">
            <InputLabel id="role-select">Pump Channel</InputLabel>
            <Select
              labelId="role-select"
              label="Pump Channel"
              value={field.pumpChannel}
              onChange={(e: any) =>
                editField(c => (c.pumpChannel = parseInt(e.target.value)))
              }
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Valve SN"
            variant="outlined"
            type="number"
            value={field.valveSerialNumber}
            onChange={value =>
              editField(
                f => (f.valveSerialNumber = parseInt(value.target.value))
              )
            }
          />
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={field.pumpId === 0}
          onClick={addOrUpdateField}
        >
          {props.field ? "Update Field" : "Create Field"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default AddEditField;
