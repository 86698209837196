import { BaseApi } from "./baseApi";
import { SurgeLogix } from "../dtos/surgeLogix";

export class SurgeLogixApi extends BaseApi<SurgeLogix> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "surgeLogix", logout, enqueueSnackbar);
  }
  public getAllbyPump = (pumpId: number) =>
    this.genericRequest({
      extraPath: `${pumpId}/surgeLogix`,
      method: "GET"
    });
  public sendToPump = (pumpId: number) => {
    return this.genericRequest({
      extraPath: `${pumpId}/surgeLogix`,
      method: "POST"
    });
  };
}
