export function getPumpSensorUnitsLabel(pumpType: string) {
  switch(pumpType){
    case 'State':
      return 'State';
    case 'Speed':
      return 'rpm';
    case 'BatteryLevel':
      return 'V';
    case 'Flow': 
      return 'gpm';
    case 'AmbientTemp':
      return 'F';
    case 'WaterPressure': 
      return 'psi';
    case 'WaterTemp':
        return 'F';
    case 'OilPressure': 
      return 'psi';
    case 'FuelLevel':
      return 'gal';
    default:
      return pumpType;
  }
}
