import React from "react";
import { Pump } from "../../dtos/pump";
import { Card, CardContent, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ApplicationRelativeRoutes } from "../..";
import { unitStateDisplayColor, unitStateDisplay } from "../../enums/unitState";
import { convertFuelToPercentage, isFuelPump } from "../../utility/fuelLevel";

export const PumpCard = ({ pump }: { pump: Pump }) => {
  const { pumpCard, serviceHours, danger, normal, warning } = useStyles();
  const serviceInterval = pump.serviceInterval || 1;
  const hoursInService = pump.hoursInService || 0;
  let hoursClass: string;
  if (hoursInService < serviceInterval) {
    hoursClass = normal;
  } else if (hoursInService < serviceInterval) {
    hoursClass = warning;
  } else {
    hoursClass = danger;
  }
  const statusColor = unitStateDisplayColor(
    pump.unitState ? pump.unitState : 0
  );
  const pumpTitle =
    pump.name + ": " + unitStateDisplay(pump.unitState ? pump.unitState : 0);
  return (
    <Link
      to={`${ApplicationRelativeRoutes.PUMPS}/${pump.id}`}
      style={{ textDecoration: "none" }}
    >
      <Card key={pump.id} className={pumpCard}>
        <CardContent>
          <Grid container justify="space-between">
            <div
              style={{
                color: statusColor
              }}
            >
              <header
                style={{
                  color: statusColor
                }}
              >
                {pumpTitle}
              </header>
              {pump.serialNumber}
            </div>
            {/* {pump.hoursInService != undefined && */}
            <div className={serviceHours}>
              Serivce {"   "}
              <div className={hoursClass}>
                <span />
                {" " + (pump.hoursInService ?? 0)?.toFixed(2) + " hrs"}
              </div>
            </div>
          </Grid>
          <Grid container justify="space-between">
            {isFuelPump(pump) && (
              <>
                <div>Fuel level: <b>{convertFuelToPercentage(pump)}%</b></div>
              </>
            )}
            <div>
              <div>Session: <b>{(pump.shours / 60)?.toFixed(2)}</b> hrs</div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

const useStyles = makeStyles({
  pumpCard: {
    margin: "10px 0",
    cursor: "pointer",
    "& header": {
      fontWeight: "bold"
    }
  },
  serviceHours: {
    display: 'flex',
    "& span": {
      display: "inline-block",
      marginLeft: '2px',
      backgroundColor: "#000",
      borderRadius: "5px",
      width: "10px",
      height: "10px"
    }
  },
  normal: {
    "& span": {
      backgroundColor: "#0d0"
    },
    color: "#0d0"
  },
  warning: {
    "& span": {
      backgroundColor: "#dd0"
    },
    color: "#dd0"
  },
  danger: {
    "& span": {
      backgroundColor: "#d00"
    },
    color: "#d00"
  }
});
