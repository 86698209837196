import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";
import { Field } from "../../../dtos/field";
import { useMessage } from "../../messageService";
import MaterialTable, { HeaderCells } from "../../shared/materialTable";
import AddEditFields from "./addEditFieldsModal";
import { useApi } from "../../apiService";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../confirmDialogService";

const FieldsPage = () => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      {role === UserRole.Admin ||
        role === UserRole.Manager ||
        role === UserRole.Operator ? (
        <FieldsTable />
      ) : (
        <div>You do not have permissions to view this page.</div>
      )}
    </div>
  );
};

const FieldsTable = () => {
  const [fields, setFields] = useState<Field[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { fieldsApi } = useApi();
  const { showMessage } = useMessage();

  const [selectedField, setSelectedField] = useState<Field | null>(null);
  const serial = new URLSearchParams(window.location.search).get("serial");

  const addUpdateField = (field: Field | null) => {
    setSelectedField(field);
    setShowAddNew(true);
  };

  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedField(null);
    fetchData();
  };

  const tryDeleteField = (field: Field) => {
    showConfirmDialog(
      `Are you sure you want to delete access to ${field.name ? " " + field.name : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        fieldsApi.delete(field.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("Field deleted");
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  const fetchData = async () => {
    showMessage("Fetching fields...");
    fieldsApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Fields fetched!");
        setFields(r.data);
      }
    });
  };

  useEffect(() => {
    if (serial) {
      setShowAddNew(true);
    }
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<Field>[] = [
    {
      id: "pumpName",
      numeric: false,
      disablePadding: true,
      label: "Pump name",
      accessor: h => h.pumpName
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Field",
      accessor: h => h.name
    },
    {
      id: "crop",
      numeric: false,
      disablePadding: true,
      label: "Crop",
      accessor: h => h.crop
    },
    {
      id: "valveSerialNumber",
      numeric: true,
      disablePadding: false,
      label: "Valve SN",
      accessor: h => h.valveSerialNumber
    },
    {
      id: "pumpChannel",
      numeric: true,
      disablePadding: false,
      label: "Pump Channel",
      accessor: h => h.pumpChannel
    }
  ];

  return (
    <>
      <MaterialTable
        title="Fields"
        headerCells={headCells}
        rows={fields}
        defaultRowsPerPage={5}
        showAddButton={true}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdateField}
        handleDelete={tryDeleteField}
      />
      <AddEditFields
        isVisible={showAddNew}
        close={finishOperation}
        field={selectedField}
        addField={fieldsApi.postOne}
        updateField={fieldsApi.putOne}
        newFieldSerial={serial}
      />
    </>
  );
};

export default FieldsPage;
