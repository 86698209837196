import React, { useContext, useState, useEffect, useMemo } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";
import { AlarmMethod } from "../../../enums/alarmMethod";
import { Customer } from "../../../dtos/customer";

import { makeStyles } from "@material-ui/core/styles";
import { User } from "../../../dtos/user";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ApiResult } from "../../../rest/baseApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../apiService";
import { useMessage } from "../../messageService";
import { ErrorMessage } from "../../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  user: User | null;
  addUser: (user: User) => Promise<ApiResult<any>>;
  updateUser: (user: User, id: number) => Promise<ApiResult<any>>;
  close: () => void;
}

const getDefaultUser = () =>
  ({
    customerName: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNumber: "",
    msgLevel: 0,
    alarmMethod: AlarmMethod.None,
    role: "User"
  } as User);

const AddEditUsers = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [user, setUser] = useState<User>(getDefaultUser());
  const { role } = useContext(AuthContext);

  const { customersApi } = useApi();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { showMessage } = useMessage();

  const fetchData = async () => {
    showMessage("Fetching customers...");
    customersApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Customers fetched!");
        setCustomers(r.data);
      }
    });
  };

  useEffect(() => {
    setUser(props.user ? props.user : getDefaultUser());
  }, [props.user]);

  useEffect(() => {
    if (!props.isVisible) {
      setUser(getDefaultUser());
      setMessages([]);
    }
  }, [props.isVisible]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOrUpdateUser = async () => {
    var result: ApiResult<any>;
    if (props.user) {
      result = await props.updateUser(user, user.id);
    } else {
      result = await props.addUser(user);
    }

    if (result.result === AjaxResult.Success) {
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editUser = (operation: (c: User) => void) => {
    var editedUser: User = { ...user };
    operation(editedUser);
    setUser(editedUser);
  };

  const inputsValidated = useMemo(
    () =>
      parseInt(user.phoneNumber) > 999999999 &&
      parseInt(user.phoneNumber) < 10000000000 &&
      user.email.length > 0,
    [user]
  );

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${props.user ? "Update" : "Add"} User`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="First Name"
            variant="outlined"
            value={user.firstName}
            onChange={value =>
              editUser(c => (c.firstName = value.target.value))
            }
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={user.lastName}
            onChange={value => editUser(c => (c.lastName = value.target.value))}
          />
          <TextField
            label="Username"
            variant="outlined"
            value={user.username}
            onChange={value => editUser(c => (c.username = value.target.value))}
          />
          <TextField
            label="Email"
            variant="outlined"
            value={user.email}
            onChange={value => editUser(c => (c.email = value.target.value))}
          />
          <TextField
            label="Phone"
            variant="outlined"
            value={user.phoneNumber}
            helperText={
              parseInt(user.phoneNumber) > 999999999 &&
              parseInt(user.phoneNumber) < 10000000000
                ? null
                : "Invalid. Use Format: XXXXXXXXXX"
            }
            onChange={value =>
              editUser(c => (c.phoneNumber = value.target.value))
            }
          />
          <div />
          <FormControl variant="outlined">
            <InputLabel id="alarm-select">Alarm Method</InputLabel>
            <Select
              labelId="alarm-select"
              label="Alarm Method"
              value={user.alarmMethod}
              onChange={(e: any) =>
                editUser(c => (c.alarmMethod = e.target.value))
              }
            >
              <MenuItem value={AlarmMethod.None}>None</MenuItem>
              <MenuItem value={AlarmMethod.Email}>Email</MenuItem>
              <MenuItem value={AlarmMethod.SMS}>SMS</MenuItem>
              <MenuItem value={AlarmMethod.Both}>Both</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="alarm-level">Alarm Level</InputLabel>
            <Select
              labelId="alarm-Level"
              label="Alarm Level"
              value={user.msgLevel}
              onChange={(e: any) =>
                editUser(c => (c.msgLevel = e.target.value))
              }
            >
              <MenuItem value={0}>Disabled</MenuItem>
              <MenuItem value={1}>Receive Alarms 1-6</MenuItem>
              <MenuItem value={2}>Receive Alarms 2-6</MenuItem>
              <MenuItem value={3}>Receive Alarms 3-6</MenuItem>
              <MenuItem value={4}>Receive Alarms 4-6</MenuItem>
              <MenuItem value={5}>Receive Alarms 5-6</MenuItem>
              <MenuItem value={6}>Receive Alarm 6 Only</MenuItem>
            </Select>
          </FormControl>
          {role === UserRole.Admin && (
            <Autocomplete
              id="combo-box-demo"
              options={customers}
              getOptionLabel={(option: Customer) => option.name}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Customer Name"
                  variant="outlined"
                />
              )}
              value={customers.find(c => c.id === user.customerId)}
              onChange={(e: any, v: Customer | null) =>
                editUser(c => (c.customerId = v ? v.id : 0))
              }
            />
          )}
          <FormControl variant="outlined">
            <InputLabel id="role-select">User Role</InputLabel>
            <Select
              labelId="role-select"
              label="User Role"
              value={user.role}
              onChange={(e: any) => editUser(c => (c.role = e.target.value))}
            >
              <MenuItem value={UserRole.User}>{UserRole.User}</MenuItem>
              <MenuItem value={UserRole.Operator}>{UserRole.Operator}</MenuItem>
              <MenuItem value={UserRole.Manager}>{UserRole.Manager}</MenuItem>
              {role === UserRole.Admin && (
                <MenuItem value={UserRole.Admin}>{UserRole.Admin}</MenuItem>
              )}
            </Select>
          </FormControl>
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={addOrUpdateUser}
          disabled={!inputsValidated}
        >
          {props.user ? "Update User" : "Create User"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default AddEditUsers;
