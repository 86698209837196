import React from "react";

export const ClusterMarker = ({
  lat,
  lng,
  clusterCount
}: {
  lat: number;
  lng: number;
  clusterCount: number;
  totalCount?: number;
}) => {
  return (
    <div
      style={{
        color: "#fff",
        background: "#1978c8",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: `30px`,
        height: `30px`,
        position: "relative",
        right: "15px",
        bottom: "15px"
      }}
    >
      {clusterCount}
    </div>
  );
};
