import { useEffect } from "react";

export const useLeavingPageAlert = (hasUnsavedChanges: boolean) =>
  useEffect(() => {
    // declare event listener for warning user about unsaved changes
    const unloadEventListener = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      if (hasUnsavedChanges) {
        e.returnValue = "";
      }
    };
    // add event listener
    window.addEventListener("beforeunload", unloadEventListener);
    // return a function that removes the event listener. This will be called whenever
    // the "hasUnsavedChanges" value changes, or when the component using this hook is dismounted
    return () => {
      window.removeEventListener("beforeunload", unloadEventListener);
    };
    // by passing in "hasUnsavedChanges" as a dependency, we ensure that the effect always
    // has access to the latest value of "hasUnsavedChanges"
  }, [hasUnsavedChanges]);
