import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Box
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ApiResult } from "../../rest/baseApi";
import { AjaxResult } from "../../enums/ajaxResult";
import { ClosedLoop, getDefaultClosedLoop } from "../../dtos/closedLoop";
import { useApi } from "../apiService";
import { Field } from "../../dtos/field";
import { useMessage } from "../messageService";
import { Sensor } from "../../dtos/sensor";
import { ErrorMessage } from "../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  pumpId: number;
  closedLoop: ClosedLoop | null;
  addClosedLoop: (closedLoop: ClosedLoop) => Promise<ApiResult<any>>;
  updateClosedLoop: (
    closedLoop: ClosedLoop,
    id: number
  ) => Promise<ApiResult<any>>;
  close: () => void;
  markStepsChanged: () => void;
}

const AddEditCloseLoop = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [closedLoop, setClosedLoop] = useState<ClosedLoop>(
    getDefaultClosedLoop()
  );

  const [sessionType, setSessionType] = useState<string>('stopSensor');

  const { fieldsApi, sensorsApi } = useApi();
  const [fields, setFields] = useState<Field[]>([]);
  const [sensors, setSensors] = useState<Sensor[]>([]);

  const { showMessage } = useMessage();

  useEffect(() => {
    setClosedLoop(props.closedLoop ? props.closedLoop : getDefaultClosedLoop());
  }, [props.closedLoop]);

  useEffect(() => {
    if (!props.isVisible) {
      setClosedLoop(getDefaultClosedLoop());
      setMessages([]);
    }
  }, [props.isVisible]);

  useEffect(() => {
    const fetchFields = async () => {
      showMessage("Fetching Fields...");
      fieldsApi.getByPumpId(props.pumpId).then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Fields Fetched!");
          setFields(r.data);
        }
      });
    };
    if (props.pumpId) {
      fetchFields();
    }
  }, [showMessage, fieldsApi, props.pumpId, setFields]);

  useEffect(() => {
    const fetchSensor = async (fieldId: number) => {
      showMessage("Fetching sensors...");
      sensorsApi.getAllByField(fieldId).then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("sensors fetched!");
          setSensors(r.data);
        }
      });
    };
    if (closedLoop.fieldId) {
      fetchSensor(closedLoop.fieldId);
    }
  }, [showMessage, sensorsApi, setSensors, closedLoop.fieldId]);

  const startSensor = useMemo(() => {
    return sensors.find(f => f.id === closedLoop.startSensorId);
  }, [sensors, closedLoop.startSensorId]);

  const stopSensor = useMemo(() => {
    if (sessionType == 'timed') {
      console.log('Timed session')
      return { id: 101, value: "Timed" };
    }

    return sensors.find(f => f.id === closedLoop.stopSensorId);
  }, [sensors, closedLoop.stopSensorId]);

  const addOrUpdateClosedLoop = async () => {
    console.log(closedLoop);
    var result: ApiResult<any>;
    if (props.closedLoop?.id) {
      result = await props.updateClosedLoop(closedLoop, closedLoop.id);
    } else {
      result = await props.addClosedLoop(closedLoop);
    }

    if (result.result === AjaxResult.Success) {
      props.markStepsChanged();
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editClosedLoop = (operation: (c: ClosedLoop) => void) => {
    var editedClosedLoop: ClosedLoop = { ...closedLoop };
    operation(editedClosedLoop);
    setClosedLoop(editedClosedLoop);
  };

  var menuOptions = sensors.map(s => (
    <MenuItem value={s.id}>{s.name}</MenuItem>
  ));

  const switchStopType = (type: string) => {
    if (type === 'timed') {
      editClosedLoop(f => {
        f.stopSensorId = 101;
        f.stopValue = 60;
      })
    } else {
      editClosedLoop(f => {
        f.stopSensorId = null;
        f.stopValue = 0;
      })

    }
    setSessionType(type);
  }

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${props.closedLoop?.id ? "Update" : "Add"
        } ClosedLoop`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <Autocomplete
            id="combo-box-fields"
            options={fields}
            getOptionLabel={(option: Field) => option.name}
            style={{ width: 215 }}
            renderInput={params => (
              <TextField {...params} label="Field Name" variant="outlined" />
            )}
            value={fields.find(f => f.id === closedLoop.fieldId)}
            onChange={(e: any, value: Field | null) =>
              editClosedLoop(f => (f.fieldId = value ? value.id : 0))
            }
          />
          <FormControl variant="outlined">
            <InputLabel id="role-select">Start Sensor</InputLabel>
            <Select
              labelId="role-select"
              label="Start Sensor"
              value={startSensor ? startSensor.id : 0}
              onChange={(e: any) =>
                editClosedLoop(f => (f.startSensorId = e.target.value))
              }
            >
              {menuOptions}
            </Select>
          </FormControl>
          <TextField
            label="Start Value"
            variant="outlined"
            type="number"
            value={closedLoop.startValue}
            onChange={value =>
              editClosedLoop(
                f => (f.startValue = parseFloat(value.target.value))
              )
            }
          />

          <Box style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <ButtonGroup variant="outlined" >
              <Button
                variant={sessionType == 'stopSensor' ? 'contained' : 'outlined'}
                onClick={() => switchStopType('stopSensor')}
              >
                Stop Sensor
              </Button>
              <Button
                variant={sessionType == 'timed' ? 'contained' : 'outlined'}
                onClick={() => switchStopType('timed')}
              >
                Timed
              </Button>
            </ButtonGroup>
          </Box>
          {sessionType == 'stopSensor' ?
            (<>
              <FormControl variant="outlined">
                <InputLabel id="role-select">Stop Sensor</InputLabel>
                <Select
                  labelId="role-select"
                  label="Stop Sensor"
                  value={stopSensor ? stopSensor.id : 0}
                  onChange={(e: any) => editClosedLoop(f => (f.stopSensorId = e.target.value))
                  }
                >
                  {menuOptions}
                </Select>
              </FormControl>
              <TextField
                label="Stop Value"
                variant="outlined"
                type="number"
                value={closedLoop.stopValue}
                onChange={value =>
                  editClosedLoop(
                    f => (f.stopValue = parseFloat(value.target.value))
                  )
                }
              />
            </>) : (
              <FormControl
                style={{
                  width: '100%'
                }}
                variant="outlined">
                <TextField
                  label="Run Time"
                  variant="outlined"
                  type="number"
                  value={closedLoop.stopValue}
                  onChange={value =>
                    editClosedLoop(
                      f => (f.stopValue = parseFloat(value.target.value))
                    )
                  }
                />
              </FormControl>
            )
          }
        </form>
        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={
            !closedLoop.fieldId ||
            !closedLoop.startSensorId ||
            closedLoop.stopSensorId === null
          }
          onClick={addOrUpdateClosedLoop}
        >
          {props.closedLoop?.id ? "Update" : "Create ClosedLoop"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default AddEditCloseLoop;
