import * as mui from "@material-ui/core";
import React from "react";

export type MapViewType = "roadmap" | "satellite" | "hybrid" | "terrain";

export function MapTypeSelect({
  mapViewType,
  setMapViewType,
  className,
  isHorizontal
}: {
  mapViewType: MapViewType;
  setMapViewType: React.Dispatch<React.SetStateAction<MapViewType>>;
  className: string;
  isHorizontal: boolean;
}) {
  return (
    <mui.FormControl component="fieldset" className={className}>
      <mui.RadioGroup
        row={isHorizontal}
        value={mapViewType}
        onChange={e => setMapViewType(e.currentTarget.value as MapViewType)}
      >
        <mui.FormControlLabel
          value="roadmap"
          control={<mui.Radio />}
          label="Normal"
          labelPlacement = "start"
        />
        <mui.FormControlLabel
          value="satellite"
          control={<mui.Radio />}
          label="Satellite"
          labelPlacement = "start"
        />
        <mui.FormControlLabel
          value="hybrid"
          control={<mui.Radio />}
          label="Hybrid"
          labelPlacement = "start"
        />
        <mui.FormControlLabel
          value="terrain"
          control={<mui.Radio />}
          label="Terrain"
          labelPlacement = "start"
        />
      </mui.RadioGroup>
    </mui.FormControl>
  );
}
