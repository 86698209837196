import { BaseApi, ApiResult } from "./baseApi";
import { UnitData } from "../dtos/unitData";

export class UnitDataApi extends BaseApi<UnitData> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "unitData", logout, enqueueSnackbar);
  }

  public getBySerialNumber = (serialNumber: number) =>
    this.genericRequest({
      extraPath: serialNumber.toString(),
      method: "GET"
    });

  public getLatestBySerialNumber = (serialNumber: number) =>
    this.genericRequest({
      extraPath: `${serialNumber}/latest`,
      method: "GET"
    }) as Promise<ApiResult<UnitData>>;
}
