export function defer() {
  var deferred = {
    promise: null as Promise<boolean> | null,
    resolve: null as ((value?: boolean) => void) | null,
    reject: null as ((reason?: any) => void) | null
  };

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}
