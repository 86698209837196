import React, { useContext } from "react";
import { AuthContext } from "../../auth/authContext";
import { UserRole } from "../../enums/userRoles";
import TileButton from "./tileButton";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PeopleIcon from "@material-ui/icons/People";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import { makeStyles, ThemeOptions } from "@material-ui/core/styles";
//import Globals from "../../globals";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CustomersPage from "./customers/customersPage";
import UsersPage from "./users/usersPage";
import PumpsPage from "./pumps/pumpsPage";
import PumpAccessPage from "./pumpAccess/pumpAccessPage";
import PumpTypesPage from "./pumpTypes/pumpTypesPage";
import FieldsPage from "./fields/fieldsPage";
import { ApplicationRelativeRoutes } from "../..";

const AdminPage = () => {
  const match = useRouteMatch();
  const { role } = useContext(AuthContext);

  const classes = useStyles();

  if (
    role !== UserRole.Admin &&
    role !== UserRole.Operator &&
    role !== UserRole.Manager
  ) {
    return <div>You do not have permissions to view this page.</div>;
  }

  return (
    <Switch>
      <Route path={`${match.url}${ApplicationRelativeRoutes.CUSTOMERS}`}>
        <CustomersPage />
      </Route>
      <Route path={`${match.url}${ApplicationRelativeRoutes.USERS}`}>
        <UsersPage />
      </Route>
      <Route path={`${match.url}${ApplicationRelativeRoutes.PUMPS}`}>
        <PumpsPage />
      </Route>
      <Route path={`${match.url}${ApplicationRelativeRoutes.PUMPACCESS}`}>
        <PumpAccessPage />
      </Route>
      <Route path={`${match.url}${ApplicationRelativeRoutes.PUMPTYPES}`}>
        <PumpTypesPage />
      </Route>
      <Route path={`${match.url}${ApplicationRelativeRoutes.FIELDS}`}>
        <FieldsPage />
      </Route>
      <Route>
        <label className={classes.sectionHeader}>Admin Page</label>
        <div className={classes.buttonContainer}>
          {role === UserRole.Admin && (
            <TileButton
              title="Customers"
              details="View and edit customers"
              icon={<HomeWorkIcon />}
              path={`${match.url}${ApplicationRelativeRoutes.CUSTOMERS}`}
            />
          )}
          <TileButton
            title="Users"
            details="View and edit users"
            icon={<PeopleIcon />}
            path={`${match.url}${ApplicationRelativeRoutes.USERS}`}
          />
          <TileButton
            title="Pumps"
            details="Add and edit pumps"
            icon={<InvertColorsIcon />}
            path={`${match.url}${ApplicationRelativeRoutes.PUMPS}`}
          />
          <TileButton
            title="Pump Access"
            details="View and edit pump acccess"
            icon={<DeviceHubIcon />}
            path={`${match.url}${ApplicationRelativeRoutes.PUMPACCESS}`}
          />
          <TileButton
            title="Fields"
            details="View and edit fields"
            icon={<NaturePeopleIcon />}
            path={`${match.url}${ApplicationRelativeRoutes.FIELDS}`}
          />
          {role === UserRole.Admin && (
            <TileButton
              title="Pump Types"
              details="Edit pump types"
              icon={<InvertColorsIcon />}
              path={`${match.url}${ApplicationRelativeRoutes.PUMPTYPES}`}
            />
          )}
        </div>
      </Route>
    </Switch>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  buttonContainer: {
    display: "flex",
    justifySelf: "center",
    flexWrap: "wrap"
  },
  sectionHeader: {
    color: theme.selectedItemColor,
    justifySelf: "start",
    fontSize: "1.25em",
    fontWeight: "bold",
    margin: theme.standardMargin
  }
}));

export default AdminPage;
