import { useMessage } from "../components/messageService";
import jwt_decode from "jwt-decode";
import React, { useMemo, useState, useCallback } from "react";
import { AuthApi } from "../rest/authApi";
import Globals from "../globals";
import { useHistory } from "react-router-dom";
import { AjaxResult } from "../enums/ajaxResult";
import { AuthContext } from "./authContext";

const useAuthSetup = () => {
  const history = useHistory();
  const { showMessage } = useMessage();
  const token = localStorage.getItem("token");
  let defaultUsername = null;
  let defaultEmail = null;
  let defaultRole: string | null = null;
  let defaultCustomerId: number | null = null;
  if (token !== null) {
    const { role, username, email, customerId } = getUserDataFromToken(
      token as string
    );

    defaultUsername = username;
    defaultEmail = email;
    defaultRole = role;
    defaultCustomerId = customerId;
  }

  const defaultUser = {
    role: defaultRole,
    username: defaultUsername,
    email: defaultEmail,
    customerId: defaultCustomerId
  };

  const [loggedInUser, setLoggedInUser] = useState<{
    username: string | null;
    role: string | null;
    email: string | null;
    customerId: number | null;
  }>(defaultUser);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpDate");
    setLoggedInUser({
      username: null,
      email: null,
      role: null,
      customerId: null
    });
    history.push("/");
    // eslint-disable-next-line
  }, []); // all the objects used in this callback should have a static reference, so I feel pretty safe ignoring the dependencies warning here

  const authApi = useMemo(
    () => new AuthApi(Globals.BASE_WEB_API_URI, showMessage, logout),
    [showMessage, logout]
  );

  const login = (creds: { username: string; password: string }) => {
    setLoginInProgress(true);
    authApi.getToken(creds.username, creds.password).then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        var { jwt, jwtExpiration } = r.data;
        localStorage.setItem("token", jwt);
        localStorage.setItem("tokenExpDate", jwtExpiration);
        const {
          username: uName,
          email: uEmail,
          role: uRole,
          customerId
        } = getUserDataFromToken(jwt);
        setLoggedInUser({
          username: uName,
          role: uRole,
          email: uEmail,
          customerId
        });
      } else if (r.result === AjaxResult.BadRequest) {
        showMessage("Incorrect username and/or password");
      } else {
        showMessage("Login failed for unknown reason");
      }
      setLoginInProgress(false);
    });
  };

  return {
    ...loggedInUser,
    logout,
    login,
    loginInProgress,
    authApi
  };
};

function getUserDataFromToken(jwt: string) {
  const decoded: any = jwt_decode(jwt);
  const usernameKey = Object.keys(decoded).find(k =>
    k.endsWith("nameidentifier")
  );
  const rolesKey = Object.keys(decoded).find(k => k.endsWith("role"));
  const emailKey = Object.keys(decoded).find(k => k.endsWith("emailaddress"));
  const email = decoded[emailKey as string] as string;
  const role = decoded[rolesKey as string] as string;
  const customerIdString = (decoded.customerId as string) || "";
  const customerId = !isNaN(parseInt(customerIdString))
    ? parseInt(customerIdString)
    : null;
  return {
    username: decoded[usernameKey as string] as string,
    email,
    role,
    customerId
  };
}

export const AuthService = ({ children }: { children?: any }) => {
  const val = useAuthSetup();
  return <AuthContext.Provider value={val}>{children}</AuthContext.Provider>;
};
