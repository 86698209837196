import React, { useEffect, useState } from 'react';
import * as Mui from '@material-ui/core';
import { ThemeOptions } from "@material-ui/core";
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { useApi } from '../components/apiService';
import { Customer } from '../dtos/customer';
import { User } from '../dtos/user';
import { AjaxResult } from "../enums/ajaxResult";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AlarmMethod } from "../enums/alarmMethod";

const logo = require("../images/aquarius-logo-large.svg");
export const SignUpForm = () => {
	const { customersApi, usersApi } = useApi();
	const [name, setName] = useState('');
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [alarmMethod, setAlarmMethod] = useState(AlarmMethod.None);
	const [messageLevel, setMessageLevel] = useState(0);

	const [disabled, setDisabled] = useState(false);
	const [submited, setSubmited] = useState(false);
	const [waiting, setWaiting] = useState(false);

	const checkRequiredFields = () => {
		const requiredFields = [name, address1, city, state, zip, phone, email];

		// check each field and assign a boolean value to each
		// const fieldChecks = requiredFields.map(field => {
		// 	return field.length > 0;
		// });
	}

	const submitData = async () => {
		// if (!checkRequiredFields()) {
		// 	console.log("missing required fields");
		// 	return;
		// }
		setWaiting(true);
		setDisabled(true);
		// make a fetch request 
		const url = 'https://localhost:5001/';
		const uri = 'customers';

		const customer: Customer = {
			"id": 123456,
			"custno": 1035,
			"name": name,
			"addr": address1,
			"addr2": address2,
			"city": city,
			"state": state,
			"zip": zip,
			"phone": phone,
			"contact": name,
			"email": email,
			"lat": 0,
			"lon": 0,
			"apiKey": "string"
		};

		const response = await customersApi.createCustomer(customer);
		console.log('response: ', response.data);

		// setSubmited(true);

		const data = response.data;

		const user: User = {
			"id": -1,
			"customerId": data.id,
			"customerName": name,
			"role": "Manager",
			"username": email,
			"firstName": name.split(' ')[0],
			"lastName": name.split(' ')[1],
			"email": email,
			"phoneNumber": phone,
			"msgLevel": messageLevel,
			"alarmMethod": alarmMethod,
		};

		// now create the user
		const userResponse = await usersApi.createUser(user);
		console.log('userResponse: ', userResponse);
		setSubmited(true);
	}

	const classes = useStyles();
	return (
		<div className={classes.loginWrapper}>
			<a href="https://www.aquarius.ag/" target="_blank" rel="noopener noreferrer">
				<img className={classes.img} src={logo} alt="Logo" />
			</a>
			{submited &&
				<div className={classes.text}>An email was sent to the email entered to set your password.</div>
			}
			{!submited &&
				<div>
					{/* <form
						className={classes.form}
						onSubmit={e => {
							e.preventDefault();
							submit();
						}}
					> */}
					<div>
						<div className={classes.title}>Sign Up</div>
						<Mui.FormControl variant="outlined" className={classes.inputBox}>
							<Mui.InputLabel style={{ border: "red" }} htmlFor="outlined-adornment-username">
								Name
							</Mui.InputLabel>
							<Mui.OutlinedInput
								id="outlined-adornment-username"
								labelWidth={50}
								value={name}
								disabled={disabled}
								onChange={event => setName(event.target.value)}
							/>
						</Mui.FormControl>
						<Mui.FormControl variant="outlined" className={classes.inputBox}>
							<Mui.InputLabel htmlFor="outlined-adornment-username">
								Address Line 1
							</Mui.InputLabel>
							<Mui.OutlinedInput
								id="outlined-adornment-username"
								labelWidth={110}
								value={address1}
								disabled={disabled}
								onChange={event => setAddress1(event.target.value)}
							/>
						</Mui.FormControl>
						<Mui.FormControl variant="outlined" className={classes.inputBox}>
							<Mui.InputLabel htmlFor="outlined-adornment-username">
								Address Line 2
							</Mui.InputLabel>
							<Mui.OutlinedInput
								id="outlined-adornment-username"
								labelWidth={110}
								value={address2}
								disabled={disabled}
								onChange={event => setAddress2(event.target.value)}
							/>
						</Mui.FormControl>
						<div className={classes.row}>
							<div className={[classes.width50, classes.mr1].join(' ')}>
								<Mui.FormControl variant="outlined" className={classes.inputBox}>
									<Mui.InputLabel htmlFor="outlined-adornment-username">
										City
									</Mui.InputLabel>
									<Mui.OutlinedInput
										id="outlined-adornment-username"
										labelWidth={30}
										value={city}
										disabled={disabled}
										onChange={event => setCity(event.target.value)}
									/>
								</Mui.FormControl>
							</div>
							<div className={[classes.width50, classes.ml1].join(' ')}>
								<Mui.FormControl variant="outlined" className={classes.inputBox}>
									<Mui.InputLabel htmlFor="outlined-adornment-username">
										State
									</Mui.InputLabel>
									<Mui.OutlinedInput
										id="outlined-adornment-username"
										labelWidth={40}
										value={state}
										disabled={disabled}
										onChange={event => setState(event.target.value)}
									/>
								</Mui.FormControl>
							</div>
						</div>
						<div className={classes.row}>
							<div className={[classes.width50, classes.mr1].join(' ')}>
								<Mui.FormControl variant="outlined" className={classes.inputBox}>
									<Mui.InputLabel htmlFor="outlined-adornment-username">
										Zip
									</Mui.InputLabel>
									<Mui.OutlinedInput
										id="outlined-adornment-username"
										labelWidth={30}
										value={zip}
										disabled={disabled}
										onChange={event => setZip(event.target.value)}
									/>
								</Mui.FormControl>
							</div>
							<div className={[classes.width50, classes.ml1].join(' ')}>
								<Mui.FormControl variant="outlined" className={classes.inputBox}>
									<Mui.InputLabel htmlFor="outlined-adornment-username">
										Phone
									</Mui.InputLabel>
									<Mui.OutlinedInput
										id="outlined-adornment-username"
										labelWidth={40}
										value={phone}
										disabled={disabled}
										onChange={event => setPhone(event.target.value)}
									/>
								</Mui.FormControl>
							</div>
						</div>
						<Mui.FormControl variant="outlined" className={classes.inputBox}>
							<Mui.InputLabel htmlFor="outlined-adornment-username">
								Email
							</Mui.InputLabel>
							<Mui.OutlinedInput
								id="outlined-adornment-username"
								labelWidth={40}
								value={email}
								disabled={disabled}
								onChange={event => setEmail(event.target.value)}
							/>
						</Mui.FormControl>
						<div className={classes.row}>
							<div className={[classes.width50, classes.mr1].join(' ')}>
								<Mui.FormControl variant="outlined" className={classes.inputBox}>
									<Mui.InputLabel id="alarm-select">Alarm Method</Mui.InputLabel>
									<Mui.Select
										labelId="alarm-select"
										label="Alarm Method"
										value={alarmMethod}
										onChange={(e: any) =>
											setAlarmMethod(e.target.value)
										}
									>
										<Mui.MenuItem value={AlarmMethod.None}>None</Mui.MenuItem>
										<Mui.MenuItem value={AlarmMethod.Email}>Email</Mui.MenuItem>
										<Mui.MenuItem value={AlarmMethod.SMS}>SMS</Mui.MenuItem>
										<Mui.MenuItem value={AlarmMethod.Both}>Both</Mui.MenuItem>
									</Mui.Select>
								</Mui.FormControl>
							</div>
							<div className={[classes.width50, classes.ml1].join(' ')}>
								<Mui.FormControl variant="outlined" className={classes.inputBox}>
									<Mui.InputLabel id="alarm-level">Alarm Level</Mui.InputLabel>
									<Mui.Select
										labelId="alarm-Level"
										label="Alarm Level"
										value={messageLevel}
										onChange={(e: any) =>
											setMessageLevel(e.target.value)
										}
									>
										<Mui.MenuItem value={0}>Disabled</Mui.MenuItem>
										<Mui.MenuItem value={1}>Receive Alarms 1-6</Mui.MenuItem>
										<Mui.MenuItem value={2}>Receive Alarms 2-6</Mui.MenuItem>
										<Mui.MenuItem value={3}>Receive Alarms 3-6</Mui.MenuItem>
										<Mui.MenuItem value={4}>Receive Alarms 4-6</Mui.MenuItem>
										<Mui.MenuItem value={5}>Receive Alarms 5-6</Mui.MenuItem>
										<Mui.MenuItem value={6}>Receive Alarm 6 Only</Mui.MenuItem>
									</Mui.Select>
								</Mui.FormControl>
							</div>
						</div>
						{!waiting ? <Mui.Button
							classes={{
								root: classes.loginButtonRoot,
								disabled: classes.loginButtonDisabled
							}}
							type="submit"
							variant="contained"
							onClick={submitData}
						>
							{!submited && <>Sign Up</>}
							{submited && <>Signing Up...</>}
						</Mui.Button>
							:
							<div className={[classes.row, classes.justifyCenter].join(' ')}><CircularProgress /></div>}
					</div>
				</div>
			}
		</div>
	);
};

const useStyles = Mui.makeStyles((theme: ThemeOptions) => ({
	row: {
		display: "flex",
		justifyContent: "space-between"
	},
	justifyCenter: {
		justifyContent: "center"
	},
	width45: {
		width: "45%"
	},
	width50: {
		width: "50%"
	},
	width100: {
		width: "100%"
	},
	mr1: {
		marginRight: ".5em"
	},
	ml1: {
		marginLeft: ".5em"
	},
	loginWrapper: {
		backgroundColor: theme.mainBackgroundColor,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		minWidth: "20%",
		minHeight: "100vh"
	},
	inputBox: {
		backgroundColor: theme.whiteColor,
		width: "100%",
		marginBottom: theme.standardMargin,
		"& input:-internal-autofill-selected": {
			backgroundColor: `${theme.whiteColor}`
		},
		"& input:-webkit-autofill": {
			"-webkit-box-shadow": `0 0 0 30px ${theme.whiteColor} inset`
		}
	},
	form: {
		maxWidth: "80%",
		margin: "auto"
	},
	loginButtonRoot: {
		minWidth: "100%",
		background:
			"transparent linear-gradient(97deg, #60bef6 0%, #2991fb 100%) 0% 0% no-repeat padding-box",
		color: `${theme.whiteColor}`,
		boxShadow: theme.standardBoxShadow,
		borderRadius: theme.shape?.borderRadius,
		opacity: 1,
		textTransform: "none",
		height: "3em",
		fontSize: "1em",
		marginBottom: theme.standardMargin
	},
	loginButtonDisabled: {
		"&.MuiButton-contained.Mui-disabled": {
			color: `${theme.whiteColor}`
		}
	},
	img: {
		marginBottom: "2em"
	},
	title: {
		color: theme.selectedItemColor,
		width: "100%",
		marginBottom: theme.standardMargin,
		fontWeight: "bold",
		fontSize: "1.25em"
	},
	text: {
		color: theme.selectedItemColor,
		marginBottom: theme.standardMargin,
		fontWeight: "bold",
		fontSize: "1.25em"
	},
}));