import { BaseApi } from "./baseApi";
import { Field } from "../dtos/field";
import { FieldExportSummary } from "../dtos/fieldExportSummary";
import moment from "moment";

export class FieldsApi extends BaseApi<Field> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "fields", logout, enqueueSnackbar);
  }

  public getField = (fieldId: number) =>
    this.genericRequest({
      extraPath: `${fieldId}`,
      method: "GET"
    });

  public getByPumpId = (pumpId: number) =>
    this.genericRequest({
      extraPath: `${pumpId}/pumpFields`,
      method: "GET"
    });

  public updateFieldPageData = (
    fieldId: number,
    startDate: Date,
    endDate: Date
  ) => {
    return this.genericRequest({
      data: {
        startDate,
        endDate,
        timezoneOffsetMinutes: moment().utcOffset()
      },
      extraPath: `${fieldId}/fieldInfo`,
      method: "POST"
    });
  };

  public updateChartSetting = (
    fieldId: number,
    sensorId: number,
    isSelected: boolean
  ) =>
    this.genericRequest({
      data: { fieldId, sensorId, isSelected },
      extraPath: `updateChartSettings`,
      method: "POST"
    });

  public getFullFieldHisoricalData = (
    fieldId: number,
    fieldName: string,
    pumpName: string,
    startDate: Date,
    endDate: Date,
    downloadName: string,
    chartImageString: string | null,
    sensorIds: number[],
    exportType: "ZIP" | "CSV" | "PDF" | undefined,
    fieldExportSummary: FieldExportSummary | null
  ) => {
    return this.genericRequest({
      data: {
        fieldExportSummary,
        requestDates: {
          startDate,
          endDate,
          timezoneOffsetMinutes: moment().utcOffset()
        },
        downloadName,
        chartImageString,
        exportType,
        sensorIds,
        fieldName,
        pumpName
      },
      extraPath: `${fieldId}/fieldFullHistoricalData`,
      method: "POST",
      downloadFileFlag: true
    });
  };
}
