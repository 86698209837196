import React, { useState, useEffect } from "react";
import { PumpAccessDto } from "../../../dtos/pumpaccess";
import { makeStyles } from "@material-ui/core/styles";
import { User } from "../../../dtos/user";
import { Pump } from "../../../dtos/pump";
import { UserRole } from "../../../enums/userRoles";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ApiResult } from "../../../rest/baseApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../apiService";
import { useMessage } from "../../messageService";
import { UserOption } from "../../../dtos/userOption";
import * as linq from "linq";
import { ErrorMessage } from "../../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  pumpAccessDto: PumpAccessDto | null;
  pumpAccessDtos: PumpAccessDto[];
  addPumpAccess: (pumpAccessDto: PumpAccessDto) => Promise<ApiResult<any>>;
  updatePumpAccess: (
    pumpAccessDto: PumpAccessDto,
    id: number
  ) => Promise<ApiResult<any>>;
  close: () => void;
}

const getDefaultPumpAccess = () =>
  ({
    customerId: 0,
    customerName: "",
    username: "",
    userId: 0,
    pumpName: "",
    pumpId: 0,
    userOptionId: 0
  } as PumpAccessDto);

const AddEditPumpAccess = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [pumpAccess, setPumpAccess] = useState<PumpAccessDto>(
    getDefaultPumpAccess()
  );

  const { usersApi, pumpsApi } = useApi();
  const [users, setUsers] = useState<User[]>([]);
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [duplicateAccess, setDuplicateAccess] = useState(false);
  const [pumps, setPumps] = useState<Pump[]>([]);
  const { showMessage } = useMessage();

  const toggleDuplication = () => {
    setPumpAccess({
      ...pumpAccess,
      userOptionId: 0,
      pumpId: 0
    });
    setDuplicateAccess(!duplicateAccess);
  };

  const fetchUserData = async () => {
    showMessage("Fetching usernames...");
    usersApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Usernames fetched!");
        //Filter out non-Users
        let data = linq
          .from(r.data)
          .where(d => d.role === UserRole.User)
          .toArray();
        setUsers(data);
      }
    });
  };

  const fetchPumpData = async () => {
    showMessage("Fetching pumps...");
    pumpsApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Pumps fetched!");
        setPumps(r.data);
      }
    });
  };

  useEffect(() => {
    var optionsList = linq
      .from(props.pumpAccessDtos)
      .select(u => ({
        id: u.userId,
        username: u.username
      }))
      .distinct(u => u.id)
      .toArray();
    setUserOptions(optionsList);
  }, [props.pumpAccessDtos]);

  useEffect(() => {
    setPumpAccess(
      props.pumpAccessDto ? props.pumpAccessDto : getDefaultPumpAccess()
    );
  }, [props.pumpAccessDto]);

  useEffect(() => {
    if (!props.isVisible) {
      setPumpAccess(getDefaultPumpAccess());
      setMessages([]);
    }
  }, [props.isVisible]);

  useEffect(() => {
    fetchUserData();
    fetchPumpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOrUpdatePumpAccess = async () => {
    var result: ApiResult<any>;
    if (props.pumpAccessDto) {
      result = await props.updatePumpAccess(pumpAccess, pumpAccess.id);
    } else {
      result = await props.addPumpAccess(pumpAccess);
    }

    if (result.result === AjaxResult.Success) {
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }

    setPumpAccess(getDefaultPumpAccess());
  };

  const editPumpAcccess = (operation: (c: PumpAccessDto) => void) => {
    var editedPumpAccess: PumpAccessDto = { ...pumpAccess };
    operation(editedPumpAccess);
    setPumpAccess(editedPumpAccess);
  };

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${
        props.pumpAccessDto ? "Update" : "Add"
      } Pump Access`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <Autocomplete
            id="combo-box-users"
            options={users}
            getOptionLabel={(option: User) => option.username}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} label="User Name" variant="outlined" />
            )}
            value={users.find(p => p.id === pumpAccess.userId)}
            onChange={(e: any, value: User | null) =>
              editPumpAcccess(p => (p.userId = value ? value.id : 0))
            }
          />
          {props.pumpAccessDto === null && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={duplicateAccess}
                  onChange={toggleDuplication}
                  color="primary"
                  name="duplicate-access"
                />
              }
              label="Duplicate user access"
            />
          )}
          {duplicateAccess ? (
            <Autocomplete
              id="combo-box-dupicateAccess"
              key="combo-box-dupicateAccess"
              options={userOptions}
              getOptionLabel={(option: UserOption) => option.username}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Dublicate Access of User"
                  variant="outlined"
                />
              )}
              value={users.find(p => p.id === pumpAccess.userOptionId)}
              onChange={(e: any, value: UserOption | null) =>
                editPumpAcccess(p => (p.userOptionId = value ? value.id : 0))
              }
            />
          ) : (
            <Autocomplete
              id="combo-box-pumps"
              key="combo-box-pumps"
              options={pumps}
              getOptionLabel={(option: Pump) => option.name}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField {...params} label="Pump Name" variant="outlined" />
              )}
              value={pumps.find(p => p.id === pumpAccess.pumpId)}
              onChange={(e: any, value: Pump | null) =>
                editPumpAcccess(p => (p.pumpId = value ? value.id : 0))
              }
            />
          )}
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={addOrUpdatePumpAccess}
          disabled={
            duplicateAccess
              ? pumpAccess.userOptionId === 0
              : pumpAccess.pumpId === 0
          }
        >
          {props.pumpAccessDto ? "Update Pump Access" : "Create Pump Access"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  },
  checkbox: {}
}));

export default AddEditPumpAccess;
