export interface ClosedLoop {
  id: number;
  pumpChannel: number;
  fieldName: string;
  fieldId: number;
  startSensorId: number | null;
  startSensorName: string;
  startSensorSerialNumber: number | null;
  startSensorOffset: number | null;
  startValue: number | null;
  stopSensorId: number | null;
  stopSensorName: string;
  stopSensorSerialNumber: number | null;
  stopSensorOffset: number | null;
  stopValue: number | null;
}

export const getDefaultClosedLoop = () =>
({
  fieldId: 0,
  startSensorId: null,
  startValue: 0,
  stopValue: 0,
  stopSensorId: null
} as ClosedLoop);
