import * as mui from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import valveIconOpen from "../../images/valve-open.png";
import valveIconClose from "../../images/valve-close.png";
import valveIconDisabled from "../../images/valve-disabled.png";
import { Field } from "../../dtos/field";
import ThreeSixty from '@material-ui/icons/ThreeSixtyOutlined'

const useStyles = makeStyles((theme: Theme) => 
	createStyles({
		controlContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			'@media(min-width: 605px)': {
				flexDirection: 'row',
				alignItems: 'center'
			}
		},
		buttonContainer: {
			paddingLeft: 10,
			display: 'flex',
			'@media(min-width: 605px)': {
				paddingLeft: 'unset'
			}
		},
		controlOpen: {
			width: 40,
			height: 40
		},
		controlClose: {
			width: 40,
			height: 40
		},
		controlSliderContainer: {
			marginLeft: 10
		},
		controlSlider: {
			marginLeft: 10,
			minWidth: 200
		},
		percentControlRoot: {
			padding: '2px 4px',
			display: 'flex',
			alignItems: 'center',
			width: 100
		},
		percentControlInput: {
			marginLeft: theme.spacing(1),
			flex: 1
		},
		percentControlIconButton: {
			padding: 10
		},
		icon: {
			color: "inherit"
		},
	}));

export function ValveControl({
	field,
	handleValveChange,
	disabled
} : {
	field: Field;
	handleValveChange: any;
	disabled: boolean
}) {
	const classes = useStyles();
	const [value, setValue] = useState<number>(field.position);
	const [previousValue, setPreviousValue] = useState<number>(field.position);
	const [disableSetButton, setDisableSetButton] = useState<boolean>(true);

	// Update the valve position if it is updated via the auto update
	useEffect(() => {
		setValue(field.position);
		setPreviousValue(field.position);
	}, [field.position]);

	const handleOpenClick = () => {
		if (value !== 100) {
			setValue(100);
			handleValveChange(field.id, field.pumpChannel, 100);
		}
	};

	const handleCloseClick = () => {
		if (value !== 0) {
			setValue(0);
			handleValveChange(field.id, field.pumpChannel, 0);
		}
	};

	const handleSetValveClick = () => {
		handleValveChange(field.id, field.pumpChannel, value);
		setDisableSetButton(true);
		setPreviousValue(value);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = Number(event.target.value);
		if(newValue !== undefined 
			&& newValue !== null
			&& newValue !== value
			&& newValue >= 0 && newValue <= 100) {
			setValue(newValue);
			setDisableSetButton(newValue === previousValue);
		}
	};

	return (
	<div className={classes.controlContainer}>
		<div className={classes.buttonContainer}>
			<mui.Tooltip title="Open to 100%">
				<span>
					<mui.IconButton className={classes.controlOpen} onClick={handleOpenClick} disabled={disabled}>
						{
							disabled ?
								<img
									src={valveIconDisabled}
									alt="Disabled"
								/>
							:
								<img
									src={valveIconOpen}
									alt="Open"
								/>	
						}
					</mui.IconButton>
				</span>
			</mui.Tooltip>
			<mui.Tooltip title="Close">
				<span>
					<mui.IconButton className={classes.controlOpen} onClick={handleCloseClick} disabled={disabled}>
						{
							disabled ?
								<img
									src={valveIconDisabled}
									alt="Close"
								/>
							:
								<img
									src={valveIconClose}
									alt="Close"
								/>
						}
					</mui.IconButton>
				</span>
			</mui.Tooltip>
		</div>
		<div className={classes.controlSliderContainer}>
			<mui.Paper className={classes.percentControlRoot}>
				<mui.Input
					className={classes.percentControlInput}
					margin="dense"
					value={value}
					onChange={handleInputChange}
					disabled={disabled}
					inputProps={{
						step: 1,
						min: 0,
						max: 100,
						type: 'number'
					}}
				/>
				<mui.Tooltip title="Set Valve Gate %">
					<span>
						<mui.IconButton 
							color="primary"
							className={classes.percentControlIconButton}
							aria-label="set valve percentage"
							onClick={handleSetValveClick}
							disabled={disableSetButton || disabled}
						>
							<ThreeSixty className={classes.icon}/>
						</mui.IconButton>
					</span>
				</mui.Tooltip>
			</mui.Paper> 
		</div>
	  </div>
	);
}