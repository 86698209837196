import React, { useEffect, useState } from 'react';
import { Sensor } from "../../dtos/sensor";
import AddEditSensors from "../fieldPage/addEditSensorsModal";
import { useApi } from "../apiService";
import { useHistory } from "react-router-dom";

export const NewSensor = () => {
	const history = useHistory();
	// create a new sensor
	const { sensorsApi } = useApi();
	const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);
	const sensorSerial = Number(new URLSearchParams(window.location.search).get("serial"));
	useEffect(() => {
		// automatically set the location of the sensor
		setSelectedSensor((s: any) => {
			return { ...s, serial: Number(sensorSerial) };
		});
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setSelectedSensor((s: any) => {
					return { ...s, serial: sensorSerial, lat: position.coords.latitude, lon: position.coords.longitude };
				});
			});
		} else {
			setSelectedSensor((s: any) => {
				return { ...s, serial: Number(sensorSerial) }
			});
		}
	}, [sensorSerial]);

	const finishOperation = () => {
		// go back to home page
		history.push("/home");
		return false;
	};


	return (
		<div>
			<h1>New Sensor</h1>
			<AddEditSensors
				isVisible={true}
				close={finishOperation}
				sensor={selectedSensor}
				addSensor={sensorsApi.postOne}
				updateSensor={sensorsApi.putOne}
				deleteSensor={sensorsApi.delete}
				showFieldSection={true}
			/>
		</div>
	);
}