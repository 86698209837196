import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";

import { Customer } from "../../../dtos/customer";
import { useMessage } from "../../messageService";
import MaterialTable, { HeaderCells } from "../../shared/materialTable";
import AddEditCustomers from "./addEditCustomerModal";
import { useApi } from "../../apiService";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../confirmDialogService";

const CustomersPage = () => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      {role === UserRole.Admin ? (
        <CustomersTable />
      ) : (
        <div>You do not have permissions to view this page.</div>
      )}
    </div>
  );
};

const CustomersTable = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { customersApi } = useApi();
  const { showMessage } = useMessage();

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );

  const addUpdateCustomer = (customer: Customer | null) => {
    setSelectedCustomer(customer);
    setShowAddNew(true);
  };

  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedCustomer(null);
    fetchData();
  };

  const tryDeleteCustomer = (customer: Customer) => {
    showConfirmDialog(
      `Are you sure you want to delete customer${
        customer.name ? " " + customer.name : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        customersApi.delete(customer.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("Customer deleted");
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  const fetchData = async () => {
    showMessage("Fetching customers...");
    customersApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Customers fetched!");
        setCustomers(r.data);
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<Customer>[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Customer",
      accessor: h => h.name
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone",
      accessor: h => h.phone
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      accessor: h => h.email
    }
  ];

  return (
    <>
      <MaterialTable
        title="Customers"
        headerCells={headCells}
        rows={customers}
        defaultRowsPerPage={5}
        showAddButton={true}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdateCustomer}
        handleDelete={tryDeleteCustomer}
      />
      <AddEditCustomers
        isVisible={showAddNew}
        close={finishOperation}
        customer={selectedCustomer}
        addCustomer={customersApi.postOne}
        updateCustomer={customersApi.putOne}
      />
    </>
  );
};

export default CustomersPage;
