import { THEME_VARIABLES } from "../themeColors";
export enum SensorAlarmStatus {
  NoAlarmData = 0,
  Normal = 1,
  AlarmLow = 2,
  AlarmHigh = 3, 
  Offline = 4
}

const valueMap = {
  [SensorAlarmStatus.NoAlarmData] : { 
    name: "No Alarm", 
    color: THEME_VARIABLES.COLOR_DARK_GRAY
  },
  [SensorAlarmStatus.Normal] : { 
    name: "Normal", 
    color: THEME_VARIABLES.COLOR_GREEN
  },
  [SensorAlarmStatus.AlarmLow] : { 
    name: "Alarm Low", 
    color: THEME_VARIABLES.COLOR_RED
  },
  [SensorAlarmStatus.AlarmHigh] : { 
    name: "Alarm High", 
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [SensorAlarmStatus.Offline] : { 
    name: "Offline", 
    color: THEME_VARIABLES.COLOR_GRAY_1
  }
};
export const sensorStateDisplay = (state?: SensorAlarmStatus) =>
  state !== undefined && state !== null && valueMap[state]
    ? valueMap[state].name
    : state
    ? "State: " + state
    : "Unknown";

export const sensorStateDisplayColor = (state?: SensorAlarmStatus) =>
  state !== undefined && state !== null && valueMap[state]
    ? valueMap[state].color
    : THEME_VARIABLES.COLOR_RED;
