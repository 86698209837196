export default class Globals {
  public static BASE_WEB_API_URI: string =
    process.env.REACT_APP_BASE_API_URI || "";

  public static DEFAULT_MAP_ZOOM: number = parseInt(
    process.env.REACT_APP_DEFAULT_MAP_ZOOM || "17"
  );

  public static GOOGLE_MAPS_API_KEY: string =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
}
