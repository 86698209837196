import { BaseApi } from "./baseApi";
import { ClosedLoop } from "../dtos/closedLoop";

export class ClosedLoopApi extends BaseApi<ClosedLoop> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "closedLoop", logout, enqueueSnackbar);
  }
  public getAllbyPump = (pumpId: number) =>
    this.genericRequest({
      extraPath: `${pumpId}/closedLoop`,
      method: "GET"
    });
  public sendToPump = (pumpId: number) => {
    return this.genericRequest({
      extraPath: `${pumpId}/closedLoop`,
      method: "POST"
    });
  };
}
