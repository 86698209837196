import * as mui from "@material-ui/core";
import { HomeCard } from "../../enums/homeCard";
import React from "react";
import { Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: 1
  },
  rightAlign: {
    marginLeft: "auto"
  }
}));

export function CardTypeSelect({
  cardType,
  setCardType
}: {
  cardType: number;
  setCardType: React.Dispatch<React.SetStateAction<number>>;
}) {

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // Grab the card grid
    var cardGrid = document.getElementById('cardGrid');
    if(cardGrid) {
      // Force it to scroll to the top
      cardGrid.scrollTo({top: 0, behavior: 'smooth'});
    }
    setCardType(newValue);
  };

  const classes = useStyles();

  return (
  <mui.FormControl component="fieldset" className={classes.root}>
      <mui.Tabs 
      value={cardType}
      indicatorColor="primary"
      onChange={handleChange}
      className={classes.rightAlign}
    >
      <Tab value={HomeCard.Pumps} label="Pumps" />
      <Tab value={HomeCard.Fields} label="Valves" />
      <Tab value={HomeCard.Sensors} label="Sensors" />
      <Tab value={HomeCard.PumpWeather} label="Weather" />
    </mui.Tabs>
  </mui.FormControl>
  );
}
