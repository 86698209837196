import { BaseApi } from "./baseApi";
import { User } from "../dtos/user";

export class UsersApi extends BaseApi<User> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "users", logout, enqueueSnackbar);
  }

  public createUser = (user: User): Promise<any> =>
    this.genericRequest({
      method: "POST",
      data: user,
      ignoreRefreshFlow: true,
      extraPath: "signUp"
    });
}
