import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { PasswordResetPage } from "./auth/resetPasswordForm";
import { ForgotUsernamePage } from "./auth/forgotUsernameForm";
import { ForgotPasswordPage } from "./auth/forgotPasswordForm";
import AdminPage from "./components/admin/adminPage";
import PumpInformationPage from "./components/pumpPage/pumpInformationPage";
import { HomePage } from "./components/home";
import { FieldPage } from "./components/fieldPage/fieldPage";
import { PumpHistoryPage } from "./components/pumpHistoryPage/pumpHistoryPage";
import SurgeLogixPage from "./components/surgeLogix/surgeLogixPage";
import ClosedLoopPage from "./components/closedLoop/closedLoopPage";
import { NewSensor } from "./components/sensorPage/newSensor";

export enum ApplicationRelativeRoutes {
  PUMPS = "/pumps",
  FIELDS = "/fields",
  SENSORS = "/sensors",
  REPORTING = "/reporting",
  SETTINGS = "/settings",
  ADMIN = "/admin",
  PASSWORD_RESET = "/resetPassword",
  PASSWORD_FORGOT = "/forgotPassword",
  USERNAME_FORGOT = "/forgotUsername",
  USERS = "/users",
  CUSTOMERS = "/customers",
  PUMPACCESS = "/pumpaccess",
  PUMPTYPES = "/pumptypes",
  PUMPHISTORY = "/pumphistory",
  SURGELOGIX = "/surgeLogix",
  CLOSEDLOOP = "/closedLoop",
  NEW_SENSOR = "/sensors/new",
}

ReactDOM.render(
  <BrowserRouter>
    <App>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route
          path={`${ApplicationRelativeRoutes.PUMPS}/:pumpId${ApplicationRelativeRoutes.FIELDS}/:fieldId`}
        >
          <FieldPage />
        </Route>
        <Route
          path={`${ApplicationRelativeRoutes.PUMPS}/:pumpId${ApplicationRelativeRoutes.PUMPHISTORY}`}
        >
          <PumpHistoryPage />
        </Route>
        <Route
          path={`${ApplicationRelativeRoutes.PUMPS}/:pumpId${ApplicationRelativeRoutes.SURGELOGIX}`}
        >
          <SurgeLogixPage />
        </Route>
        <Route
          path={`${ApplicationRelativeRoutes.PUMPS}/:pumpId${ApplicationRelativeRoutes.CLOSEDLOOP}`}
        >
          <ClosedLoopPage />
        </Route>
        <Route path={`${ApplicationRelativeRoutes.PUMPS}/:pumpId`}>
          <PumpInformationPage />
        </Route>
        <Route
          path="/sensors/new"
        >
          <NewSensor />
        </Route>
        <Route path={ApplicationRelativeRoutes.SENSORS}>
          <div>Sensors</div>
        </Route>
        <Route path={ApplicationRelativeRoutes.REPORTING}>
          <div>Reporting</div>
        </Route>
        <Route path={ApplicationRelativeRoutes.SETTINGS}>
          <div>Settings</div>
        </Route>
        <Route path={ApplicationRelativeRoutes.ADMIN}>
          <AdminPage />
        </Route>
        <Route
          path={ApplicationRelativeRoutes.PASSWORD_RESET}
          component={PasswordResetPage}
        />
        <Route
          path={ApplicationRelativeRoutes.PASSWORD_FORGOT}
          component={ForgotPasswordPage}
        />
        <Route
          path={ApplicationRelativeRoutes.USERNAME_FORGOT}
          component={ForgotUsernamePage}
        />
        <Route component={HomePage} />
      </Switch>
    </App>
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
