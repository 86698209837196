import React from "react";
import { Sensor } from "../../dtos/sensor";
import { sensorStateDisplay, sensorStateDisplayColor } from "../../enums/sensorAlarmStatus";
import { Card, CardContent, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ApplicationRelativeRoutes } from "../..";
import { ThemeOptions } from "@material-ui/core";
import { THEME_VARIABLES } from "../../themeColors";


export const SensorCard = ({ sensor }: { sensor: Sensor }) => {
  const { sensorCard, currentEng, blue, green, red, gray } = useStyles();
  var alarmStatus = sensor.alarmStatus? sensor.alarmStatus : 0; 
  const statusColor = sensorStateDisplayColor(alarmStatus);
  const statusName = sensorStateDisplay(alarmStatus);

  let alarmClass: string;
  if (statusColor === THEME_VARIABLES.COLOR_GREEN) {
    alarmClass = green;
  } else if (statusColor === THEME_VARIABLES.COLOR_RED) {
    alarmClass = red;
  } else if (statusColor === THEME_VARIABLES.COLOR_BLUE_1) { 
    alarmClass = blue;
  } else {
    alarmClass = gray;
  }
  
  const sensorTitle = sensor.name + ": " + statusName;
  return (
    <Link
      to={`${ApplicationRelativeRoutes.PUMPS}/${sensor.pumpId}${ApplicationRelativeRoutes.FIELDS}/${sensor.fieldId}`}
      style={{ textDecoration: "none" }}
    >
      <Card key={sensor.id} className={sensorCard}>
        <CardContent>
          <Grid container justify="space-between">
            <div
              style={{
                color: statusColor
              }}
            >
              <header
                style={{
                  color: statusColor
                }}
              >
                {sensorTitle}
              </header>
              {sensor.serial}
            </div>
            <div className={currentEng}>
              Current Reading{" "}
              <div className={alarmClass}>
                <span />
                {" " + sensor.currentEngData?.toFixed(2)}
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  sensorCard: {
    margin: "10px 0",
    cursor: "pointer",
    "& header": {
      fontWeight: "bold"
    }
  },
  currentEng: {
    "& span": {
      display: "inline-block",
      backgroundColor: "#000",
      borderRadius: "5px",
      width: "10px",
      height: "10px"
    }
  },
  green: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_GREEN
    },
    color: THEME_VARIABLES.COLOR_GREEN
  },
  red: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_RED
    },
    color: THEME_VARIABLES.COLOR_RED
  },
  blue: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_BLUE_1
    },
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  gray: {
    "& span": {
      backgroundColor: THEME_VARIABLES.COLOR_DARK_GRAY
    },
    color: THEME_VARIABLES.COLOR_DARK_GRAY
  },
}));
