import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as mui from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import ScheduleIcon from "@material-ui/icons/Schedule";
import TimelineIcon from "@material-ui/icons/Timeline";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import SettingsIcon from "@material-ui/icons/Settings";
import { PumpDataLog } from "../../dtos/pumpDataLog";
import PumpSettingsModal from "./pumpSettingsModal";
import { unitStateDisplay, unitStateDisplayColor } from "../../enums/unitState";
import { useHistory } from "react-router-dom";
import { ApplicationRelativeRoutes } from "../..";
import { THEME_VARIABLES } from "../../themeColors";
import { convertFuelToPercentage, isFuelPump } from "../../utility/fuelLevel";
import { Grid } from "@material-ui/core";

interface PumpInformationProps {
  pumpId: number;
  pump: PumpDataLog | null;
  setRefreshPumpInfoCounter: React.Dispatch<React.SetStateAction<number>>;
  setRefreshFieldCounter: React.Dispatch<React.SetStateAction<number>>;
}

const types = ["electric", " auto off manual", "on off"];
const PumpInformation = (props: PumpInformationProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [pumpSettingsModalIsVisible, setPumpSettingsModalIsVisible] = useState(
    false
  );

  const [autoOpen, setAutoOpen] = useState(false);

  function openLink() {
    if (props.pump?.pump.weatherLink != null && props.pump?.pump.weatherLink !== "") {
      window.open(props.pump?.pump.weatherLink, "_blank");
    }
  }

  const autoToggle = () => {
    props.setRefreshPumpInfoCounter(0);
    props.setRefreshFieldCounter(0);
    setAutoOpen(!autoOpen);
  };

  const ButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={classes.root}>
      {props.pump && (
        <div className={classes.sectionHeader}>
          <mui.Grid container>
            <mui.Grid item xs={12} md={6}>
              <div className={classes.headerSection}>
                <label>{props.pump.pump ? props.pump.pump.name : "Pump "}</label>
                <div className={classes.serialNumber}>
                  {props.pump.pump.serialNumber}
                </div>
              </div>
            </mui.Grid>
            <mui.Grid item xs={12} md={6}>
              <div className={classes.headerSection}>
                <mui.Button
                  variant="contained"
                  className={classes.autoControlButton}
                  endIcon={<BrightnessAutoIcon />}
                  onClick={autoToggle}
                  ref={ButtonRef}
                >
                  Auto Control
                </mui.Button>
                <mui.Menu
                  id="simple menu"
                  keepMounted
                  open={autoOpen}
                  onClose={autoToggle}
                  anchorEl={ButtonRef.current}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <mui.MenuItem
                    onClick={() => {
                      props.setRefreshPumpInfoCounter(0);
                      props.setRefreshFieldCounter(0);
                      history.push(
                        `${ApplicationRelativeRoutes.PUMPS}/${props.pumpId}${ApplicationRelativeRoutes.SURGELOGIX}`
                      );
                    }}
                  >
                    <mui.ListItemIcon>
                      <ScheduleIcon fontSize="small" />
                    </mui.ListItemIcon>
                    <mui.ListItemText primary="surgeLogix" />
                  </mui.MenuItem>
                  <mui.MenuItem
                    onClick={() => {
                      props.setRefreshPumpInfoCounter(0);
                      props.setRefreshFieldCounter(0);
                      history.push(
                        `${ApplicationRelativeRoutes.PUMPS}/${props.pumpId}${ApplicationRelativeRoutes.CLOSEDLOOP}`
                      );
                    }}
                  >
                    <mui.ListItemIcon>
                      <CachedIcon fontSize="small" />
                    </mui.ListItemIcon>
                    <mui.ListItemText primary="ClosedLoop" />
                  </mui.MenuItem>
                </mui.Menu>
                {props.pump?.pump.weatherLink != null &&
                  props.pump?.pump.weatherLink !== "" && (
                    <mui.Button
                      variant="contained"
                      onClick={() => openLink()}
                      className={classes.localWeatherButton}
                      endIcon={<WbSunnyIcon />}
                    >
                      Local Weather
                    </mui.Button>
                  )}

                <mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.setRefreshPumpInfoCounter(0);
                    props.setRefreshFieldCounter(0);
                    setPumpSettingsModalIsVisible(true);
                  }}
                  className={classes.pumpSettingsButton}
                  endIcon={<SettingsIcon />}
                >
                  Pump Settings
                </mui.Button>
              </div>
            </mui.Grid>
          </mui.Grid>
        </div>
      )}
      {props.pump && (
        <mui.Card className={classes.informationRoot} variant="outlined">
          <StyledCardContent>
            <div className={classes.cardHeader}>
              <Grid justify="space-between" container alignItems="center">
                <Grid item>
                  <Grid container spacing={2} alignItems="center" >
                    <Grid item>
                      Status{"   "}
                      <span
                        style={{
                          color: props.pump.unitData
                            ? unitStateDisplayColor(props.pump.unitData?.state)
                            : THEME_VARIABLES.COLOR_RED
                        }}
                      >
                        {props.pump.unitData ? unitStateDisplay(props.pump.unitData?.state) : ""}
                      </span>
                    </Grid>
                    {isFuelPump(props?.pump?.pump) && props.pump.unitData?.data4 != 255 && <Grid item>
                      Fuel Level{"   "}
                      <span><b>{props.pump?.unitData ? (((props.pump.unitData?.data4 || 0) / 8) * 100) + "%" : "Not found"}</b></span>
                    </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item>
                  <mui.Button
                    variant="text"
                    color="primary"
                    className={classes.pumpHistoryButton}
                    endIcon={<TimelineIcon />}
                    onClick={() =>
                      history.push(
                        `${ApplicationRelativeRoutes.PUMPS}/${props.pumpId}${ApplicationRelativeRoutes.PUMPHISTORY}`
                      )
                    }
                  >
                    Pump History
                  </mui.Button>
                </Grid>
              </Grid>
            </div>
          </StyledCardContent>
          <mui.CardContent>
            <mui.Grid container spacing={2}>
              <mui.Grid item xs={3} md={2}>
                <div> Flow</div>
              </mui.Grid>
              <mui.Grid item xs={3} md={2}>
                <div>{props.pump.unitData ? props.pump.unitData.flow : ""}</div>
              </mui.Grid>
              <mui.Grid item xs={3} md={2}>
                {types.some(type => props.pump?.pump.typeName.toLowerCase().includes(type)) ?
                  <div>Current Sense</div> : <div>Speed</div>
                }
              </mui.Grid>
              <mui.Grid item xs={3} md={2}>
                <div>{props.pump.unitData ? props.pump.unitData.speed : ""}</div>
              </mui.Grid>

              {!props.pump.pump.typeName.toLowerCase().includes("aq2") ? (<><mui.Grid item xs={3} md={2}>
                <div>Coolant Temp</div>
              </mui.Grid>
                <mui.Grid item xs={3} md={2}>
                  <div>
                    {props.pump.unitLog?.wtemp ? props.pump.unitLog.wtemp.toFixed(1) : ""}
                  </div>
                </mui.Grid></>) : <></>}
              <mui.Grid item xs={3} md={2}>
                <div>Battery Voltage</div>
              </mui.Grid>
              <mui.Grid item xs={3} md={2}>
                <div>
                  {props.pump.unitLog?.batt
                    ? (props.pump.unitLog.batt / 10).toFixed(1)
                    : ""}
                </div>
              </mui.Grid>
              {!props.pump.pump.typeName.toLowerCase().includes("aq2") ? <><mui.Grid item xs={3} md={2}>
                <div>Target Speed</div>
              </mui.Grid>
                <mui.Grid item xs={3} md={2}>
                  <div>{props.pump.unitData ? props.pump.unitData.tgt : ""}</div>
                </mui.Grid></> : <></>}
              {!props.pump.pump.typeName.toLowerCase().includes("aq2") ? <><mui.Grid item xs={3} md={2}>
                <div>PTO</div>
              </mui.Grid>
                <mui.Grid item xs={3} md={2}>
                  <div>
                    {props.pump.pump.typeName === "Diesel" ||
                      props.pump.pump.typeName === "Natural Gas"
                      ? props.pump.unitLog?.pto
                        ? "Engaged"
                        : "Not Engaged"
                      : "N/A"}
                  </div>
                </mui.Grid></> : <></>}
            </mui.Grid>
          </mui.CardContent>
        </mui.Card>
      )
      }
      <PumpSettingsModal
        isVisible={pumpSettingsModalIsVisible}
        pumpDataLog={props.pump}
        close={() => setPumpSettingsModalIsVisible(false)}
      />
    </div >
  );
};

const StyledCardContent = mui.withStyles((theme: mui.ThemeOptions) => ({
  root: {
    backgroundColor: theme.cardHeaderColor
  }
}))(mui.CardContent);

const useStyles = makeStyles((theme: mui.ThemeOptions) => ({
  root: {
    width: "95%",
    marginBottom: theme.standardMargin
  },
  tableContainer: {
    maxHeight: "40vh"
  },
  headerSection: {
    display: "flex",
    maxWidth: "900px",
    justifySelf: "center",
    marginBottom: theme.standardMargin,
    "& div": {
      marginLeft: ".5em"
    },
    "& button": {
      marginLeft: ".5em"
    },
    "& a": {
      marginLeft: ".5em"
    }
  },
  serialNumber: {
    color: theme.textColor,
    fontSize: "1em",
    fontWeight: "normal"
  },
  sectionHeader: {
    color: theme.selectedItemColor,
    justifySelf: "start",
    fontSize: "1.25em",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  informationRoot: {
    width: "100%"
  },
  pumpHistoryButton: {
    backgroundColor: theme.cardHeaderColor,
    textTransform: "none"
  },
  autoControlButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  },
  localWeatherButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  },
  pumpSettingsButton: {
    backgroundColor: theme.selectedItemColor,
    color: theme.whiteColor,
    textTransform: "none"
  }
}));

export default PumpInformation;
