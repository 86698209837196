import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useApi } from "../apiService";
import { AjaxResult } from "../../enums/ajaxResult";
import { useMessage } from "../messageService";
import * as mui from "@material-ui/core";
import { Field } from "../../dtos/field";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { ApplicationRelativeRoutes } from "../..";
import { ValveControl } from "./valveControl";
import { PumpDataLog } from "../../dtos/pumpDataLog";

interface FieldTableProps {
  pumpId: number;
  pump: PumpDataLog | null;
  setRefreshPumpInfoCounter: React.Dispatch<React.SetStateAction<number>>;
  refreshFieldCounter: number;
  setRefreshFieldCounter: React.Dispatch<React.SetStateAction<number>>;
}

const FieldTable = (props: FieldTableProps) => {
  const classes = useStyles();

  const history = useHistory();

  const [fields, setFields] = useState<Field[]>([]);
  const [pendingChange, setPendingChange] = useState<number>(0);
  const { fieldsApi, pumpsApi } = useApi();
  const { showMessage } = useMessage();
  
  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      if(pendingChange === 0) {
        ref.current = value;
      }
    });
    return ref.current;
  };

  const previousFields = usePrevious(fields);

  useEffect(() => {
    const fetchFieldData = async () => {
      showMessage("Fetching fields...");
      fieldsApi.getByPumpId(props.pumpId).then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Fields fetched!");
          setFields(r.data);
        }
      });
    };

    try {
      fetchFieldData();
    } catch (e) {
      showMessage(e.message);
    }
  }, [props.pumpId, showMessage, fieldsApi]);

  useEffect(() => {
    const fetchFieldData = async () => {
      if (props.refreshFieldCounter < 20) {
        showMessage("Updating fields...");
        fieldsApi.getByPumpId(props.pumpId).then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Fields updated!");
            setFields(r.data);
          }
          props.setRefreshFieldCounter(props.refreshFieldCounter + 1);
        }); 
      }
      else {
        clearInterval(interval);
      }
    };

    const interval = setInterval(fetchFieldData, 30000);
    return () => clearInterval(interval);
  }, [props, props.refreshFieldCounter, props.pumpId, showMessage, fieldsApi]);

  useEffect(() => {
    if(pendingChange !== 0) {
      var oldField = (previousFields as unknown as Field[]).find(f => f.id === pendingChange);
      var newField = fields.find(f => f.id === pendingChange);
      if(oldField && newField
         && oldField.position !== newField.position){
            setPendingChange(0);
      }
    }
  }, [fields, pendingChange, previousFields]);

  const onValveControlCellClicked = (event: any) => {
    event.stopPropagation();
  };

  const handleValveChange = (id: number, channelNumber: number, newValue: number) => {
    if(props.pump?.pump) {
      if (newValue === 0) {
         pumpsApi.shutValve({
          customerId: props.pump.pump.customerId,
          pumpSerialNumber: props.pump.pump.serialNumber,
          valveNumber: channelNumber
        }).then(r => {
          console.log(r);
          if(r.result === AjaxResult.Success && r.data) {
            setPendingChange(id);
            props.setRefreshPumpInfoCounter(0);
            props.setRefreshFieldCounter(0);
            showMessage("Shut valve command received.");
          }
        });
      } 
      else if (newValue === 100) {
         pumpsApi.openValve({
          customerId: props.pump.pump.customerId,
          pumpSerialNumber: props.pump.pump.serialNumber,
          valveNumber: channelNumber
        }).then(r => {
          console.log(r);
          if(r.result === AjaxResult.Success && r.data) {
            setPendingChange(id);
            props.setRefreshPumpInfoCounter(0);
            props.setRefreshFieldCounter(0);
            showMessage("Open valve command received.");
          }
        });
      }
      else {
         pumpsApi.setValveGatePercentage({
          customerId: props.pump.pump.customerId,
          pumpSerialNumber: props.pump.pump.serialNumber,
          valveNumber: channelNumber,
          valveGatePercentage: newValue
        }).then(r => {
          console.log(r);
          if(r.result === AjaxResult.Success && r.data) {
            setPendingChange(id);
            props.setRefreshPumpInfoCounter(0);
            props.setRefreshFieldCounter(0);
            showMessage("Open valve command received.");
          }
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <label className={classes.sectionHeader}>Fields</label>
      {fields.length === 0 ? (
        <div>No fields associated with this pump.</div>
      ) : (
        <mui.TableContainer className={classes.tableContainer}>
          <StyledTable stickyHeader aria-label="sticky table">
            <mui.TableHead>
              <mui.TableRow>
                <StyledTableCell key="name">Name</StyledTableCell>
                <StyledTableCell key="sensors">Sensors</StyledTableCell>
                <StyledTableCell className={classes.statusSectionHeader} key="status">Status</StyledTableCell>
                <StyledTableCell className={classes.controlSectionHeader} key="control" size="small">Control</StyledTableCell>
                <StyledTableCell className={classes.selectSectionHeader} key="select" />
              </mui.TableRow>
            </mui.TableHead>
            <mui.TableBody>
              {fields.map(row => {
                return (
                  <StyledTableRow
                    hover
                    key={row.id}
                    onClick={(event: any) => {
                        history.push(
                          `${ApplicationRelativeRoutes.PUMPS}/${props.pumpId}${ApplicationRelativeRoutes.FIELDS}/${row.id}`
                        )
                    }}
                  >
                    <StyledTableCell>{` ${row.name}`}</StyledTableCell>
                    <StyledTableCell>{row.sensorCount}</StyledTableCell>
                    <StyledTableCell>
                      {row.id === pendingChange ? "Pending" :
                        row.position < 11
                          ? "Closed"
                          : "Valve " + row.position + "% open"}
                    </StyledTableCell>
                    <StyledTableCell onClick={onValveControlCellClicked}>
                        {row.valveSerialNumber !== undefined && row.valveSerialNumber !== null && row.valveSerialNumber !== 0 ? 
                          <ValveControl field={row} handleValveChange={handleValveChange} disabled={props.pump?.pump ? false : true}/> :
                          ""
                        }
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <ChevronRightIcon />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </mui.TableBody>
          </StyledTable>
        </mui.TableContainer>
      )}
    </div>
  );
};

const StyledTableCell = mui.withStyles((theme: mui.ThemeOptions) => ({
  root: {
    padding: "10px"
  },
  head: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.labelColor,
    border: "none"
  },
  body: {
    fontSize: 14,
    backgroundColor: theme.whiteColor,
    color: theme.darkTextColor
  }
}))(mui.TableCell);

const StyledTableRow = mui.withStyles((theme: mui.ThemeOptions) => ({
  root: {
    borderRadius: theme.shape?.borderRadius,
    boxShadow: theme.standardBoxShadow,
    "& td:first-child": {
      borderBottomLeftRadius: "8px",
      borderTopLeftRadius: "8px"
    },
    "& td:last-child": {
      borderBottomRightRadius: "8px",
      borderTopRightRadius: "8px"
    }
  },
  hover: {
    "&:hover": {
      boxShadow: "0px 0px 7px #00000026",
      cursor: "pointer"
    }
  }
}))(mui.TableRow);
const StyledTable = mui.withStyles({
  root: {
    borderSpacing: "0em .5em"
  }
})(mui.Table);

const useStyles = makeStyles((theme: mui.ThemeOptions) => ({
  root: {
    width: "95%",
    color: theme.textColor
  },
  tableContainer: {
    maxHeight: "40vh"
  },
  sectionHeader: {
    color: theme.selectedItemColor,
    justifySelf: "start",
    fontSize: "1.25em",
    fontWeight: "bold"
  },
  statusSectionHeader: {
    width: 420
  },
  controlSectionHeader: {
    width: 200
  },
  selectSectionHeader: {
    width: 500
  },
  link: {
    width: "100%"
  }
}));

export default FieldTable;
