import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Sensor } from "../../dtos/sensor";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { ApiResult } from "../../rest/baseApi";
import { AjaxResult } from "../../enums/ajaxResult";
import { useConfirmDialog } from "../confirmDialogService";
import { ErrorMessage } from "../shared/errorMessage";
import { Autocomplete } from "@material-ui/lab";
// import the add icon
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useApi } from "../apiService";
// import { Field } from "../../dtos/field";
import { useMessage } from "../messageService";
import AddEditFields from '../admin/fields/addEditFieldsModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
// import { get } from "http";

interface AddEditProps {
  isVisible: boolean;
  sensor: Sensor | null;
  fieldId?: number;
  addSensor: (sensor: Sensor) => Promise<ApiResult<any>>;
  deleteSensor: (id: number) => Promise<ApiResult<any>>;
  updateSensor: (sensor: Sensor, id: number) => Promise<ApiResult<any>>;
  close: () => void;
  showFieldSection?: boolean;
}

const getDefaultSensor = (fieldId?: number, sensor?: any) =>
({
  name: sensor?.name || "",
  fieldId: fieldId || 0,
  serial: Number(sensor?.serial) || 0,
  alarmPriority: sensor?.alarmPriority || 0,
  channel: sensor?.channel || 0,
  lat: sensor?.lat || 0,
  lon: sensor?.lon || 0,
  id: sensor?.id || null,
  alarmHigh: sensor?.alarmHigh || null,
  alarmHighReset: sensor?.alarmHighReset || null,
  alarmLow: sensor?.alarmLow || null,
  alarmLowReset: sensor?.alarmLowReset || null,
  avgWeight: sensor?.avgWeight || null,
  offset: sensor?.offset || 28
} as Sensor);

const AddEditSensor = (props: AddEditProps) => {
  const { fieldsApi } = useApi();
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [sensor, setSensor] = useState<Sensor>(getDefaultSensor(props.fieldId, props.sensor));
  const { showConfirmDialog } = useConfirmDialog();
  const { showMessage } = useMessage();
  const [fields, setFields] = useState<any[]>([]);
  const [field, setField] = useState<any>();
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [fieldsLoading, setFieldsLoading] = useState(true);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [levelReading, setLevelReading] = useState({} as any);
  const [showOther, setShowOther] = useState(false);
  const [channelValue, setChannelValue] = useState(sensor.channel);
  const [otherValue, setOtherValue] = useState('' as any);

  useEffect(() => {
    setSensor(getDefaultSensor(props.fieldId, props.sensor));
    // editSensor(f => (f.serial = props.sensor?.serial || 0));
    console.log("props.sensor ", props.sensor);
    console.log("sensor ", sensor);
    console.log("props", props);
    console.log(props.fieldId);
    // if (props.sensor != undefined) {
    //   console.log("updating sensor");
    //   // setSensor((f: any) => {
    //   //   return { ...f, serial: props.sensor?.serial }
    //   // });
    //   editSensor(f => (f.serial = props.sensor?.serial || 0));
    // }
    if (props.showFieldSection != true) {
      setReadOnly(false);
    }
    // }
    console.log("props.sensor ", props.sensor);
    console.log("sensor ", sensor);
    console.log("props", props);
  }, [props.sensor, props.fieldId]);

  // useEffect(() => {
  //   if (!props.isVisible) {
  //     setSensor(getDefaultSensor(props.fieldId));
  //     setMessages([]);
  //   }
  //   if (props.fieldId) {
  //     setReadOnly(false);
  //   }

  //   // setSensor(props.sensor);

  //   try {
  //     if (typeof levelReading === 'object') {
  //       getCurrentLevelReading();
  //     }
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  // }, [props.isVisible, props.fieldId, sensor]);

  useEffect(() => {
    try {
      fetchFields();
    } catch (e) {
      console.error(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOrUpdateSensor = async () => {
    var result: ApiResult<any>;
    console.log("Trying to add this sensor: ", sensor);
    if (props.sensor && !props.showFieldSection) {
      console.log("updating sensor api call");
      result = await props.updateSensor(sensor, sensor.id);
    } else {
      console.log("adding sensor api call");
      result = await props.addSensor(sensor);
    }

    if (result.result === AjaxResult.Success) {
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };
  const deleteSensor = async () => {
    showConfirmDialog(
      `Are you sure you want to delete access to ${sensor.name ? " " + sensor.name : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        props.deleteSensor(sensor.id);
        props.close();
      }
    });
  };
  const editSensor = (operation: (c: Sensor) => void) => {
    var editedSensor: Sensor = { ...sensor };
    operation(editedSensor);
    setSensor(editedSensor);
  };

  const fetchFields = async () => {
    setFieldsLoading(true);
    showMessage("Fetching fields...");
    fieldsApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Fields fetched!");
        setFields(r.data);
        setFieldsLoading(false);
      }
    });
  };

  const setFieldId = (id: number) => {
    setSensor((s) => ({ ...s, fieldId: id }));
    showMessage("Fetching fields...");
    setFieldsLoading(true);
    fieldsApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Fields fetched!");
        setFields(r.data);
        // Ensure `setField` is called after the state is updated with the new fields
        const fieldToSet = r.data.find(f => f.id === id);
        if (fieldToSet) {
          setField(fieldToSet);
        }
        setFieldsLoading(false);
      }
    }).catch(error => {
      // Handle any errors here
      console.error("Error fetching fields:", error);
    });
  }

  const fieldChange = (e: any, value: any) => {
    setSensor((s) => ({ ...s, fieldId: value.id }));
    setReadOnly(false);
    setField(value);
  }

  const getCurrentLevelReading = async () => {
    console.log('--------');
    console.log(levelReading);
    console.log('--------');
    const serial = props.sensor?.serial || 0;
    const channel = 5;

    const now = new Date();
    const utcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    console.log("Utc timestamp.");
    const formattedDate = format(now, 'yyyy-MM-dd HH:mm:ss');
    const twoHoursAgo = new Date(now.getTime() - (2 * 60 * 60 * 1000));
    const formattedTwoHoursAgo = format(twoHoursAgo, 'yyyy-MM-dd HH:mm:ss');
    console.log(format(utcTimestamp, 'yyyy-MM-dd HH:mm:ss'));
    console.log("formatedDate: ");
    console.log(formattedTwoHoursAgo);

    const url = `https://faults.appdig.com/data/${serial}/${channel}?start=${formattedTwoHoursAgo}&end=${formattedDate}`;
    console.log(url);

    const res = await fetch(url);
    console.log(res);
    const data: any = await res.json();

    if (data.length === 0) {
      return;
    }

    let reading = data[0]?.Data / 10 || 0;

    console.log("-------------------------------reading: " + reading)

    setLevelReading(reading);
    const offset = sensor.offset || 0;
    setCurrentLevel(reading);
    setSensor((s) => ({ ...s, offset: (reading + offset) }));
  }

  const setCurrentLevelValue = async (value: number) => {
    setCurrentLevel(value);
    setSensor((s) => ({ ...s, offset: (value + levelReading) }));
  };

  const setHeightOffset = async (value: number) => {
    setSensor((s) => ({ ...s, offset: value }));
    if (typeof levelReading != 'object') {
      setCurrentLevel(value - levelReading)
    }

  }

  useEffect(() => {
    if (sensor?.channel === null) {
      return;
    }

    if (sensor?.channel > 5) {
      setOtherValue(sensor.channel);
      editSensor((c) => (c.channel = sensor.channel));
      setShowOther(true);
    }
  }, [sensor?.channel]);

  useEffect(() => {
    console.log("showOther: ", showOther);
  }, [showOther]);

  const handleChannelChange = (e: any) => {
    console.log('handleChannelChange');
    const value = parseInt(e.target.value);
    setChannelValue(value);

    if (value === -1) {
      setShowOther(true);
      setOtherValue('');
      return;
    } else {
      setShowOther(false);
      editSensor((c) => (c.channel = value));
    }
  };

  const handleOtherChannelChange = (e: any) => {
    const value = parseInt(e.target.value);
    setOtherValue(value);
    editSensor((c) => (c.channel = value));
  };

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      {props.showFieldSection && (
        <div>
          <DialogTitle>Select Field</DialogTitle>
          <DialogContent>
            <div className={classes.flex}>
              {fieldsLoading ? <CircularProgress /> : ''}
              {!fieldsLoading && <div className={classes.fieldHolder}>
                <Autocomplete
                  id="combo-box-demo"
                  options={fields}
                  getOptionLabel={(option: any) => option?.name}
                  style={{ width: '100%' }}
                  renderInput={params => <TextField {...params} label="Field" variant="outlined" />}
                  value={field || null}
                  onChange={fieldChange}
                />
              </div>}
              <div className={classes.buttonHolder}>
                <Button onClick={() => setShowFieldModal(true)} color="primary">
                  <AddCircleOutlineIcon />
                  <span> Create Field</span>
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      )}

      <AddEditFields
        isVisible={showFieldModal}
        close={() => { setShowFieldModal(false) }}
        field={null}
        addField={fieldsApi.postOne}
        updateField={fieldsApi.putOne}
        onFieldSaved={setFieldId}
      />
      <DialogTitle>{`${props.sensor && !props.showFieldSection ? "Update" : "Add"} Sensor`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="Sensor Name"
            variant="outlined"
            // Disable Sensor Name if Average
            disabled={props.sensor?.name === "Average" ? true : false || readOnly}
            value={sensor.name}
            onChange={value => editSensor(f => (f.name = value.target.value))}
          />
          {props.sensor?.name !== "Average" && (
            <TextField
              label="Serial Number"
              disabled={readOnly}
              variant="outlined"
              type="number"
              value={sensor.serial}
              helperText={sensor.serial > 0 ? null : "Serial Number > 0 "}
              onChange={value =>
                editSensor(f => (f.serial = parseInt(value.target.value)))
              }
            />
          )}
          {sensor?.name !== "Average" && (
            <FormControl variant="outlined" className={classes.inputWidth}>
              <InputLabel id="role-select">Channel</InputLabel>
              <Select
                labelId="role-select"
                label="Channel"
                disabled={readOnly}
                value={showOther ? -1 : channelValue}
                onChange={handleChannelChange}
              >
                <MenuItem value={0}>Moisture - 1</MenuItem>
                <MenuItem value={1}>Moisture - 2</MenuItem>
                <MenuItem value={2}>Moisture - 3</MenuItem>
                <MenuItem value={3}>Moisture - 4</MenuItem>
                <MenuItem value={5}>Level</MenuItem>
                <MenuItem value={-1}>Other</MenuItem>
              </Select>
            </FormControl>
          )}
          {showOther && (
            <TextField
              label="Other Channel #"
              variant="outlined"
              type="number"
              value={otherValue}
              onChange={handleOtherChannelChange}
            />
          )}
          {props.sensor?.name === "Average" ? (
            <div />
          ) : (
            <TextField
              label="Average Weight %"
              variant="outlined"
              type="number"
              disabled={props.sensor?.name === "Average" ? true : false || readOnly}
              value={sensor.avgWeight}
              onChange={value =>
                editSensor(f => (f.avgWeight = parseInt(value.target.value)))
              }
            />
          )}

          <TextField
            label="Alarm Limit High"
            variant="outlined"
            type="number"
            value={sensor.alarmHigh}
            disabled={readOnly}
            onChange={value =>
              editSensor(f => (f.alarmHigh = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Alarm Reset High"
            variant="outlined"
            disabled={readOnly}
            type="number"
            value={sensor.alarmHighReset}
            onChange={value =>
              editSensor(
                f => (f.alarmHighReset = parseFloat(value.target.value))
              )
            }
          />
          <TextField
            label="Alarm Limit Low"
            variant="outlined"
            disabled={readOnly}
            type="number"
            value={sensor.alarmLow}
            onChange={value =>
              editSensor(f => (f.alarmLow = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Alarm Reset Low"
            variant="outlined"
            disabled={readOnly}
            type="number"
            value={sensor.alarmLowReset}
            onChange={value =>
              editSensor(
                f => (f.alarmLowReset = parseFloat(value.target.value))
              )
            }
          />
          {props.sensor?.name !== "Average" && (
            <TextField
              label="Latitude"
              variant="outlined"
              disabled={readOnly}
              type="number"
              value={sensor.lat}
              helperText={
                sensor.lat > -180 && sensor.lat < 180
                  ? null
                  : "Valid range -180...180, if left blank this sensor will not display on the Home page"
              }
              onChange={value =>
                editSensor(c => (c.lat = parseFloat(value.target.value)))
              }
            />
          )}
          {props.sensor?.name !== "Average" && (
            <TextField
              label="Longitude"
              variant="outlined"
              disabled={readOnly}
              type="number"
              value={sensor.lon}
              helperText={
                sensor.lon > -180 && sensor.lon < 180
                  ? null
                  : "Valid range -180...180, if left blank this sensor will not display on the Home page"
              }
              onChange={value =>
                editSensor(c => (c.lon = parseFloat(value.target.value)))
              }
            />
          )}
          {props.sensor?.name !== "Average" && (
            <TextField
              label="Height Offset (in)"
              variant="outlined"
              disabled={readOnly}
              type="number"
              defaultValue={28}
              value={sensor.offset}
              onChange={value =>
                // setHeightOffset(parseInt(value.target.value))
                editSensor(c => (c.offset = parseFloat(value.target.value)))
              }
            />
          )}
          {props.sensor?.name !== "Average" && typeof levelReading !== 'object' && (
            <TextField
              label="Current Water Level (in)"
              variant="outlined"
              disabled={readOnly}
              type="number"
              value={currentLevel}
              onChange={value =>
                setCurrentLevelValue(parseInt(value.target.value))
              }
            />
          )}
          {typeof levelReading === 'object' && (
            <div>Level Sensor Offline</div>
          )}
          <FormControl variant="outlined" className={classes.inputWidth}>
            <InputLabel id="role-select">Alarm Priority</InputLabel>
            <Select
              labelId="role-select"
              label="Alarm Priority"
              disabled={readOnly}
              value={sensor.alarmPriority}
              onChange={(e: any) =>
                editSensor(c => (c.alarmPriority = parseInt(e.target.value)))
              }
            >
              <MenuItem value="0">Disabled</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </Select>
          </FormControl>
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions className={classes.flexCenter}>
        <Button
          variant="contained"
          color="secondary"
          disabled={sensor.serial < 0 && sensor?.name !== "Average"}
          onClick={addOrUpdateSensor}
        >
          {props.sensor && !props.showFieldSection ? "Update Sensor" : "Create Sensor"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        {props.sensor && !props.showFieldSection && props.sensor.name !== "Average" ? (
          <Button variant="contained" onClick={deleteSensor}>
            Delete
          </Button>
        ) : (
          <Button variant="contained" disabled>
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog >
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  },
  flex: {
    display: "flex",
    flex: "3 1"
  },
  iconHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "2 1",
    cursor: "pointer",
  },
  fieldHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "3 1"
  },
  inputWidth: {
    width: "40% !important",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonHolder: {
    maginTop: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1"
  }
}));

export default AddEditSensor;
