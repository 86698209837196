export const THEME_VARIABLES = {
  COLOR_WHITE: "#fff",
  COLOR_LIGHT_BLUE: "#f8fcff",
  COLOR_BLUE_1: "#2480de",
  COLOR_BLUE_2: "#2991FB",
  COLOR_GRAY_0: "#F5F5F5",
  COLOR_GRAY_1: "#aeaeae",
  COLOR_GRAY_2: "#9D9D9D",
  COLOR_DARK_GRAY: "#686868",

  COLOR_GREEN: "#41BF80",
  COLOR_YELLOW: "#F7A14A",
  COLOR_RED: "#E66C6C"
};
