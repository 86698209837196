import React, { useContext, useEffect, useState } from "react";
import { NavLink, matchPath } from "react-router-dom";
import { AuthContext } from "../auth/authContext";
import { makeStyles, ThemeOptions } from "@material-ui/core/styles";
import { UserRole } from "../enums/userRoles";
import { useConfirmDialog } from "./confirmDialogService";
import { ApplicationRelativeRoutes } from "..";
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Button, Divider, Grid } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import TimelineIcon from "@material-ui/icons/Timeline";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import CachedIcon from "@material-ui/icons/Cached";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useWindowWidth } from "@react-hook/window-size";
import { THEME } from "../theme";

let logo = require("../images/aquarius-logo.svg");

function NavigationBar() {
  const { username, logout, role } = useContext(AuthContext);
  const classes = useStyles();
  const { showConfirmDialog } = useConfirmDialog();
  const [parameters, setParameters] = useState<{
    pumpId?: number;
    fieldId?: number;
    pumpHistory: boolean;
    surgeLogix: boolean;
    closedLoop: boolean;
  }>({ pumpHistory: false, surgeLogix: false, closedLoop: false });

  let location = useLocation();

  const width = useWindowWidth();
  var fullSize = width > THEME.minWidthFullScreen;

  useEffect(() => {
    // Check if Pump history is included in Url
    var match: {
      isExact: boolean;
      params: {
        pumpId?: string;
        fieldId?: string;
      };
    } | null = matchPath(location.pathname, {
      path: `(${ApplicationRelativeRoutes.PUMPS})?/:pumpId?(${ApplicationRelativeRoutes.PUMPHISTORY})`,
      exact: true,
      strict: false
    });
    if (match && match.isExact) {
      setParameters({
        pumpHistory: true,
        surgeLogix: false,
        closedLoop: false,
        fieldId: undefined,
        pumpId:
          match && match.params.pumpId && !isNaN(parseInt(match?.params.pumpId))
            ? parseInt(match?.params.pumpId)
            : undefined
      });
    }
    // Check if SurgeLogix is included in url
    else {
      match = matchPath(location.pathname, {
        path: `(${ApplicationRelativeRoutes.PUMPS})?/:pumpId?(${ApplicationRelativeRoutes.SURGELOGIX})`,
        exact: true,
        strict: false
      });
      if (match && match.isExact) {
        setParameters({
          pumpHistory: false,
          surgeLogix: true,
          closedLoop: false,
          fieldId: undefined,
          pumpId:
            match &&
            match.params.pumpId &&
            !isNaN(parseInt(match?.params.pumpId))
              ? parseInt(match?.params.pumpId)
              : undefined
        });
        // Check if Closed Loop
      } else {
        match = matchPath(location.pathname, {
          path: `(${ApplicationRelativeRoutes.PUMPS})?/:pumpId?(${ApplicationRelativeRoutes.CLOSEDLOOP})`,
          exact: true,
          strict: false
        });
        if (match && match.isExact) {
          setParameters({
            pumpHistory: false,
            surgeLogix: false,
            closedLoop: true,
            fieldId: undefined,
            pumpId:
              match &&
              match.params.pumpId &&
              !isNaN(parseInt(match?.params.pumpId))
                ? parseInt(match?.params.pumpId)
                : undefined
          });
        } else {
          // Check if FieldId is included in url
          match = matchPath(location.pathname, {
            path: `(${ApplicationRelativeRoutes.PUMPS})?/:pumpId?(${ApplicationRelativeRoutes.FIELDS})?/:fieldId?`,
            exact: true,
            strict: false
          });
          setParameters({
            pumpHistory: false,
            surgeLogix: false,
            closedLoop: false,
            fieldId:
              match &&
              match.params.fieldId &&
              !isNaN(parseInt(match?.params.fieldId))
                ? parseInt(match?.params.fieldId)
                : undefined,
            pumpId:
              match &&
              match.params.pumpId &&
              !isNaN(parseInt(match?.params.pumpId))
                ? parseInt(match?.params.pumpId)
                : undefined
          });
        }
      }
    }
  }, [location]);

  const tryLogout = () => {
    showConfirmDialog("Are you sure you want to log out?").then(confirmed => {
      if (confirmed) {
        logout();
      }
    });
  };
  return (
    <div className={classes.appHeader}>
      <Grid container>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.navigationHeader}>
              <div className={classes.headerItem}>
                <img src={logo} alt="Logo" />
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.headerRightItem}>
              {(role === UserRole.Admin ||
                role === UserRole.Manager ||
                role === UserRole.Operator) && (
                <>
                  <NavLink
                    className={classes.adminNavLink}
                    activeClassName="active"
                    to={ApplicationRelativeRoutes.ADMIN}
                  >
                    <SettingsIcon className={classes.icon} />
                    {fullSize && "Admin"}
                  </NavLink>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    classes={{ root: classes.dividerRoot }}
                  />
                </>
              )}
              {fullSize && (
                <div className={classes.user}>
                  <PersonIcon className={classes.icon} />
                  {username}
                </div>
              )}
              <Button
                classes={{
                  root: classes.logoutButtonRoot
                }}
                type="submit"
                variant="contained"
                onClick={tryLogout}
              >
                Logout
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              light={true}
              classes={{ root: classes.dividerRoot }}
            />
            <Breadcrumbs
              aria-label="breadcrumb"
              separator="›"
              classes={{ separator: classes.separator }}
            >
              <NavLink
                className={classes.navLink}
                activeClassName="active"
                exact={true}
                to="/"
              >
                <HomeIcon className={classes.icon} />
                {fullSize && "Home"}
              </NavLink>

              {parameters.pumpId && (
                <NavLink
                  className={classes.navLink}
                  activeClassName="active"
                  exact={true}
                  to={`${ApplicationRelativeRoutes.PUMPS}/${parameters.pumpId}`}
                >
                  <InvertColorsIcon className={classes.icon} />
                  {fullSize && "Pump"}
                </NavLink>
              )}
              {parameters.pumpId && parameters.pumpHistory && (
                <NavLink
                  className={classes.navLink}
                  activeClassName="active"
                  exact={true}
                  to={`${ApplicationRelativeRoutes.PUMPS}/${parameters.pumpId}${ApplicationRelativeRoutes.PUMPHISTORY}`}
                >
                  <TimelineIcon className={classes.icon} />
                  {fullSize && "History"}
                </NavLink>
              )}
              {parameters.pumpId && parameters.surgeLogix && (
                <NavLink
                  className={classes.navLink}
                  activeClassName="active"
                  exact={true}
                  to={`${ApplicationRelativeRoutes.PUMPS}/${parameters.pumpId}${ApplicationRelativeRoutes.SURGELOGIX}`}
                >
                  <ScheduleIcon className={classes.icon} />
                  {fullSize && "surgeLogix"}
                </NavLink>
              )}
              {parameters.pumpId && parameters.closedLoop && (
                <NavLink
                  className={classes.navLink}
                  activeClassName="active"
                  exact={true}
                  to={`${ApplicationRelativeRoutes.PUMPS}/${parameters.pumpId}${ApplicationRelativeRoutes.CLOSEDLOOP}`}
                >
                  <CachedIcon className={classes.icon} />
                  {fullSize && "ClosedLoop"}
                </NavLink>
              )}
              {parameters.pumpId && parameters.fieldId && (
                <NavLink
                  className={classes.navLink}
                  activeClassName="active"
                  exact={true}
                  to={`${ApplicationRelativeRoutes.PUMPS}/${parameters.pumpId}${ApplicationRelativeRoutes.FIELDS}/${parameters.fieldId}`}
                >
                  <NaturePeopleIcon className={classes.icon} />
                  {fullSize && "Field"}
                </NavLink>
              )}
            </Breadcrumbs>
          </>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
  appHeader: {
    backgroundColor: theme.navbarColor,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerItem: {
    padding: "1em 1em 1em 1.5em",
    color: theme.textColor,
    display: "flex",
    alignItems: "center"
  },
  headerRightItem: {
    padding: "1em 1em 1em 1em",
    color: theme.textColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  navigationHeader: {
    display: "flex",
    alignItems: "center"
  },
  navLink: {
    padding: "1.5em 1em 1.5em 1em",
    textDecoration: "none",
    color: theme.textColor,
    display: "flex",
    "&.active": {
      color: theme.selectedItemColor,
      borderBottom: `1px solid ${theme.selectedItemColor}`
    }
  },
  adminNavLink: {
    textDecoration: "none",
    display: "flex",
    color: theme.darkTextColor,
    "&.active": {
      color: theme.selectedItemColor,
      borderBottom: `1px solid ${theme.selectedItemColor}`
    }
  },
  icon: {
    color: "inherit"
  },
  separator: {
    marginRight: 0,
    marginLeft: 0
  },
  logoutButtonRoot: {
    background:
      "transparent linear-gradient(97deg, #60bef6 0%, #2991fb 100%) 0% 0% no-repeat padding-box",
    color: `${theme.whiteColor}`,
    boxShadow: theme.standardBoxShadow,
    borderRadius: theme.shape?.borderRadius,
    opacity: 1,
    textTransform: "none",
    fontSize: "1em"
  },
  user: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.standardMargin
  },
  dividerRoot: {
    marginTop: theme.standardMargin,
    marginBottom: theme.standardMargin
  }
}));

export default NavigationBar;
