import React, { useState, useEffect } from "react";
import { PumpType } from "../../../dtos/pumpType";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { ApiResult } from "../../../rest/baseApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { ErrorMessage } from "../../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  pumpType: PumpType | null;
  addPumpType: (PumpType: PumpType) => Promise<ApiResult<any>>;
  updatePumpType: (pumpType: PumpType, id: number) => Promise<ApiResult<any>>;
  close: () => void;
}

const getDefaultPumpType = () =>
({
  name: ""
} as PumpType);
const AddEditPumpType = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [pumpType, setPumpType] = useState<PumpType>(getDefaultPumpType());

  useEffect(() => {
    setPumpType(props.pumpType ? props.pumpType : getDefaultPumpType());
  }, [props.pumpType]);

  useEffect(() => {
    if (!props.isVisible) {
      setPumpType(getDefaultPumpType());
      setMessages([]);
    }
    console.log("loaded.");
  }, [props.isVisible]);
  // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     console.log("Got location")
  //     // setLatitude(position.coords.latitude);
  //     // setLongitude(position.coords.longitude);
  //   });
  // } else {
  //   console.log("can't get location.");
  // }

  const addOrUpdatePumpType = async () => {
    var result: ApiResult<any>;
    if (props.pumpType) {
      result = await props.updatePumpType(pumpType, pumpType.id);
    } else {
      result = await props.addPumpType(pumpType);
    }

    if (result.result === AjaxResult.Success) {
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editPumpType = (operation: (c: PumpType) => void) => {
    var editedPumpType: PumpType = { ...pumpType };
    operation(editedPumpType);
    setPumpType(editedPumpType);
  };

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${props.pumpType ? "Update" : "Add"
        } Pump Type`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="Pump Type"
            variant="outlined"
            value={pumpType?.name}
            onChange={value => editPumpType(c => (c.name = value.target.value))}
          />
        </form>
        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={addOrUpdatePumpType}
          disabled={pumpType.name === ""}
        >
          {props.pumpType ? "Update Pump Type" : "Create Pump Type"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default AddEditPumpType;
