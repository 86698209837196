import React, { useContext, useState, useEffect, useMemo } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";
import { PumpType } from "../../../dtos/pumpType";
import { Customer } from "../../../dtos/customer";

import { makeStyles } from "@material-ui/core/styles";
import { Pump } from "../../../dtos/pump";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  FormGroup
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ApiResult } from "../../../rest/baseApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../apiService";
import { useMessage } from "../../messageService";
import { ErrorMessage } from "../../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  pump: Pump | null;
  addPump: (pump: Pump) => Promise<ApiResult<any>>;
  newPump: string | null;
  updatePump: (pump: Pump, id: number) => Promise<ApiResult<any>>;
  close: () => void;
}

const getDefaultPump = () =>
({
  name: "",
  lat: 0,
  lon: 0,
  phone: "",
  serialNumber: 0
} as Pump);

const AddEditPumps = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [pump, setPump] = useState<Pump>(getDefaultPump());
  const { role } = useContext(AuthContext);

  const { customersApi, pumpTypeApi } = useApi();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [pumpTypes, setPumpTypes] = useState<PumpType[]>([]);
  // const [hasFlowMeter, setHasFlowMeter] = useState(false);
  const { showMessage } = useMessage();

  useEffect(() => {
    setPump(props.pump ? props.pump : getDefaultPump());
  }, [props.pump]);


  const getPhoneNumber = async (serialNumber: string) => {
    const url = "https://faults.appdig.com/device/parms/";
    const response = await fetch(url + serialNumber);
    if (response.status != 200) {
      console.log('No phone number found');
      return 0;
    }
    const data = await response.json();

    console.log(data);
    if (data?.parms == null) {
      return null;
    }
    const parm65 = data.parms.find((p: any) => p.number == 65).value;
    const parm66 = data.parms.find((p: any) => p.number == 66).value;

    const phoneNumber = parm65.toString() + parm66.toString();
    console.log(phoneNumber);
    return phoneNumber;
  }

  const addToOldPumpsTable = async (serialNumber: string, pumpType: string) => {
    console.log(`Pump: ${serialNumber}, Type: ${pumpType}`);
    if (serialNumber == null) {
      return false;
    }
    const url = `https://faults.appdig.com/addToOld/${serialNumber}/${pumpType}`;

    const response = await fetch(url);
    if (response.status != 201) {
      console.log('Error adding to old pumps table');
      return false;
    }
    return true;


  }

  useEffect(() => {
    if (!props.isVisible) {
      console.log("setting pumps");
      setPump(getDefaultPump());
      setMessages([]);
    }
  }, [props.isVisible]);

  useEffect(() => {
    const fetchData = async () => {
      showMessage("Fetching customers...");
      customersApi.getAll().then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Customers fetched!");
          setCustomers(r.data);
        }
      });
      showMessage("Fetching pump types...");
      pumpTypeApi.getAll().then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Pump Types fetched!");
          setPumpTypes(r.data);
        }
      });
    };
    fetchData();
  }, [customersApi, pumpTypeApi, showMessage]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPump(p => ({ ...p, lat: position.coords.latitude, lon: position.coords.longitude }));
      });
    } else {
      console.log("can't get location.");
    }
    test();
  }, [props.newPump]);

  const test = async () => {
    if (props.newPump != null) {
      const serialNumber = parseInt(props.newPump) || 0;
      const phone = await getPhoneNumber(serialNumber.toString());
      setPump(p => ({ ...p, serialNumber: serialNumber, phone: phone }));
    }
  }

  const setParm = async (serial: string, parmNumber: string, value: string) => {
    const url = "https://appdig.com/aQParms/setParm.php";

    // const command = "6," + user.col1 + "," + user.col2 + "*";
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `serno=${serial}&data1=${parmNumber}&data2=${value}`,
    });

    if (response.status != 200) {
      console.log('Error setting parm');
      setMessages(["Error setting parm"]);
    }
  }

  const addOrUpdatePump = async () => {

    var result: ApiResult<any>;
    if (props.pump) {
      result = await props.updatePump(pump, pump.id);
    } else {
      result = await props.addPump(pump);
    }

    if (result.result === AjaxResult.Success) {
      // add to old pumps table
      // send the parm to the pump
      const pumpType = pumpTypes.find(p => p.id === pump.type)?.name.toLowerCase() ?? "";
      if (pumpType != null) {
        let oldPumptType = '';
        if (pumpType.includes("aq2")) {
          if (pumpType.includes("diesel")) {
            setParm(pump.serialNumber.toString(), "75", "3");
            oldPumptType = "AQ2_ENGINE";
          } else if (pumpType.includes("auto off manual")) {
            setParm(pump.serialNumber.toString(), "75", "4");
            oldPumptType = "AQ2_ELEC_AOM"
          } else if (pumpType.includes("on off")) {
            setParm(pump.serialNumber.toString(), "75", "5");
            oldPumptType = "AQ2_ELEC_ON_OFF"
          }
          if (await addToOldPumpsTable(pump.serialNumber.toString(), oldPumptType)) {
            console.log('Added to old pumps table');
          } else {
            console.log('Error adding to old pumps table');
          }
        }
      }

      props.close();

    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editPump = (operation: (c: Pump) => void) => {
    var editedPump: Pump = { ...pump };
    operation(editedPump);
    setPump(editedPump);
  };

  const inputsValidated = useMemo(
    () =>
      parseInt(pump.phone) > 999999999 &&
      parseInt(pump.phone) < 10000000000 &&
      pump.lat > -180 &&
      pump.lat < 180 &&
      pump.lon > -180 &&
      pump.lon < 180,
    [pump]
  );

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${props.pump ? "Update" : "Add"} Pump`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          {role === UserRole.Admin && (
            <Autocomplete
              id="combo-box-demo"
              options={customers}
              getOptionLabel={(option: Customer) => option.name}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Customer Name"
                  variant="outlined"
                />
              )}
              value={customers.find(c => c.id === pump.customerId)}
              onChange={(e: any, v: Customer | null) =>
                editPump(c => (c.customerId = v ? v.id : 0))
              }
            />
          )}
          <TextField
            label="Name"
            variant="outlined"
            value={pump.name}
            onChange={value => editPump(c => (c.name = value.target.value))}
          />
          <TextField
            label="SerialNo"
            variant="outlined"
            type="number"
            value={pump.serialNumber}
            onChange={value =>
              editPump(c => (c.serialNumber = parseInt(value.target.value)))
            }
          />
          <TextField
            label="Phone"
            variant="outlined"
            value={pump.phone}
            helperText={
              parseInt(pump.phone) > 999999999 &&
                parseInt(pump.phone) < 10000000000
                ? null
                : "Invalid. Use Format: XXXXXXXXXX"
            }
            onChange={value => editPump(c => (c.phone = value.target.value))}
          />
          <TextField
            label="Weather Link"
            variant="outlined"
            value={pump.weatherLink}
            onChange={value =>
              editPump(c => (c.weatherLink = value.target.value))
            }
          />
          <TextField
            label="Latitude"
            variant="outlined"
            type="number"
            value={pump.lat}
            helperText={
              pump.lat > -180 && pump.lat < 180
                ? null
                : "Valid range -180...180"
            }
            onChange={value =>
              editPump(c => (c.lat = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Longitude"
            variant="outlined"
            type="number"
            value={pump.lon}
            helperText={
              pump.lon > -180 && pump.lon < 180
                ? null
                : "Valid range -180...180"
            }
            onChange={value =>
              editPump(c => (c.lon = parseFloat(value.target.value)))
            }
          />
          <Autocomplete
            id="combo-box-demo"
            options={pumpTypes}
            getOptionLabel={(option: PumpType) => option.name}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} label="Pump Type" variant="outlined" />
            )}
            value={pumpTypes.find(p => p.id === pump.type)}
            onChange={(e: any, p: PumpType | null) =>
              editPump(c => (c.type = p ? p.id : 0))
            }
          />
          {/* <FormGroup>
            <FormControlLabel
              control={<Checkbox
                value={hasFlowMeter}
                onChange={(e) => setHasFlowMeter(e.target.checked)}
              />}
              label="Flow Meter" />
          </FormGroup>
          {hasFlowMeter && (
            <div>Test</div>
          )} */}
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={pump.customerId === 0 || !inputsValidated}
          onClick={addOrUpdatePump}
        >
          {props.pump ? "Update Pump" : "Create Pump"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default AddEditPumps;
