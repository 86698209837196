import React, { useState } from "react";
import { PumpSensor } from "../../dtos/pumpSensor";
import { Checkbox, Button, Grid } from "@material-ui/core";
import { ChartSettings } from "../../dtos/chartSettings";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import EditSensors from "./EditPumpSensorsModal";
import { useRouteMatch } from "react-router-dom";
import { useApi } from "../apiService";
import { SensorAlarmStatus } from "../../enums/sensorAlarmStatus";
import { THEME_VARIABLES } from "../../themeColors";

export const PumpSensorSelector = ({
  pumpSensors,
  chartSettings,
  updateSelection,
  refreshPumpSensorData
}: {
  pumpSensors: PumpSensor[];
  chartSettings: ChartSettings[];
  updateSelection: (pumpSensorId: number) => void;
  refreshPumpSensorData: () => void;
}) => {
  const match = useRouteMatch<{ pumpId: string | undefined }>();
  const pumpId = match.params.pumpId ? parseInt(match.params.pumpId) : 0;
  const [
    selectedPumpSensor,
    setSelectedPumpSensor
  ] = useState<PumpSensor | null>(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const { pumpSensorsApi } = useApi();
  const classes = useStyles();

  const updateSensor = (pumpSensor: PumpSensor) => {
    setSelectedPumpSensor(pumpSensor);
    setShowAddNew(true);
  };
  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedPumpSensor(null);
    refreshPumpSensorData();
  };
  return (
    <div>
      <div className={classes.addSensorContainer}>
        <div>
          <h2>Pump Sensors</h2>
        </div>
        <div></div>
        <div></div>
      </div>
      <div className={classes.sensorList}>
        {pumpSensors.map((s, i) => {
          return (
            <div className={classes.sensorContainer} key={i}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={2}>
                  <div>
                    <Checkbox
                      onChange={() => updateSelection(s.id)}
                      checked={
                        !!chartSettings.find(c => c.pumpSensorId === s.id) ||
                        false
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div>
                    <div className={classes.sensorTitle}>{s.type}</div>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div>
                    <StatusIndicator status={s.alarmStatus || 0} />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <Button onClick={() => updateSensor(s)}>
                      <SettingsIcon />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
      <EditSensors
        isVisible={showAddNew}
        close={finishOperation}
        pumpSensor={selectedPumpSensor}
        pumpId={pumpId}
        updatePumpSensor={pumpSensorsApi.putOne}
      />
    </div>
  );
};

const StatusIndicator = ({ status }: { status: SensorAlarmStatus }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={`${classes.dot} ${SensorAlarmStatus[status]}`}></div>
      <div className={`${classes.status} ${SensorAlarmStatus[status]}`}>
        {SensorAlarmStatus[status]
          ? SensorAlarmStatus[status].replace(/([A-Z])/g, " $1").trim()
          : ""}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  dot: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    "&.AlarmLow": {
      backgroundColor: THEME_VARIABLES.COLOR_RED
    },
    "&.AlarmHigh": {
      backgroundColor: THEME_VARIABLES.COLOR_RED
    },
    "&.Normal": {
      backgroundColor: THEME_VARIABLES.COLOR_GREEN
    },
    "&.NoData": {
      backgroundColor: THEME_VARIABLES.COLOR_DARK_GRAY
    }
  },
  status: {
    display: "inline-block",
    marginLeft: "5px",
    "&.AlarmLow": {
      color: THEME_VARIABLES.COLOR_RED
    },
    "&.AlarmHigh": {
      color: THEME_VARIABLES.COLOR_RED
    },
    "&.Normal": {
      color: THEME_VARIABLES.COLOR_GREEN
    },
    "&.NoData": {
      color: THEME_VARIABLES.COLOR_DARK_GRAY
    }
  },
  sensorList: {
    maxHeight: "600px",
    marginTop: "15px"
  },
  sensorContainer: {
    border: "2px solid #e1e1e1",
    borderRadius: "8px",
    flex: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    marginBottom: "10px"
  },
  addSensorContainer: {
    flex: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    marginBottom: "10px"
  },
  averageCheckbox: {
    display: "flex",
    alignItems: "center"
  },
  sensorTitle: {},
  sensorSubtitle: {
    color: "#888",
    fontSize: "80%"
  },
  addSensorButton: {
    display: "inline-block"
  }
});
