import { BaseApi } from "./baseApi";
import { Pump } from "../dtos/pump";
import * as Dtos from "../dtos/pumpCommandParameter";

export class PumpsApi extends BaseApi<Pump> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "pumps", logout, enqueueSnackbar);
  }

  public getPumpDataLogById = (pumpId: number) =>
    this.genericRequest({
      extraPath: `${pumpId}/dataLogs`,
      method: "GET"
    });

  public getMyPumps = () =>
    this.genericRequest({
      extraPath: "myPumps",
      method: "GET"
    });

  public startPump = (parameters: Dtos.StartPumpParameters) =>
    this.genericRequest({
      extraPath: "startPump",
      method: "POST",
      data: parameters
    });

  public stopPump = (parameters: Dtos.BasePumpCommand) =>
    this.genericRequest({
      extraPath: "stopPump",
      method: "POST",
      data: parameters
    });
  public stopAllPumps = () =>
    this.genericRequest({
      extraPath: "stopAll",
      method: "POST"
    });

  public setPumpRpm = (parameters: Dtos.SetPumpRpmParameters) =>
    this.genericRequest({
      extraPath: "setPumpRpm",
      method: "POST",
      data: parameters
    });

  public openValve = (parameters: Dtos.ValveParameters) =>
    this.genericRequest({
      extraPath: "openValve",
      method: "POST",
      data: parameters
    });

  public shutValve = (parameters: Dtos.ValveParameters) =>
    this.genericRequest({
      extraPath: "shutValve",
      method: "POST",
      data: parameters
    });

  public setValveGatePercentage = (parameters: Dtos.ValveGateParameters) =>
    this.genericRequest({
      extraPath: "setValveGatePercentage",
      method: "POST",
      data: parameters
    });

  public surgeStart = (parameters: Dtos.SurgeLogixStartParameters) =>
    this.genericRequest({
      extraPath: "surgeStart",
      method: "POST",
      data: parameters
    });

  public smartStart = (parameters: Dtos.SmartStartParameters) =>
    this.genericRequest({
      extraPath: "smartStart",
      method: "POST",
      data: parameters
    });

  public setParameters = (parameters: Dtos.SetParameters) =>
    this.genericRequest({
      extraPath: "setParameters",
      method: "POST",
      data: parameters
    });
}
