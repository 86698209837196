export function getFieldSensorUnitsLabel(type: number | null, sensorName: string) {
  switch(type)
  {
    case 26:
      return 'F';        
    case 35:
      return 'V';
    case 47:
      return 'm';
    case 48:
      return 'Moisture Volate';
    case 49:
      return 'Moisture R';
    case 50:
      return 'Water Detect';
    case 53:
      return 'cfs';
    case 55:
      return 'Valve feedback';
    case 56:
      return 'm';
    case 60: 
      return 'rpm';
    case 61: 
      return 'F'
    case 62:  
      return 'psi';
    case 63: 
      return 'PTO Status';
    case 32:
      return 'type 32 placeholder'
    case null: 
      return sensorName;
    default: 
      return `type (${type}) placeholder`;
  }
}
