import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Mui from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { PumpDataLog } from "../../dtos/pumpDataLog";
import { useApi } from "../apiService";
import { useMessage } from "../messageService";
import { AjaxResult } from "../../enums/ajaxResult";

interface PumpSettingsProps {
  isVisible: boolean;
  pumpDataLog: PumpDataLog | null;
  close: () => void;
}

const PumpSettingsModal = (props: PumpSettingsProps) => {
  const classes = useStyles();
  const { pumpsApi, fieldsApi } = useApi();
  const { showMessage } = useMessage();

  const [targetRpm, setTargetRpm] = useState(0);
  const [runtimeMinutes, setRuntimeMinutes] = useState(0);
  const [surgeLogixStartFieldNumber, setSurgeLogixStartFieldNumber] = useState(1);
  const [surgeLogixOptions, setSurgeLogixOptions] = useState<JSX.Element[]>([]);
  const [gatePercentage, setGatePercentage] = useState(100);
  const [fieldNumber, setFieldNumber] = useState(1);
  const [newServiceInterval, setNewServiceInterval] = useState(
    props.pumpDataLog?.pump.serviceInterval
  );
  const [serviceHourInterval, setServiceHourInterval] = useState();

  useEffect(() => {
    const fetchFieldCount = async () => {
      if (props.pumpDataLog) {
        fieldsApi.getByPumpId(props.pumpDataLog.pump.id).then(r => {
          var optionCount = 6;
          setSurgeLogixOptions([]);
          var surgeOptionList = [];

          if (r.result === AjaxResult.Success && r.data) {
            optionCount = r.data.length;
          }

          for (var i = 0; i < optionCount; i++) {
            surgeOptionList.push(<Mui.MenuItem key={(i + 1).toString()} value={i + 1}>{i + 1}</Mui.MenuItem>)
          }
          setSurgeLogixOptions(surgeOptionList);
          if (props?.pumpDataLog?.pump?.typeName.toLowerCase().includes('aq2')) {
            getServiceIntervalValue();
          }
        });
      };
    };

    try {
      fetchFieldCount();
    } catch (e) {

    }
  }, [props, fieldsApi]);

  const stopPump = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .stopPump({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.success
                ? "Stop command received."
                : `Failed to send command: ${r.data.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };

  const startPump = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .startPump({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber,
          runtimeMinutes: runtimeMinutes
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.success
                ? "Start command received."
                : `Failed to send command: ${r.data.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };

  const surgeStart = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .surgeStart({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber,
          startField: surgeLogixStartFieldNumber
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.success
                ? "SurgeLogix Start command received."
                : `Failed to send command: ${r.data.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };

  const smartStart = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .smartStart({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber,
          runtimeMinutes: runtimeMinutes,
          targetRpm: targetRpm,
          valveNumber: fieldNumber,
          valveGatePercentage: gatePercentage
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.success
                ? "Smart Start command received."
                : `Failed to send command: ${r.data.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };

  const setPumpRpm = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .setPumpRpm({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber,
          targetRpm: targetRpm
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.success
                ? "Target RPM command received."
                : `Failed to send command: ${r.data.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };
  const setServiceInterval = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .setParameters({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber,
          parameterValue: newServiceInterval ? newServiceInterval : 100,
          parameterNumber: 24
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.result.success
                ? "Updated Pump Service Interval."
                : `Failed to send command: ${r.data.result.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };

  const resetServiceHours = async () => {
    if (props.pumpDataLog) {
      pumpsApi
        .setParameters({
          customerId: props.pumpDataLog.pump.customerId,
          pumpSerialNumber: props.pumpDataLog.pump.serialNumber,
          parameterValue: 500,
          parameterNumber: 19315
        })
        .then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            showMessage(
              r.data.result.success
                ? "Reset Service Hours command sent."
                : `Failed to send command: ${r.data.result.errorMessage}`
            );
          }
        })
        .catch(e => showMessage(e));
    }
  };

  /**
   * 
   * @param serialNumber SerialNumber of device
   * @returns parm24 which is the service hour interval
   */
  const getServiceInterval = async (serialNumber: number) => {
    console.log(props)
    const url = "https://faults.appdig.com/device/parms/";
    const response = await fetch(url + serialNumber);
    if (response.status != 200) {
      console.log('No params found.');
      return 0;
    }
    const data = await response.json();

    console.log(data);
    if (data?.parms == null) {
      return null;
    }

    const parm24 = data.parms.find((p: any) => p.number == 24).value;

    console.log(parm24);
    return parm24;
  }

  const getServiceIntervalValue = async () => {
    if (props?.pumpDataLog == null) {
      return;
    }
    const svc = await getServiceInterval(props?.pumpDataLog?.pump?.serialNumber)
    console.log(svc);
    setServiceHourInterval(svc);
  }


  return (
    <Mui.Dialog open={props.isVisible} onClose={props.close}>
      <StyledDialogTitle disableTypography>
        <h2>Pump Settings</h2>
        <div className="update-date">
          {props.pumpDataLog &&
            props.pumpDataLog.unitData &&
            props.pumpDataLog.unitData.ts
            ? `Last Updated ${props.pumpDataLog.unitData.ts}`
            : ""}
        </div>
      </StyledDialogTitle>
      {props.pumpDataLog &&
        props.pumpDataLog.unitData &&
        props.pumpDataLog.pump && (
          <Mui.DialogContent>
            <Mui.Card className={classes.card}>
              <Mui.Grid container>
                <Mui.Grid className={classes.label} item xs={6} md={3}>
                  IP Address
                </Mui.Grid>
                <Mui.Grid item xs={6} md={9}>
                  {props.pumpDataLog.unitData.ip}
                </Mui.Grid>
                <Mui.Grid className={classes.label} item xs={6} md={3}>
                  Version
                </Mui.Grid>
                <Mui.Grid item xs={6} md={9}>
                  {props.pumpDataLog.unitData.ver
                    ? (props.pumpDataLog.unitData.ver / 10).toFixed(2)
                    : 0}
                </Mui.Grid>
                <Mui.Grid className={classes.label} item xs={6} md={3}>
                  Display Version
                </Mui.Grid>
                <Mui.Grid item xs={6} md={9}>
                  {props.pumpDataLog.unitData.dspver
                    ? (props.pumpDataLog.unitData.dspver / 10).toFixed(2)
                    : 0}
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Card>
            <div className={classes.sessionData}>
              <div className={classes.sessionDataSection}>
                <div className={classes.label}>Session</div>
                <StyledSmallerDivider />
                <Mui.Grid container>
                  <Mui.Grid className={classes.label} item xs={6} md={3}>
                    Hours
                  </Mui.Grid>
                  <Mui.Grid item xs={6} md={9}>
                    {props.pumpDataLog.unitData.shours
                      ? (props.pumpDataLog.unitData.shours / 3600).toFixed(2)
                      : 0}
                  </Mui.Grid>
                  <Mui.Grid className={classes.label} item xs={6} md={3}>
                    Gallons
                  </Mui.Grid>
                  <Mui.Grid item xs={9}>
                    {props.pumpDataLog.unitData.sflow
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Mui.Grid>
                </Mui.Grid>
              </div>
              <div className={classes.sessionDataSection}>
                <div className={classes.label}>Totals</div>
                <StyledSmallerDivider />
                <Mui.Grid container>
                  <Mui.Grid className={classes.label} item xs={6} md={3}>
                    Hours
                  </Mui.Grid>
                  <Mui.Grid item xs={6} md={9}>
                    {props.pumpDataLog.unitData.thours
                      ? (props.pumpDataLog.unitData.thours / 60).toFixed(2)
                      : 0}
                  </Mui.Grid>
                  <Mui.Grid className={classes.label} item xs={6} md={3}>
                    Gallons
                  </Mui.Grid>
                  <Mui.Grid item xs={6} md={9}>
                    {props.pumpDataLog.unitData.tflow
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Mui.Grid>
                </Mui.Grid>
              </div>
            </div>
            <StyledDivider />
            <Mui.Grid container>
              <Mui.Grid className={classes.label} item xs={6} md={3}>
                Air Temp F
              </Mui.Grid>
              <Mui.Grid item xs={6} md={9}>
                {props.pumpDataLog.unitData.temp
                  ? props.pumpDataLog.unitData.temp / 10
                  : 0}
              </Mui.Grid>
              <Mui.Grid className={classes.label} item xs={6} md={3}>
                Service Hours
              </Mui.Grid>
              <Mui.Grid item xs={6} md={9}>
                {props.pumpDataLog.pump.hoursInService?.toFixed(2)}
              </Mui.Grid>
              <Mui.Grid className={classes.label} item xs={6} md={3}>
                Service Interval
              </Mui.Grid>
              <Mui.Grid item xs={6} md={9}>
                {props.pumpDataLog.pump.serviceInterval}
                {props.pumpDataLog.pump.typeName.toLowerCase().includes('aq2') &&
                  (<>{serviceHourInterval}</>)
                }
              </Mui.Grid>
              <Mui.Grid item xs={6} md={9}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={resetServiceHours}
                  className={classes.button}
                >
                  Reset Service Hours
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>

            <StyledDivider />
            <div className={classes.label}>Pump Commands</div>
            <Mui.Grid className={classes.commandSection} container>
              <Mui.Grid item xs={6}>
                <Mui.TextField
                  label="Service Interval (hrs)"
                  variant="outlined"
                  value={newServiceInterval}
                  defaultValue={props.pumpDataLog.pump.serviceInterval}
                  className={classes.commandInput}
                  onChange={value => {
                    if (!isNaN(parseInt(value.target.value))) {
                      setNewServiceInterval(parseInt(value.target.value));
                    }
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={setServiceInterval}
                  className={classes.button}
                >
                  Set Service Interval
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid className={classes.commandSection} container>
              <Mui.Grid item xs={6}>
                <Mui.TextField
                  label="Target RPM"
                  variant="outlined"
                  value={targetRpm}
                  className={classes.commandInput}
                  onChange={value => {
                    if (!isNaN(parseInt(value.target.value))) {
                      setTargetRpm(parseInt(value.target.value));
                    }
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => setPumpRpm()}
                  className={classes.button}
                >
                  Set Target RPM
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid className={classes.commandSection} container>
              <Mui.Grid item xs={6}>
                <Mui.TextField
                  label="Runtime (minutes)"
                  variant="outlined"
                  value={runtimeMinutes}
                  className={classes.commandInput}
                  onChange={value => {
                    if (!isNaN(parseInt(value.target.value))) {
                      setRuntimeMinutes(parseInt(value.target.value));
                    }
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => startPump()}
                  className={classes.button}
                >
                  Set Pump Runtime
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid className={classes.commandSection} container>
              <Mui.Grid item xs={6}>
                <Mui.TextField
                  label="Valve Gate %"
                  variant="outlined"
                  className={classes.commandInput}
                  value={gatePercentage}
                  onChange={value => {
                    if (!isNaN(parseInt(value.target.value))) {
                      setGatePercentage(parseInt(value.target.value));
                    }
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid className={classes.commandSection} container>
              <Mui.Grid className={classes.label} item xs={6}>
                <Mui.FormControl
                  className={classes.commandInput}
                  variant="outlined"
                >
                  <Mui.InputLabel id="field-number">
                    Field Number
                  </Mui.InputLabel>
                  <Mui.Select
                    labelId="field-number"
                    label="Field Number"
                    value={fieldNumber}
                    onChange={(e: any) =>
                      setFieldNumber(parseInt(e.target.value))
                    }
                  >
                    <Mui.MenuItem value="1">1</Mui.MenuItem>
                    <Mui.MenuItem value="2">2</Mui.MenuItem>
                    <Mui.MenuItem value="3">3</Mui.MenuItem>
                    <Mui.MenuItem value="4">4</Mui.MenuItem>
                    <Mui.MenuItem value="5">5</Mui.MenuItem>
                    <Mui.MenuItem value="6">6</Mui.MenuItem>
                  </Mui.Select>
                </Mui.FormControl>
              </Mui.Grid>
              <Mui.Grid className={classes.label} item xs={6}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => smartStart()}
                  className={classes.button}
                >
                  Smart Start
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid className={classes.commandSection} container>
              <Mui.Grid className={classes.label} item xs={6}>
                <Mui.FormControl
                  className={classes.commandInput}
                  variant="outlined"
                >
                  <Mui.InputLabel id="surge-number">
                    SurgeLogix Start Field
                  </Mui.InputLabel>
                  <Mui.Select
                    labelId="surge-number"
                    label="SurgeLogix Start Field"
                    value={surgeLogixStartFieldNumber}
                    onChange={(e: any) =>
                      setSurgeLogixStartFieldNumber(parseInt(e.target.value))
                    }
                  >
                    {surgeLogixOptions}
                  </Mui.Select>
                </Mui.FormControl>
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => surgeStart()}
                  className={classes.button}
                >
                  SurgeLogix Start
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Button
              variant="contained"
              color="primary"
              onClick={() => stopPump()}
              className={classes.button}
            >
              Stop Pump
            </Mui.Button>
          </Mui.DialogContent>
        )}

      <Mui.DialogActions>
        <Mui.Button variant="contained" onClick={props.close}>
          Ok
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

const StyledDialogTitle = withStyles((theme: Mui.ThemeOptions) => ({
  root: {
    color: theme.selectedItemColor,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",

    "& .update-date": {
      color: theme.textColor
    },
    paddingTop: "0px",
    paddingBottom: "0px"
  }
}))(Mui.DialogTitle);

const StyledDivider = withStyles((theme: Mui.ThemeOptions) => ({
  root: {
    marginTop: theme.standardMargin,
    marginBottom: theme.standardMargin
  }
}))(Mui.Divider);

const StyledSmallerDivider = withStyles((theme: Mui.ThemeOptions) => ({
  root: {
    marginTop: theme.standardMargin,
    marginBottom: theme.standardMargin
  }
}))(Mui.Divider);

const useStyles = makeStyles((theme: Mui.ThemeOptions) => ({
  root: {
    "& > *": {
      width: "35ch"
    }
  },
  card: {
    minWidth: "30ch",
    backgroundColor: theme.cardHeaderColor,
    marginBottom: theme.standardMargin,
    padding: theme.standardMargin
  },
  sessionData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  sessionDataSection: {
    width: "48%"
  },
  label: {
    fontWeight: "bold"
  },
  button: {
    backgroundColor: theme.selectedItemColor,
    color: theme.whiteColor,
    textTransform: "none",
    marginTop: theme.smallMargin
  },
  commandSection: {
    marginBottom: theme.smallMargin,
    marginTop: theme.smallMargin,
    display: "flex",
    alignItems: "flex-end",

    "& button": {
      marginLeft: theme.smallMargin
    }
  },
  commandInput: {
    width: "100%"
  }
}));

export default PumpSettingsModal;
