import React from "react";
import { Pump } from "../../dtos/pump";
import { Card, CardContent, makeStyles, Grid } from "@material-ui/core";
import * as mui from "@material-ui/core";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import { unitStateDisplayColor, unitStateDisplay } from "../../enums/unitState";

export const PumpWeatherCard = ({ pump }: { pump: Pump }) => {
  const { pumpCard } = useStyles();
  const classes = useStyles();

  function openLink() {
    if (pump?.weatherLink != null && pump?.weatherLink !== "") {
      window.open(pump?.weatherLink, "_blank");
    }
  }
  const statusColor = unitStateDisplayColor(
    pump.unitState ? pump.unitState : 0
  );
  const linkValid = pump?.weatherLink != null &&
    pump?.weatherLink !== "";
  const pumpTitle =
    pump.name + ": " + unitStateDisplay(pump.unitState ? pump.unitState : 0);
  return (
      <Card key={pump.id} className={pumpCard}>
        <CardContent>
          <Grid container justify="space-between">
            <div
              style={{
                color: statusColor
              }}
            >
              <header
                style={{
                  color: statusColor
                }}
              >
                {pumpTitle}
              </header>
              {pump.serialNumber}
            </div>
            {linkValid && (
                    <mui.Button
                      variant="contained"
                      onClick={() => openLink()}
                      className={classes.localWeatherButton}
                      startIcon={<WbSunnyIcon/>}
                    >
                      Weather Tab
                    </mui.Button>
                  )}
            {!linkValid && (
              <div>
                  No Weather Link
              </div>    
                  )}
          </Grid>
        </CardContent>
      </Card>
  );
};

const useStyles = makeStyles((theme : mui.ThemeOptions) => ({
  pumpCard: {
    margin: "10px 0",
    cursor: "pointer",
    "& header": {
      fontWeight: "bold"
    }
  },
  localWeatherButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  },
}));
