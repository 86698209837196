import { createContext, useContext, useMemo } from "react";
import Globals from "../globals";
import { useMessage } from "./messageService";
import { AuthContext } from "../auth/authContext";
import React from "react";
import { CustomersApi } from "../rest/customersApi";
import { UsersApi } from "../rest/usersApi";
import { UnitDataApi } from "../rest/unitDataApi";
import { PumpAccessApi } from "../rest/pumpAccessApi";
import { PumpTypeApi } from "../rest/pumpTypeApi";
import { FieldsApi } from "../rest/fieldsApi";
import { PumpsApi } from "../rest/pumpsApi";
import { SensorsApi } from "../rest/sensorsApi";
import { PumpSensorsApi } from "../rest/pumpSensorsApi";
import { SurgeLogixApi } from "../rest/surgeLogixApi";
import { ClosedLoopApi } from "../rest/closedLoopApi";

type MessageDisplayDelegate = (message: string) => void;

const buildContextValue = (
  baseUri: string,
  logout: () => void,
  displayMessage: MessageDisplayDelegate
) => {
  return {
    customersApi: new CustomersApi(baseUri, displayMessage, logout),
    usersApi: new UsersApi(baseUri, displayMessage, logout),
    pumpsApi: new PumpsApi(baseUri, displayMessage, logout),
    unitDataApi: new UnitDataApi(baseUri, displayMessage, logout),
    pumpAccessApi: new PumpAccessApi(baseUri, displayMessage, logout),
    pumpTypeApi: new PumpTypeApi(baseUri, displayMessage, logout),
    fieldsApi: new FieldsApi(baseUri, displayMessage, logout),
    sensorsApi: new SensorsApi(baseUri, displayMessage, logout),
    pumpSensorsApi: new PumpSensorsApi(baseUri, displayMessage, logout),
    surgeLogixApi: new SurgeLogixApi(baseUri, displayMessage, logout),
    closedLoopApi: new ClosedLoopApi(baseUri, displayMessage, logout)
  };
};

type ApiContextType = ReturnType<typeof buildContextValue>;

const ApiContext = createContext<ApiContextType>(
  buildContextValue(
    Globals.BASE_WEB_API_URI,
    () => {},
    (message: string) => {}
  )
);

export const ApiService = ({ children }: { children?: any }) => {
  const { showMessage } = useMessage();
  const { logout } = useContext(AuthContext);
  const api = useMemo(
    () => buildContextValue(Globals.BASE_WEB_API_URI, logout, showMessage),
    [showMessage, logout]
  );
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

/**
 * This hook gives you access, via context, to an object with the various Api classes on it
 */
export const useApi = () => useContext(ApiContext);
