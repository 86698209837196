import { BaseApi } from "./baseApi";
import { PumpSensor } from "../dtos/pumpSensor";
import moment from "moment";

export class PumpSensorsApi extends BaseApi<PumpSensor> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "pumpSensors", logout, enqueueSnackbar);
  }
  public getAllbyPump = (pumpId: number) =>
    this.genericRequest({
      extraPath: `${pumpId}/pumpSensors`,
      method: "GET"
    });
  public updatePumpHistoryPageData = (
    pumpId: number,
    startDate: Date,
    endDate: Date
  ) => {
    return this.genericRequest({
      data: { startDate, endDate, timezoneOffsetMinutes: moment().utcOffset() },
      extraPath: `${pumpId}/pumpInfo`,
      method: "POST"
    });
  };
  public updateChartSetting = (
    pumpId: number,
    pumpSensorId: number,
    isSelected: boolean
  ) =>
    this.genericRequest({
      data: { pumpId, pumpSensorId, isSelected },
      extraPath: `updateChartSettings`,
      method: "POST"
    });
}
