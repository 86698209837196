import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ThemeOptions } from "@material-ui/core";

export interface TileButtonProps {
  title: string;
  details: string;
  icon: ReactNode;
  path: string;
}

const TileButton = ({
  title,
  details,
  icon,
  path
}: TileButtonProps): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.tileButton, label: classes.buttonLabel }}
      onClick={() => history.push(path)}
    >
      <label className={classes.title}>{title}</label>
      <label className={classes.details}>{details}</label>
      <div className={classes.icon}>{icon}</div>
    </Button>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  tileButton: {
    background: `${theme.selectedItemColor} 0% 0% no-repeat padding-box`,
    border: theme.textColor,
    borderRadius: theme.shape?.borderRadius,
    opacity: 1,
    color: theme.whiteColor,
    minWidth: "300px",
    textTransform: "none",
    cursor: "pointer",
    "& label": {
      cursor: "pointer"
    },
    margin: theme.standardMargin
  },
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    fontWeight: "bold",
    fontSize: "2em",
    margin: ".5em"
  },
  details: {
    fontSize: "1em",
    margin: theme.standardMargin
  },
  icon: {
    margin: theme.standardMargin
  }
}));

export default TileButton;
