import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Mui from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

enum DateOption {
  Last24Hours = 1,
  Last48Hours = 2,
  LastWeek = 3,
  LastMonth = 4,
  YTD = 5,
  Custom = 6
}

const isSelectedOption = (
  referenceDate: Date,
  startDate: Date,
  endDate: Date,
  daysAgo: number
) => {
  let date = new Date(referenceDate);
  date.setDate(date.getDate() - daysAgo);
  if (referenceDate == null || endDate == null || startDate == null) {
    return false;
  } else if (referenceDate.toDateString() !== endDate.toDateString()) {
    return false;
  } else if (startDate.toDateString() !== date.toDateString()) {
    return false;
  } else {
    return true;
  }
};

const getStartDate = (referenceDate: Date, daysAgo: number) => {
  let date = new Date(referenceDate);
  date.setDate(date.getDate() - daysAgo);
  return date;
};

export const DateSelector = ({
  updateDateSelection
}: {
  updateDateSelection: (startDate: Date, endDate: Date) => void;
}) => {
  const [isSelectorOpen, setSelectorOpen] = useState(false);
  const classes = useStyles();

  const toggleSelectorOpen = () => setSelectorOpen(!isSelectorOpen);

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);

  const selectedOption =
    (isSelectedOption(today, startDate, endDate, 1) &&
      DateOption.Last24Hours) ||
    (isSelectedOption(today, startDate, endDate, 2) &&
      DateOption.Last48Hours) ||
    (isSelectedOption(today, startDate, endDate, 7) && DateOption.LastWeek) ||
    (isSelectedOption(today, startDate, endDate, 30) && DateOption.LastMonth) ||
    (isSelectedOption(today, startDate, endDate, Math.floor((new Date().getTime() -new Date(new Date().getFullYear(), 0, 1).getTime())/86400000)) && DateOption.YTD) ||
    DateOption.Custom;

  const onStartDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    setStartDate(date);
  };

  const onEndDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    setEndDate(date);
  };

  const applyDateSelection = () => {
    updateDateSelection(startDate, endDate);
    toggleSelectorOpen();
  };

  const updateSelectedOption = (option: DateOption) => {
    switch (option) {
      case DateOption.Last24Hours:
        setStartDate(getStartDate(today, 1));
        setEndDate(today);
        break;
      case DateOption.Last48Hours:
        setStartDate(getStartDate(today, 2));
        setEndDate(today);
        break;
      case DateOption.LastWeek:
        setStartDate(getStartDate(today, 7));
        setEndDate(today);
        break;
      case DateOption.LastMonth:
        setStartDate(getStartDate(today, 30));
        setEndDate(today);
        break;
      case DateOption.YTD:
        setStartDate(new Date(new Date().getFullYear(), 0, 1));
        setEndDate(today);
        break;
      case DateOption.Custom:
        break;
    }
  };

  return (
    <div className={classes.selectorWrapper}>
      <Mui.Button
        className={`${classes.dateRangeButton}`}
        onClick={toggleSelectorOpen}
      >
        Date Range
      </Mui.Button>
      {isSelectorOpen && (
        <div className={classes.dateSelectorWrapper}>
          <div className={classes.dateSelector}>
            <div className={classes.dateRangeOptions}>
              <ButtonOption
                selectedOption={selectedOption}
                updateSelectedOption={updateSelectedOption}
                dateOption={DateOption.Last24Hours}
                text={"Last 24 Hours"}
              />
              <ButtonOption
                selectedOption={selectedOption}
                updateSelectedOption={updateSelectedOption}
                dateOption={DateOption.Last48Hours}
                text={"Last 48 Hours"}
              />
              <ButtonOption
                selectedOption={selectedOption}
                updateSelectedOption={updateSelectedOption}
                dateOption={DateOption.LastWeek}
                text={"Last Week"}
              />
              <ButtonOption
                selectedOption={selectedOption}
                updateSelectedOption={updateSelectedOption}
                dateOption={DateOption.LastMonth}
                text={"Last Month"}
              />
              <ButtonOption
                selectedOption={selectedOption}
                updateSelectedOption={updateSelectedOption}
                dateOption={DateOption.YTD}
                text={"Year to Date"}
              />
              <ButtonOption
                selectedOption={selectedOption}
                updateSelectedOption={updateSelectedOption}
                dateOption={DateOption.Custom}
                text={"Custom"}
              />
            </div>
            <div className={classes.pickerWrapper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  maxDate={endDate}
                  value={startDate}
                  onChange={onStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="End Date"
                  minDate={startDate}
                  value={endDate}
                  onChange={onEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={classes.submitButtons}>
            <Mui.Button
              className={classes.applyButton}
              onClick={applyDateSelection}
            >
              Apply
            </Mui.Button>
            <Mui.Button onClick={toggleSelectorOpen}>Cancel</Mui.Button>
          </div>
        </div>
      )}
    </div>
  );
};

const ButtonOption = ({
  selectedOption,
  updateSelectedOption,
  dateOption,
  text
}: {
  selectedOption: DateOption;
  updateSelectedOption: (option: DateOption) => void;
  dateOption: DateOption;
  text: string;
}) => {
  const classes = useStyles();
  return (
    <div>
      <Mui.Button
        onClick={() => updateSelectedOption(dateOption)}
        className={`${classes.optionButton} ${(dateOption === selectedOption &&
          classes.optionSelected) ||
          ""}`}
      >
        {text}
      </Mui.Button>
    </div>
  );
};
const useStyles = makeStyles({
  notSelected: {
    color: "#000 !important",
    backgroundColor: "#fff !important",
    fontWeight: "initial !important" as any
  },
  optionSelected: {
    backgroundColor: "#EAF4FE"
  },
  optionButton: {
    textTransform: "none"
  },
  selectorWrapper: {
    position: "relative"
  },
  dateRangeOptions: {
    width: "130px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    borderRight: "2px solid #EBEBEB"
  },
  dateRangeButton: {
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#2991FB",
    "&:hover": {
      color: "#000"
    }
  },
  dateSelector: {
    display: "flex"
  },
  dateSelectorWrapper: {
    position: "absolute",
    right: "-100px",
    top: "45px",
    border: "2px solid #EBEBEB",
    backgroundColor: "#fff",
    borderRadius: "10px",
    zIndex: 5
  },
  pickerWrapper: {
    width: "250px",
    margin: "10px 0"
  },
  submitButtons: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      textTransform: "none"
    },
    margin: "10px 20px"
  },
  applyButton: {
    color: "#fff",
    backgroundColor: "#2991FB",
    "&:hover": {
      color: "#000"
    },
    marginLeft: "10px"
  }
});
