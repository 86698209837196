import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";

import { PumpAccessDto } from "../../../dtos/pumpaccess";
import { useMessage } from "../../messageService";
import MaterialTable, { HeaderCells } from "../../shared/materialTable";
import AddEditPumpAccess from "./addEditPumpAccessModal";
import { useApi } from "../../apiService";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../confirmDialogService";

const PumpAccessPage = () => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      {role === UserRole.Admin ||
      role === UserRole.Manager ||
      role === UserRole.Operator ? (
        <PumpAccessTable />
      ) : (
        <div>You do not have permissions to view this page.</div>
      )}
    </div>
  );
};

const PumpAccessTable = () => {
  const [pumpAccess, setPumpAccess] = useState<PumpAccessDto[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { pumpAccessApi } = useApi();
  const { showMessage } = useMessage();

  const [
    selectedPumpAccess,
    setSelectedPumpAccess
  ] = useState<PumpAccessDto | null>(null);

  const addUpdatePumpAccess = (pumpAccessDto: PumpAccessDto | null) => {
    setSelectedPumpAccess(pumpAccessDto);
    setShowAddNew(true);
  };

  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedPumpAccess(null);
    fetchData();
  };

  const tryDeletePumpAccess = (pumpAccessDto: PumpAccessDto) => {
    showConfirmDialog(
      `Are you sure you want to delete access${
        pumpAccessDto.pumpName ? " to " + pumpAccessDto.pumpName : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        pumpAccessApi.delete(pumpAccessDto.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("User deleted");
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  const fetchData = async () => {
    showMessage("Fetching pump access...");
    pumpAccessApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Pump Access fetched!");
        setPumpAccess(r.data);
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<PumpAccessDto>[] = [
    {
      id: "customerName",
      numeric: false,
      disablePadding: true,
      label: "Customer",
      accessor: h => h.customerName
    },
    {
      id: "username",
      numeric: false,
      disablePadding: true,
      label: "Username",
      accessor: h => h.username
    },
    {
      id: "pumpName",
      numeric: false,
      disablePadding: false,
      label: "Pump name",
      accessor: h => h.pumpName
    }
  ];

  return (
    <>
      <MaterialTable
        title="Pump Access"
        headerCells={headCells}
        rows={pumpAccess}
        defaultRowsPerPage={5}
        showAddButton={true}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdatePumpAccess}
        handleDelete={tryDeletePumpAccess}
      />
      <AddEditPumpAccess
        isVisible={showAddNew}
        close={finishOperation}
        pumpAccessDto={selectedPumpAccess}
        pumpAccessDtos={pumpAccess}
        addPumpAccess={pumpAccessApi.postOne}
        updatePumpAccess={pumpAccessApi.putOne}
      />
    </>
  );
};

export default PumpAccessPage;
