import { useRouteMatch, Prompt } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { SurgeLogix } from "../../dtos/surgeLogix";
import { Pump } from "../../dtos/pump";
import { useMessage } from "../messageService";
import MaterialTable, { HeaderCells } from "../shared/materialTable";
import EditSurgeLogix from "./editSurgeLogixModal";
import { useApi } from "../apiService";
import { AjaxResult } from "../../enums/ajaxResult";
import { useConfirmDialog } from "../confirmDialogService";
import * as mui from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { useLeavingPageAlert } from "../../hooks/useLeavingPageAlert";

const SurgeLogixPage = () => {
  const match = useRouteMatch<{ pumpId: string | undefined }>();
  const pumpId = match.params.pumpId ? parseInt(match.params.pumpId) : 0;
  const { pumpsApi, surgeLogixApi } = useApi();
  const { showMessage } = useMessage();
  const [pump, setPump] = useState<Pump | null>(null);
  const classes = useStyles();
  const { showConfirmDialog } = useConfirmDialog();

  const [
    pumpInconsistentWithCommands,
    setPumpInconsistentWithCommands
  ] = useState(false);

  useLeavingPageAlert(pumpInconsistentWithCommands);

  useEffect(() => {
    const fetchPumpData = async () => {
      showMessage("Fetching pumps...");
      pumpsApi.getById(pumpId).then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Pumps fetched!");
          setPump(r.data);
        }
      });
    };
    fetchPumpData();
  }, [showMessage, pumpsApi, pumpId, setPump]);

  const trySurgeLogixCommand = () => {
    showConfirmDialog(
      `Are you sure you want to send surgeLogix to ${
        pump?.name ? " " + pump.name : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        surgeLogixApi.sendToPump(pumpId).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("Command sent to Pump");
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
              setPumpInconsistentWithCommands(false);
            });
          }
        });
      }
    });
  };

  return (
    <>
      <Prompt
        when={pumpInconsistentWithCommands}
        message="You have changes that have not been pushed to the pump, are you sure you want to leave?"
      />
      <div className={classes.root}>
        <div className={classes.sectionHeader}>
          <div className={classes.headerSection}>
            <label>{pump ? pump.name : "Pump "}</label>
            <div className={classes.serialNumber}>
              {pump ? pump.serialNumber : "SN"}
            </div>
          </div>
          <div className={classes.headerSection}>
            <mui.Button
              variant="contained"
              color="primary"
              className={classes.AutoControlButton}
              endIcon={<PlayCircleOutlineIcon />}
              onClick={trySurgeLogixCommand}
            >
              Send to Pump
            </mui.Button>
          </div>
        </div>
        <SurgeLogixTable
          markStepsChanged={() => setPumpInconsistentWithCommands(true)}
        />
      </div>
    </>
  );
};

const SurgeLogixTable = (props: { markStepsChanged: () => void }) => {
  const [surgeLogixs, setSurgeLogixs] = useState<SurgeLogix[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { surgeLogixApi } = useApi();
  const { showMessage } = useMessage();
  const [
    selectedSurgeLogix,
    setSelectedSurgeLogix
  ] = useState<SurgeLogix | null>(null);

  const match = useRouteMatch<{ pumpId: string | undefined }>();
  const pumpId = match.params.pumpId ? parseInt(match.params.pumpId) : 0;

  const addUpdateSurgeLogix = (surgeLogix: SurgeLogix | null) => {
    setSelectedSurgeLogix(surgeLogix);
    setShowAddNew(true);
  };
  const zeroSurgeLogix = (surgeLogix: SurgeLogix) => {
    showConfirmDialog(
      `Are you sure you want to clear ${
        surgeLogix.fieldName ? " " + surgeLogix.fieldName : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        surgeLogix.count = 0;
        surgeLogix.duration = 0;
        surgeLogix.adjust = 0;
        surgeLogixApi.putOne(surgeLogix, surgeLogix.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("Field deleted");
            props.markStepsChanged();
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };
  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedSurgeLogix(null);
    fetchData();
  };

  const fetchData = async () => {
    showMessage("Fetching Entries...");
    surgeLogixApi.getAllbyPump(pumpId).then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Entries fetched!");
        setSurgeLogixs(r.data);
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<SurgeLogix>[] = [
    {
      id: "fieldName",
      numeric: false,
      disablePadding: true,
      label: "Field name",
      accessor: h => h.fieldName
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
      accessor: h => h.type
    },
    {
      id: "count",
      numeric: true,
      disablePadding: true,
      label: "Cycle Count",
      accessor: h => h.count
    },
    {
      id: "duration",
      numeric: true,
      disablePadding: false,
      label: "Set Duration(min)",
      accessor: h => h.duration
    },
    {
      id: "adjust",
      numeric: true,
      disablePadding: false,
      label: "Cycle Time Adjust (%)",
      accessor: h => h.adjust
    }
  ];

  return (
    <>
      <MaterialTable
        title="surgeLogix Steps"
        headerCells={headCells}
        rows={surgeLogixs}
        defaultRowsPerPage={12}
        showAddButton={false}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdateSurgeLogix}
        handleDelete={zeroSurgeLogix}
      />
      <EditSurgeLogix
        isVisible={showAddNew}
        close={finishOperation}
        surgeLogix={selectedSurgeLogix}
        updateSurgeLogix={surgeLogixApi.putOne}
        markStepsChanged={props.markStepsChanged}
      />
    </>
  );
};

const useStyles = makeStyles((theme: mui.ThemeOptions) => ({
  root: {
    width: "100%",
    marginBottom: theme.standardMargin
  },
  tableContainer: {
    maxHeight: "40vh"
  },
  headerSection: {
    display: "flex",
    maxWidth: "900px",
    justifySelf: "center",
    marginBottom: theme.standardMargin,
    "& div": {
      marginLeft: ".5em"
    },
    "& button": {
      marginLeft: ".5em"
    }
  },
  serialNumber: {
    color: theme.textColor,
    fontSize: "1em",
    fontWeight: "normal"
  },
  sectionHeader: {
    color: theme.selectedItemColor,
    justifySelf: "start",
    fontSize: "1.25em",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  informationRoot: {
    width: "100%"
  },
  AutoControlButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  }
}));

export default SurgeLogixPage;
