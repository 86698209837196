import { createContext } from "react";
import { AuthApi } from "../rest/authApi";
import Globals from "../globals";

export const AuthContext = createContext<{
  username: string | null;
  email: string | null;
  role: string | null;
  loginInProgress: boolean;
  authApi: AuthApi;
  customerId: number | null;
  logout: () => void;
  login: (creds: { username: string; password: string }) => void;
}>({
  username: null,
  email: null,
  loginInProgress: false,
  role: null,
  authApi: new AuthApi(Globals.BASE_WEB_API_URI),
  customerId: null,
  logout: () => {},
  login: () => {}
});
