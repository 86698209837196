import { THEME_VARIABLES } from "./themeColors";

// this pattern of doing things comes from https://material-ui.com/customization/theming/#custom-variables
declare module "@material-ui/core/styles/createMuiTheme" {
  interface THEME {
    navbarColor: string;
    whiteColor: string;
    cardHeaderColor: string;
    cardInfoBackgroundColor: string;
    fixtureInfoBackgroundColor: string;
    border1: string;
    mainBackgroundColor: string;
    selectedItemColor: string;
    iconColor: string;
    textColor: string;
    errorColor: string;
    darkTextColor: string;
    labelColor: string;

    smallMargin: string;
    standardMargin: string;
    standardBoxShadow: string;

    minWidthFullScreen: number;
  }

  /**
   * Has the same properties as THEME, but all optional
   */
  interface ThemeOptions extends Partial<THEME> {}
}

export const THEME = {
  mainBackgroundColor: THEME_VARIABLES.COLOR_LIGHT_BLUE,
  whiteColor: THEME_VARIABLES.COLOR_WHITE,
  navbarColor: THEME_VARIABLES.COLOR_WHITE,

  cardHeaderColor: THEME_VARIABLES.COLOR_GRAY_0,
  textColor: THEME_VARIABLES.COLOR_GRAY_1,
  labelColor: THEME_VARIABLES.COLOR_GRAY_2,
  darkTextColor: THEME_VARIABLES.COLOR_DARK_GRAY,
  errorColor: THEME_VARIABLES.COLOR_RED,

  border1: `1px solid ${THEME_VARIABLES.COLOR_GRAY_1}`,
  smallMargin: "0.5em",
  standardMargin: "1em",
  standardBoxShadow: "0px 0px 3px #00000026",

  selectedItemColor: THEME_VARIABLES.COLOR_BLUE_1,
  iconColor: THEME_VARIABLES.COLOR_BLUE_2,
  palette: {
    primary: {
      main: THEME_VARIABLES.COLOR_BLUE_1,
      light: THEME_VARIABLES.COLOR_WHITE,
      dark: THEME_VARIABLES.COLOR_BLUE_1,
      contrastText: THEME_VARIABLES.COLOR_BLUE_1
    }
  },
  shape: {
    borderRadius: 6
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        color: THEME_VARIABLES.COLOR_BLUE_2
      }
    },
    MuiFormHelperText: {
      root: {
        color: THEME_VARIABLES.COLOR_RED
      }
    },
    MuiPickersDay: {
      daySelected: {
        color: THEME_VARIABLES.COLOR_WHITE
      }
    }
  },
  minWidthFullScreen: 1250
};
