import { useState } from "react";
import { Bounds } from "google-map-react";

export const useMapState = () => {
  const [mapZoom, setMapZoom] = useState(15);
  const [mapCenter, setMapCenter] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [bounds, setBounds] = useState<Bounds | null>(null);
  return {
    mapZoom,
    setMapZoom,
    mapCenter,
    setMapCenter,
    bounds,
    setBounds
  };
};
