import * as mui from "@material-ui/core";
import React, { createContext, useState, useRef, useContext } from "react";
import { defer } from "../utility/defer";

/**
 * This hook gives you access to a function that can be used to display a confirmation dialog and handle the response.
 * The "showConfirmDialog" function returns a promise - the resolved value of the promise is a boolean that indicates
 * whether the user clicked the "Confirm" button
 */
export const useConfirmDialog = () => {
  const { showConfirmDialog } = useContext(ConfirmDialogContext);
  return { showConfirmDialog };
};

export const ConfirmDialogService = ({ children }: { children?: any }) => {
  const {
    confirmMessage,
    resolveWithFalse,
    resolveWithTrue,
    showConfirmDialog,
    confirmDialogIsVisible,
    setConfirmDialogIsVisible
  } = useConfirmDialogGlobal();
  return (
    <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
      {children}
      <ConfirmDialog
        isVisible={confirmDialogIsVisible}
        message={confirmMessage}
        close={() => setConfirmDialogIsVisible(false)}
        confirm={resolveWithTrue}
        cancel={resolveWithFalse}
      />
    </ConfirmDialogContext.Provider>
  );
};

const useConfirmDialogGlobal = () => {
  const [confirmDialogIsVisible, setConfirmDialogIsVisible] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const promiseRef = useRef<ReturnType<typeof defer> | null>(null);
  const showConfirmDialog = (message: string) => {
    setConfirmMessage(message);
    setConfirmDialogIsVisible(true);
    promiseRef.current = defer();
    return promiseRef.current.promise as Promise<boolean>;
  };
  const resolveWithTrue = () => {
    if (promiseRef.current && promiseRef.current.resolve) {
      promiseRef.current.resolve(true);
    }
  };
  const resolveWithFalse = () => {
    if (promiseRef.current && promiseRef.current.resolve) {
      promiseRef.current.resolve(false);
    }
  };
  return {
    confirmMessage,
    showConfirmDialog,
    resolveWithTrue,
    resolveWithFalse,
    confirmDialogIsVisible,
    setConfirmDialogIsVisible
  };
};

const ConfirmDialogContext = createContext<{
  showConfirmDialog: (message: string) => Promise<boolean>;
}>({
  showConfirmDialog: (message: string) =>
    new Promise<boolean>(r => {
      r(false);
    })
});

const ConfirmDialog = ({
  isVisible,
  message,
  close,
  confirm,
  cancel
}: {
  isVisible: boolean;
  message: string;
  close: () => void;
  confirm: () => void;
  cancel: () => void;
}) => (
  <mui.Dialog open={isVisible}>
    <mui.DialogContent>{message}</mui.DialogContent>
    <mui.DialogActions>
      <mui.Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          close();
          cancel();
        }}
      >
        Cancel
      </mui.Button>
      <mui.Button
        color="primary"
        variant="outlined"
        onClick={() => {
          close();
          confirm();
        }}
      >
        Confirm
      </mui.Button>
    </mui.DialogActions>
  </mui.Dialog>
);
