import { useRouteMatch, Prompt } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { ClosedLoop, getDefaultClosedLoop } from "../../dtos/closedLoop";
import { Pump } from "../../dtos/pump";
import { useMessage } from "../messageService";
import MaterialTable, { HeaderCells } from "../shared/materialTable";
import AddEditClosedLoop from "./addEditClosedLoopModal";
import { useApi } from "../apiService";
import { AjaxResult } from "../../enums/ajaxResult";
import { useConfirmDialog } from "../confirmDialogService";
import * as mui from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { useLeavingPageAlert } from "../../hooks/useLeavingPageAlert";

const ClosedLoopPage = () => {
  const match = useRouteMatch<{ pumpId: string | undefined }>();
  const pumpId = match.params.pumpId ? parseInt(match.params.pumpId) : 0;
  const { pumpsApi, closedLoopApi } = useApi();
  const { showMessage } = useMessage();
  const [pump, setPump] = useState<Pump | null>(null);
  const classes = useStyles();
  const { showConfirmDialog } = useConfirmDialog();
  const [
    pumpInconsistentWithCommands,
    setPumpInconsistentWithCommands
  ] = useState(false);

  useLeavingPageAlert(pumpInconsistentWithCommands);

  useEffect(() => {
    const fetchPumpData = async () => {
      showMessage("Fetching pumps...");
      pumpsApi.getById(pumpId).then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          showMessage("Pumps fetched!");
          setPump(r.data);
        }
      });
    };
    fetchPumpData();
  }, [showMessage, pumpsApi, pumpId, setPump]);

  const tryClosedLoopCommand = () => {
    showConfirmDialog(
      `Are you sure you want to send ClosedLoop Commands to ${pump?.name ? " " + pump.name : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        closedLoopApi.sendToPump(pumpId).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("Command sent to Pump");
            setPumpInconsistentWithCommands(false);
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  return (
    <>
      <Prompt
        when={pumpInconsistentWithCommands}
        message="You have changes that have not been pushed to the pump, are you sure you want to leave?"
      />
      <div className={classes.root}>
        <div className={classes.sectionHeader}>
          <div className={classes.headerSection}>
            <label>{pump ? pump.name : "Pump "}</label>
            <div className={classes.serialNumber}>
              {pump ? pump.serialNumber : "SN"}
            </div>
          </div>
          <div className={classes.headerSection}>
            <mui.Button
              variant="contained"
              color="primary"
              className={classes.AutoControlButton}
              endIcon={<PlayCircleOutlineIcon />}
              onClick={tryClosedLoopCommand}
            >
              Send to Pump
            </mui.Button>
          </div>
        </div>
        <ClosedLoopTable
          markStepsChanged={() => setPumpInconsistentWithCommands(true)}
        />
      </div>
    </>
  );
};

const ClosedLoopTable = (props: { markStepsChanged: () => void }) => {
  const [closedLoops, setClosedLoops] = useState<ClosedLoop[]>([]);
  const { showConfirmDialog } = useConfirmDialog();
  const { closedLoopApi } = useApi();
  const { showMessage } = useMessage();
  const [
    selectedClosedLoop,
    setSelectedClosedLoop
  ] = useState<ClosedLoop | null>(null);

  const match = useRouteMatch<{ pumpId: string | undefined }>();
  const pumpId = match.params.pumpId ? parseInt(match.params.pumpId) : 0;

  const addUpdateClosedLoop = (closedLoop: ClosedLoop | null) => {
    setSelectedClosedLoop(closedLoop ? closedLoop : getDefaultClosedLoop());
  };
  const finishOperation = () => {
    setSelectedClosedLoop(null);
    fetchData();
  };

  const tryDeleteClosedLoop = (closedLoop: ClosedLoop) => {
    showConfirmDialog(
      `Are you sure you want to delete ClosedLoop for ${closedLoop.fieldName ? " " + closedLoop.fieldName : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        closedLoopApi.delete(closedLoop.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("ClosedLoop deleted");
            props.markStepsChanged();
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  const fetchData = async () => {
    showMessage("Fetching Entries...");
    closedLoopApi.getAllbyPump(pumpId).then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Entries fetched!");
        setClosedLoops(r.data.map((row: any) => {
          if (row.stopSensorSerialNumber === 0) {
            row.stopSensorName = 'Time';
          }
          return row;
        }));
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<ClosedLoop>[] = [
    {
      id: "fieldName",
      numeric: false,
      disablePadding: true,
      label: "Field name",
      accessor: h => h.fieldName
    },
    {
      id: "startSensorName",
      numeric: false,
      disablePadding: true,
      label: "Start Sensor Name",
      accessor: h => h.startSensorName
    },
    {
      id: "startValue",
      numeric: false,
      disablePadding: true,
      label: "Start Value",
      accessor: h => h.startValue
    },
    {
      id: "stopSensorName",
      numeric: false,
      disablePadding: true,
      label: "Stop Sensor Name",
      accessor: h => h.stopSensorName
    },
    {
      id: "stopValue",
      numeric: false,
      disablePadding: true,
      label: "Stop Value",
      accessor: h => h.stopValue
    }
  ];

  return (
    <>
      <MaterialTable
        title="ClosedLoop Steps"
        headerCells={headCells}
        rows={closedLoops}
        defaultRowsPerPage={5}
        showAddButton={true}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdateClosedLoop}
        handleDelete={tryDeleteClosedLoop}
      />
      <AddEditClosedLoop
        isVisible={selectedClosedLoop !== null}
        pumpId={pumpId}
        closedLoop={selectedClosedLoop}
        addClosedLoop={closedLoopApi.postOne}
        updateClosedLoop={closedLoopApi.putOne}
        close={finishOperation}
        markStepsChanged={props.markStepsChanged}
      />
    </>
  );
};

const useStyles = makeStyles((theme: mui.ThemeOptions) => ({
  root: {
    width: "100%",
    marginBottom: theme.standardMargin
  },
  tableContainer: {
    maxHeight: "40vh"
  },
  headerSection: {
    display: "flex",
    maxWidth: "900px",
    justifySelf: "center",
    marginBottom: theme.standardMargin,
    "& div": {
      marginLeft: ".5em"
    },
    "& button": {
      marginLeft: ".5em"
    }
  },
  serialNumber: {
    color: theme.textColor,
    fontSize: "1em",
    fontWeight: "normal"
  },
  sectionHeader: {
    color: theme.selectedItemColor,
    justifySelf: "start",
    fontSize: "1.25em",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  informationRoot: {
    width: "100%"
  },
  AutoControlButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  }
}));

export default ClosedLoopPage;
