import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";

import { PumpType } from "../../../dtos/pumpType";
import { useMessage } from "../../messageService";
import MaterialTable, { HeaderCells } from "../../shared/materialTable";
import AddEditPumpType from "./addEditPumpType";
import { useApi } from "../../apiService";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../confirmDialogService";

const PumpTypesPage = () => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      {role === UserRole.Admin ? (
        <PumpTypesTable />
      ) : (
        <div>You do not have permissions to view this page.</div>
      )}
    </div>
  );
};

const PumpTypesTable = () => {
  const [pumpType, setPumpType] = useState<PumpType[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { pumpTypeApi } = useApi();
  const { showMessage } = useMessage();

  const [selectedPumpType, setSelectedPumpType] = useState<PumpType | null>(
    null
  );

  const addUpdatePumpType = (pumpTypeDto: PumpType | null) => {
    setSelectedPumpType(pumpTypeDto);
    setShowAddNew(true);
  };

  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedPumpType(null);
    fetchData();
  };

  const tryDeletePumpType = (pumpTypeDto: PumpType) => {
    showConfirmDialog("Cannot delete pump type");
  };

  const fetchData = async () => {
    showMessage("Fetching pump types...");
    pumpTypeApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Pump Types fetched!");
        setPumpType(r.data);
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<PumpType>[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Type name",
      accessor: h => h.name
    }
  ];

  return (
    <>
      <MaterialTable
        title="Pump Type"
        headerCells={headCells}
        rows={pumpType}
        defaultRowsPerPage={5}
        showAddButton={true}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdatePumpType}
        handleDelete={tryDeletePumpType}
      />
      <AddEditPumpType
        isVisible={showAddNew}
        close={finishOperation}
        pumpType={selectedPumpType}
        addPumpType={pumpTypeApi.postOne}
        updatePumpType={pumpTypeApi.putOne}
      />
    </>
  );
};

export default PumpTypesPage;
