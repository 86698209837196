import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Pump } from "../../dtos/pump";
import { Field } from "../../dtos/field";
import { Sensor } from "../../dtos/sensor";
import { AjaxResult } from "../../enums/ajaxResult";
import { PumpCard } from "./pumpCard";
import { FieldCard } from "./fieldCard";
import { SensorCard } from "./sensorCard";
import * as mui from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import { useWindowSize } from "@react-hook/window-size";
import { SearchRounded } from "@material-ui/icons";
import { MapMarker } from "./mapMarker";
import { ClusterMarker } from "./clusterMarker";
import { useMapState } from "./useMapState";
import { useClusteredPumps } from "./useClusteredPumps";
import { useApi } from "../apiService";
import { AuthContext } from "../../auth/authContext";
import { makeStyles } from "@material-ui/styles";
import { ThemeOptions, useMediaQuery } from "@material-ui/core";
import { UserRole } from "../../enums/userRoles";
import { HomeCard } from "../../enums/homeCard";
import Globals from "../../globals";
import { ApiResult } from "../../rest/baseApi";
import { useConfirmDialog } from "../confirmDialogService";
import { useMessage } from "../messageService";
import { MapViewType, MapTypeSelect } from "./mapTypeSelect";
import { CardTypeSelect } from "./cardSelect";
import linq from "linq";
import { PumpWeatherCard } from "./pumpWeatherCard";
import { useTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import { classicNameResolver } from "typescript";
import { Link } from "react-router-dom";

export const HomePage = () => {
  const { buttonContainer, searchBar, stopButton, mapTypeStyle } = useStyles();
  const { customerId, role } = useContext(AuthContext);
  const { pumpsApi, fieldsApi, sensorsApi } = useApi();
  const [pumps, setPumps] = useState<Pump[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [pumpFieldsLoaded, setPumpFieldsLoaded] = useState<number[]>([]);
  const [pumpSensorsLoaded, setPumpSensorsLoaded] = useState<number[]>([]);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const mapRef = useRef();
  const [mapRefIsLoaded, setMapRefIsLoaded] = useState(false);
  const { bounds, mapCenter, mapZoom, setBounds, setMapCenter, setMapZoom } = useMapState();
  const height = useWindowSize()[1];
  const pageContentHeight = `${height - 226}px`;
  const listContentHeight = `${height - 177}px`;
  const { showConfirmDialog } = useConfirmDialog();
  const { showMessage } = useMessage();
  const [mapViewType, setMapViewType] = useState<MapViewType>("satellite");
  const [cardType, setCardType] = useState<HomeCard>(HomeCard.Pumps);

  useEffect(() => {
    const apiMethod =
      role === UserRole.Admin
        ? () => pumpsApi.getAll()
        : (role === UserRole.Manager || role === UserRole.Operator) &&
          customerId
          ? () => pumpsApi.getForCustomer(customerId)
          : () => pumpsApi.getMyPumps();
    apiMethod().then(r => {
      if (r.result === AjaxResult.Success && r.data) {

        var orderedPumps = r.data.sort(function (a: Pump, b: Pump) {
          return a.name.trim().localeCompare(b.name.trim(), undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });

        setPumps(orderedPumps);
      }
    });
  }, [pumpsApi, customerId, role]);
  const pumpsWithCoords = useMemo(() => pumps.filter(p => !!p.lat && !!p.lon), [
    pumps
  ]);

  const sensorsWithCoords = useMemo(
    () =>
      sensors.filter(
        s =>
          !!s.lat &&
          !!s.lon
      ),
    [sensors]
  );
  const fieldsWithCoords = useMemo(
    () =>
      fields.filter(
        f =>
          !!f.lat &&
          !!f.lon
      ),
    [fields]
  );
  const defaultCenter = useMemo(() => {
    if (!pumpsWithCoords || !pumpsWithCoords.length) {
      return undefined;
    }
    const pumpsLinq = linq.from(pumpsWithCoords);
    const minLat = pumpsLinq.min(p => p.lat);
    const minLong = pumpsLinq.min(p => p.lon);
    const maxLat = pumpsLinq.max(p => p.lat);
    const maxLong = pumpsLinq.max(p => p.lon);
    return {
      lat: ((maxLat + minLat) / 2) as number,
      lng: ((maxLong + minLong) / 2) as number
    };
  }, [pumpsWithCoords]);

  useEffect(() => {
    if (!pumpsWithCoords || !pumpsWithCoords.length || !mapRefIsLoaded) {
      return;
    }
    const pumpsLinq = linq.from(pumpsWithCoords);
    const minLat = pumpsLinq.min(p => p.lat);
    const minLong = pumpsLinq.min(p => p.lon);
    const maxLat = pumpsLinq.max(p => p.lat);
    const maxLong = pumpsLinq.max(p => p.lon);
    if (maxLat - minLat > .0005 || maxLong - minLong > .0005) {
      (mapRef.current as any).fitBounds({
        east: maxLong,
        west: minLong,
        north: maxLat,
        south: minLat
      });
    }
  }, [mapRefIsLoaded, pumpsWithCoords]);

  const [searchText, setSearchText] = useState("");
  const filteredPumps = useMemo(() => {
    return searchText
      ? pumpsWithCoords.filter(
        p =>
          p.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          p.serialNumber?.toString()?.indexOf(searchText) !== -1
      )
      : pumpsWithCoords;
  }, [pumpsWithCoords, searchText]);
  const filteredFields = useMemo(() => {
    return searchText
      ? fieldsWithCoords.filter(
        f =>
          f.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          f.valveSerialNumber?.toString()?.indexOf(searchText) !== -1
      )
      : fieldsWithCoords;
  }, [fieldsWithCoords, searchText]);
  const filteredSensors = useMemo(() => {
    return searchText
      ? sensorsWithCoords.filter(
        s =>
          s.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          s.serial?.toString()?.indexOf(searchText) !== -1
      )
      : sensorsWithCoords;
  }, [sensorsWithCoords, searchText]);

  const loadSensorData = (bounds: any) => {
    const pumpsWithinBounds = pumpsWithCoords.filter(
      p =>
        p.lat <= bounds.ne.lat &&
        p.lat >= bounds.sw.lat &&
        p.lon <= bounds.ne.lng &&
        p.lon >= bounds.sw.lng
    );

    const pumpsToLoad = pumpsWithinBounds.filter(
      p => !pumpSensorsLoaded.find(l => l === p.id)
    );

    const sensorApiCalls = [] as Promise<ApiResult<any>>[];
    pumpsToLoad.forEach(p => sensorApiCalls.push(sensorsApi.getAllByPump(p.id)));
    Promise.all(sensorApiCalls).then(s => {
      setPumpSensorsLoaded([
        ...pumpSensorsLoaded,
        ...pumpsToLoad.map(p => p.id)
      ]);
      const newSensors = s
        .filter(r => r.result === AjaxResult.Success && r.data)
        .map(r => r.data as Sensor[])
        .flat();

      var orderedSensors = newSensors.sort(function (a, b) {
        return a.name.trim().localeCompare(b.name.trim(), undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      setSensors([...sensors, ...orderedSensors]);
    });
  };
  //LoadFieldData
  const loadFieldData = (bounds: any) => {
    const pumpsWithinBounds = pumpsWithCoords.filter(
      p =>
        p.lat <= bounds.ne.lat &&
        p.lat >= bounds.sw.lat &&
        p.lon <= bounds.ne.lng &&
        p.lon >= bounds.sw.lng
    );

    const pumpsToLoad = pumpsWithinBounds.filter(
      p => !pumpFieldsLoaded.find(l => l === p.id)
    );

    const feildApiCalls = [] as Promise<ApiResult<any>>[];
    pumpsToLoad.forEach(p => feildApiCalls.push(fieldsApi.getByPumpId(p.id)));
    Promise.all(feildApiCalls).then(f => {
      setPumpFieldsLoaded([
        ...pumpFieldsLoaded,
        ...pumpsToLoad.map(p => p.id)
      ]);
      const newFields = f
        .filter(r => r.result === AjaxResult.Success && r.data)
        .map(r => r.data as Field[])
        .flat();

      var orderedFields = newFields.sort(function (a, b) {
        return a.name.trim().localeCompare(b.name.trim(), undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      setFields([...fields, ...orderedFields]);
    });
  };

  const clusters = useClusteredPumps({
    pumpsWithCoords,
    sensorsWithCoords,
    fieldsWithCoords,
    mapZoom,
    bounds,
    cardType
  });

  const tryStopAllPumps = () => {
    showConfirmDialog(`Are you sure you want to stop all pumps?`).then(
      confirmed => {
        if (confirmed) {
          pumpsApi.stopAllPumps().then(result => {
            if (result.result === AjaxResult.Success) {
              showMessage("Command sent to Pumps");
            } else if (result.messages) {
              result.messages.forEach(m => {
                showMessage(m);
              });
            }
          });
        }
      }
    );
  };

  const theme = useTheme();

  return (
    <div>


      <mui.Grid container spacing={1} >
        <mui.Grid item xs={12} md={9}>
          <CardTypeSelect {...{ cardType, setCardType }} />
          <div style={{ height: pageContentHeight, width: "100%" }}>
            <MapTypeSelect {...{ mapViewType, setMapViewType }} className={mapTypeStyle} isHorizontal={useMediaQuery(theme.breakpoints.up('sm'))} />
            <GoogleMapReact
              bootstrapURLKeys={{
                key: Globals.GOOGLE_MAPS_API_KEY
              }}
              center={mapCenter || defaultCenter}
              zoom={mapZoom}
              onGoogleApiLoaded={a => {
                mapRef.current = a.map;
                setMapRefIsLoaded(true);
              }}
              onChange={e => {
                loadSensorData(e.bounds);
                loadFieldData(e.bounds);
                setMapZoom(e.zoom);
                setBounds(e.bounds);
              }}
              onChildClick={(key, childProps) => {
                if (childProps.lat && childProps.lng) {
                  setMapCenter({ lat: parseFloat(childProps.lat), lng: parseFloat(childProps.lng) });
                }
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              options={{ mapTypeId: mapViewType }}
            >
              {clusters.map(c => {
                const [longitude, latitude] = c.geometry.coordinates;
                if (!c.properties.cluster) {
                  return (
                    <MapMarker
                      key={`${c.properties.type}-${c.properties.id}`}
                      lat={latitude}
                      lng={longitude}
                      id={c.properties.id}
                      name={c.properties.name}
                      serialNumber={c.properties.serialNumber}
                      markerImageType={c.properties.type}
                      category={c.properties.category}
                      status={c.properties.status}
                      fuelLevel={c.properties.data4}
                      unitState={
                        c.properties.unitState ? c.properties.unitState : 0
                      }
                      online={
                        c.properties.online ? c.properties.online : false
                      }
                      currentEngData={c.properties.currentEngData}
                    />
                  );
                } else {
                  return (
                    <ClusterMarker
                      key={c.id}
                      lat={latitude}
                      lng={longitude}
                      clusterCount={c.properties.point_count}
                    />
                  );
                }
              })}
            </GoogleMapReact>
          </div>
        </mui.Grid>
        <mui.Grid item xs={12} md={3}>

          <div style={{ height: listContentHeight, overflowY: "auto" }} id="cardGrid">
            {role !== UserRole.Admin && (
              <mui.Button
                className={stopButton}
                color="secondary"
                variant="contained"
                disabled={cardType !== HomeCard.Pumps}
                fullWidth
                onClick={tryStopAllPumps}
              >
                Stop All Pumps
              </mui.Button>
            )}
            <mui.TextField
              className={searchBar}
              placeholder="Search..."
              fullWidth
              variant="outlined"
              value={searchText}
              onChange={e => setSearchText(e.currentTarget.value)}
              InputProps={{
                endAdornment: (
                  <mui.InputAdornment position="end">
                    <SearchRounded />
                  </mui.InputAdornment>
                )
              }}
            />
            {filteredPumps.length === 0 &&
              <div className={buttonContainer}>
                <Link to="/admin/pumps?add=true">
                  <Button variant="outlined" color="primary">
                    <AddCircleOutline />
                    <span>Add Pump</span>
                  </Button>
                </Link>
              </div>
            }
            {cardType === HomeCard.Sensors
              ? filteredSensors.map(s => (
                <SensorCard key={s.id} sensor={s} />
              ))
              : cardType === HomeCard.Fields
                ? filteredFields.map(f => (
                  <FieldCard key={f.id} field={f} />
                ))
                : cardType === HomeCard.PumpWeather
                  ? filteredPumps.map(p => (
                    <PumpWeatherCard key={p.id} pump={p} />
                  ))
                  : filteredPumps.map(p => (
                    <PumpCard key={p.id} pump={p} />
                  ))}
          </div>
        </mui.Grid>
      </mui.Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  searchBar: {
    backgroundColor: theme.whiteColor,
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  stopButton: {
    color: "error",
    padding: "4px",
    position: "sticky",
    top: 0,
    display: "flex",
    justifySelf: "center",
    marginBottom: theme.standardMargin,
    textTransform: "none",
    fontWeight: "bold"
  },
  visiblityButton: {
    color: theme.whiteColor,
    padding: "16px",
    position: "sticky",
    top: 0,
    display: "flex",
    justifySelf: "center",
    marginBottom: theme.standardMargin,
    textTransform: "none",
    fontWeight: "bold"
  },
  mapTypeStyle: {
    position: "absolute",
    zIndex: 100,
    color: "white"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.standardMargin
  }

}));
