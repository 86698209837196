import React, { useState, useEffect } from "react";
import { SurgeLogix } from "../../dtos/surgeLogix";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { ApiResult } from "../../rest/baseApi";
import { AjaxResult } from "../../enums/ajaxResult";
import { ErrorMessage } from "../shared/errorMessage";

interface EditProps {
  isVisible: boolean;
  surgeLogix: SurgeLogix | null;
  updateSurgeLogix: (
    surgeLogix: SurgeLogix,
    id: number
  ) => Promise<ApiResult<any>>;
  close: () => void;
  markStepsChanged: () => void;
}

const getDefaultSurge = () =>
  ({
    count: 0,
    duration: 0,
    adjust: 0
  } as SurgeLogix);

const EditSurgeLogix = (props: EditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [surgeLogix, setSurgeLogix] = useState<SurgeLogix>(getDefaultSurge());

  useEffect(() => {
    if (!!props.surgeLogix) {
      setSurgeLogix(props.surgeLogix);
    }
  }, [props.surgeLogix]);

  useEffect(() => {
    if (!props.isVisible) {
      setMessages([]);
    }
  }, [props.isVisible]);

  const updateSurgeLogix = async () => {
    var result: ApiResult<any>;
    if (props.surgeLogix) {
      result = await props.updateSurgeLogix(surgeLogix, surgeLogix.id);
      if (result.result === AjaxResult.Success) {
        props.close();
        props.markStepsChanged();
      } else {
        setMessages(result.messages ? result.messages : []);
      }
    }
  };

  const editSurgeLogix = (operation: (c: SurgeLogix) => void) => {
    var editedSurgeLogix: SurgeLogix = { ...surgeLogix };
    operation(editedSurgeLogix);
    setSurgeLogix(editedSurgeLogix);
  };

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${props.surgeLogix?.fieldName ? "Update" : "Add"} ${
        props.surgeLogix?.fieldName
      }`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="Field Name"
            variant="outlined"
            disabled={true}
            value={surgeLogix.fieldName}
          />
          <TextField
            label="Command Type"
            variant="outlined"
            disabled={true}
            value={surgeLogix.type}
          />
          <TextField
            label="Cycle Count"
            variant="outlined"
            type="number"
            value={surgeLogix.count}
            onChange={value =>
              editSurgeLogix(f => (f.count = parseInt(value.target.value)))
            }
          />
          <TextField
            label="Set Duration (min)"
            variant="outlined"
            type="number"
            value={surgeLogix.duration}
            onChange={value =>
              editSurgeLogix(f => (f.duration = parseInt(value.target.value)))
            }
          />
          <TextField
            label="Cycle Time Adjust (%)"
            variant="outlined"
            type="number"
            value={surgeLogix.adjust}
            onChange={value =>
              editSurgeLogix(f => (f.adjust = parseInt(value.target.value)))
            }
          />
        </form>
        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={updateSurgeLogix}
        >
          {props.surgeLogix?.fieldName ? "Update" : "Create Field"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default EditSurgeLogix;
