import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import FieldTable from "./fieldsTable";
import PumpInformation from "./pumpInformation";
import { PumpDataLog } from "../../dtos/pumpDataLog";
import { useMessage } from "../messageService";
import { useApi } from "../apiService";
import { AjaxResult } from "../../enums/ajaxResult";

const PumpInformationPage = () => {
  const match = useRouteMatch<{ pumpId: string | undefined }>();

  const pumpId = match.params.pumpId ? parseInt(match.params.pumpId) : 0;

  const classes = useStyles();

  const [pump, setPump] = useState<PumpDataLog | null>(null);
  const [refreshPumpInfoCounter, setRefreshPumpInfoCounter] = useState<number>(0);
  const [refreshFieldCounter, setRefreshFieldCounter] = useState<number>(0);
  const { pumpsApi } = useApi();
  const { showMessage } = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      showMessage("Fetching pump information...");
      pumpsApi.getPumpDataLogById(pumpId).then(r => {
        if (r.result === AjaxResult.Success && r.data) {
          console.log(r.result);
          showMessage("Pump information fetched!");
          setPump(r.data);
        }
      });
    };

    try {
      fetchData();
    } catch (e) {
      showMessage(e.message);
    }
  }, [pumpId, showMessage, pumpsApi]);

  useEffect(() => {
    const fetchPumpData = async () => {
      if (refreshPumpInfoCounter < 20) {
        showMessage("Updating pump information...");
        pumpsApi.getPumpDataLogById(pumpId).then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Pump information updated!");
            setPump(r.data);
          }
          setRefreshPumpInfoCounter(refreshPumpInfoCounter + 1);
        });
      }
      else {
        clearInterval(interval);
      }
    };

    const interval = setInterval(fetchPumpData, 30000);
    return () => clearInterval(interval);
  }, [refreshPumpInfoCounter, pumpId, pumpsApi, showMessage]);

  return (
    <div className={classes.root}>
      <PumpInformation pumpId={pumpId} pump={pump ? pump : null} setRefreshPumpInfoCounter={setRefreshPumpInfoCounter} setRefreshFieldCounter={setRefreshFieldCounter} />
      <FieldTable pumpId={pumpId} pump={pump ? pump : null} refreshFieldCounter={refreshFieldCounter} setRefreshPumpInfoCounter={setRefreshPumpInfoCounter} setRefreshFieldCounter={setRefreshFieldCounter} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center"
  }
});

export default PumpInformationPage;
