import { Pump } from "../../dtos/pump";
import { Field } from "../../dtos/field";
import { Sensor } from "../../dtos/sensor";
import { Bounds } from "google-map-react";
import { useMemo } from "react";
import useSupercluster from "use-supercluster";
import { MarkerCategory } from "../../enums/markerCategory";
import { HomeCard } from "../../enums/homeCard";
import { convertFuelToPercentage } from "../../utility/fuelLevel";

export const useClusteredPumps = ({
  pumpsWithCoords,
  sensorsWithCoords,
  fieldsWithCoords,
  mapZoom,
  bounds,
  cardType
}: {
  pumpsWithCoords: Pump[];
  fieldsWithCoords: Field[];
  sensorsWithCoords: Sensor[];
  mapZoom: number;
  bounds: Bounds | null;
  cardType: HomeCard
}) => {
  const clusterPoints = useMemo(
    () => {
      if (cardType === HomeCard.Pumps) {
        return [
          ...pumpsWithCoords.map(p => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: p.id,
              serialNumber: p.serialNumber,
              name: p.name,
              category: MarkerCategory.Pump,
              type: p.type,
              unitState: p.unitState,
              data4: convertFuelToPercentage(p)
            },
            geometry: {
              type: "Point",
              coordinates: [p.lon, p.lat]
            }
          }))
        ];
      }
      else if (cardType === HomeCard.Fields) {
        return [
          ...fieldsWithCoords.map(f => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: f.id,
              serialNumber: f.valveSerialNumber,
              name: f.name,
              category: MarkerCategory.Field,
              type: 98,
              online: f.online ? true : false,
              currentEngData: f.position

            },
            geometry: {
              type: "Point",
              coordinates: [f.lon, f.lat]
            }
          }))
        ];
      }
      else if (cardType === HomeCard.Sensors) {
        return [
          ...sensorsWithCoords.map(s => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: s.id,
              serialNumber: s.serial,
              name: s.name,
              category: MarkerCategory.Sensor,
              type: 99,
              status: s.alarmStatus,
              online: s.online ? true : false,
              currentEngData: s.currentEngData
            },
            geometry: {
              type: "Point",
              coordinates: [s.lon, s.lat]
            }
          }))
        ];
      }
      else if (cardType === HomeCard.PumpWeather) {
        return [
          ...pumpsWithCoords.filter(p => p.weatherLink).map(p => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: p.id,
              serialNumber: p.serialNumber,
              name: p.name,
              category: MarkerCategory.Pump,
              type: p.type,
              unitState: p.unitState
            },
            geometry: {
              type: "Point",
              coordinates: [p.lon, p.lat]
            }
          }))
        ];
      }
      else {
        return [
          ...pumpsWithCoords.map(p => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: p.id,
              serialNumber: p.serialNumber,
              name: p.name,
              category: MarkerCategory.Pump,
              type: p.type,
              unitState: p.unitState
            },
            geometry: {
              type: "Point",
              coordinates: [p.lon, p.lat]
            }
          })),
          ...fieldsWithCoords.map(f => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: f.id,
              serialNumber: f.valveSerialNumber,
              name: f.name,
              category: MarkerCategory.Field,
              type: 98,
              online: f.online ? true : false,
              currentEngData: f.position

            },
            geometry: {
              type: "Point",
              coordinates: [f.lon, f.lat]
            }
          })),
          ...sensorsWithCoords.map(s => ({
            type: "Feature",
            properties: {
              cluster: false,
              id: s.id,
              serialNumber: s.serial,
              name: s.name,
              category: MarkerCategory.Sensor,
              type: 99,
              status: s.alarmStatus,
              online: s.online ? true : false,
              currentEngData: s.currentEngData
            },
            geometry: {
              type: "Point",
              coordinates: [s.lon, s.lat]
            }
          }))
        ]
      }
    },
    [pumpsWithCoords, fieldsWithCoords, sensorsWithCoords, cardType]
  );
  const { clusters } = useSupercluster({
    points: clusterPoints,
    zoom: mapZoom,
    bounds: bounds
      ? [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]
      : [],
    options: { radius: 75, maxZoom: 20 }
  });
  return clusters;
};
