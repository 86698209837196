import { AjaxResult } from "../enums/ajaxResult";

/**
 * A Promise that can be awaited by any piece of code.
 */
let refreshPromise: Promise<boolean> | null = null;

const resetRefreshPromise = () => {
  refreshPromise = null;
};

export interface ApiResult<TDataType> {
  result: AjaxResult;
  data?: TDataType;
  messages?: string[];
}

export class BaseApi<TEntity> {
  protected _baseUri: string;
  protected _resourceName: string;
  protected _logout?: () => void;
  protected _showMessage: (
    message: React.ReactNode
  ) => string | number | null | undefined;

  protected get _fullBaseUri(): string {
    return `${this._baseUri}`;
  }
  protected get _baseResource(): string {
    return `${this._fullBaseUri}/${this._resourceName}`;
  }
  protected get _basicHeaders() {
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`
    };
    return headers;
  }

  constructor(
    baseUri: string,
    resourceName: string,
    logout?: () => void,
    showMessage?: any
  ) {
    this._baseUri = baseUri;
    this._resourceName = resourceName;
    this._logout = logout;
    this._showMessage = showMessage;
  }

  public getAll = () =>
    this.genericRequest({ method: "GET" }) as Promise<ApiResult<TEntity[]>>;

  public getForCustomer = (customerId: number) =>
    this.genericRequest({
      method: "GET",
      overridePath: `customers/${customerId}/${this._resourceName}`
    });

  public getById = (id: number) =>
    this.genericRequest({ method: "GET", extraPath: `${id}` }) as Promise<
      ApiResult<TEntity>
    >;

  public postOne = (postObject: TEntity) =>
    this.genericRequest({ method: "POST", data: postObject });

  public putOne = (putObject: TEntity, id: number) =>
    this.genericRequest({ method: "PUT", extraPath: `${id}`, data: putObject });

  public delete = (id: number) =>
    this.genericRequest({ method: "DELETE", extraPath: `${id}` });

  protected refreshJwtToken(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      fetch(`${this._baseUri}/auth/refresh`, {
        method: "POST",
        headers: this._basicHeaders,
        credentials: "include"
      }).then(r => {
        if (r.ok) {
          r.json()
            .then(d => {
              if (d) {
                localStorage.token = d.jwt;
                localStorage.tokenExpDate = d.jwtExpiration;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(e => resolve(false));
        } else {
          resolve(false);
        }
      });
    });
  }

  protected genericRequest = (options: {
    method: "GET" | "POST" | "PUT" | "DELETE";
    data?: {};
    extraPath?: string;
    overridePath?: string;
    abortController?: AbortController;
    ignoreRefreshFlow?: boolean;
    downloadFileFlag?: boolean;
  }): Promise<ApiResult<any>> => {
    const logout = this._logout;
    return new Promise<ApiResult<any>>(resolve => {
      const token = localStorage.getItem("token");
      const tokenExpiration = localStorage.getItem("tokenExpDate");
      const tokenExpDate =
        tokenExpiration && new Date(tokenExpiration).toUTCString();
      const now = new Date();
      const nowDateString = now.toUTCString();
      if (!options.ignoreRefreshFlow && (!tokenExpiration || !token)) {
        logout && logout();
        resolve({ result: AjaxResult.Unauthorized });
      } else if (
        !options.ignoreRefreshFlow &&
        tokenExpDate &&
        tokenExpDate < nowDateString
      ) {
        if (!refreshPromise) {
          refreshPromise = this.refreshJwtToken();
        }
        refreshPromise.then(r => {
          if (r) {
            this.executeRequest(options).then(response => {
              resolve(response);
            });
          } else {
            logout && logout();
            resolve({ result: AjaxResult.Unauthorized });
          }
          resetRefreshPromise();
        });
      } else {
        this.executeRequest(options).then(response => {
          resolve(response);
        });
      }
    });
  };

  private executeRequest = (options: {
    method: "GET" | "POST" | "PUT" | "DELETE";
    data?: {};
    extraPath?: string;
    overridePath?: string;
    abortController?: AbortController;
    downloadFileFlag?: boolean;
  }) => {
    let { method, data, extraPath, overridePath, abortController } = options;
    let requestOptions: RequestInit = {
      method: method,
      body: JSON.stringify(data),
      headers: this._basicHeaders,
      signal: abortController && abortController.signal,
      // credentials: include allows us to pass cookies with each request. This is required for our refresh tokens,
      // which are stored as HTTP-only cookies
      credentials: "include"
    };
    const fullResource =
      overridePath || `${this._resourceName}/${extraPath || ""}`;
    const uri = `${this._fullBaseUri}/${fullResource}`;
    return new Promise<ApiResult<any>>(resolve => {
      fetch(uri, requestOptions)
        .then(r => {
          switch (r.status) {
            case 200:
            case 201:
              if (options.downloadFileFlag) {
                r.blob()
                .then(blob => {
                  resolve({result: AjaxResult.Success, data: blob});
                })
              }
              else {
                r.json()
                .then(d => {
                  resolve({ result: AjaxResult.Success, data: d });
                })
                .catch(() => {
                  resolve({ result: AjaxResult.Success });
                });
              }
              break;
            case 400:
              resolve({ result: AjaxResult.BadRequest });
              this._showMessage(
                `The ${method} request to ${uri} failed because parameters are invalid`
              );
              break;
            case 401:
              resolve({ result: AjaxResult.Unauthorized });
              this._logout && this._logout();
              break;
            case 403:
              resolve({ result: AjaxResult.Forbidden });
              this._showMessage(
                `The ${method} request to ${uri} failed because you have insufficient permission to access it`
              );
              break;
            case 500:
            default:
              r.json().then(d => {
                this._showMessage(`The ${method} request to ${uri} failed`);
                if (d && d.errors) {
                  resolve({ result: AjaxResult.Failure, messages: d.errors });
                } else {
                  resolve({ result: AjaxResult.Failure });
                }
              });
              break;
          }
        })
        .catch(e => {
          if (e.name === "AbortError") {
            resolve({ result: AjaxResult.Cancelled });
          } else {
            console.log(e);
            resolve({ result: AjaxResult.Failure });
            this._showMessage(
              `Request to ${uri} failed. It's likely the request couldn't reach the server because the server is down`
            );
          }
        });
    });
  };
}
