import React, { useContext, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "./authContext";
import { AjaxResult } from "../enums/ajaxResult";
import * as Mui from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import { ThemeOptions } from "@material-ui/core";

let logo = require("../images/aquarius-logo-large.svg");

export const PasswordResetPage = () => {
  const { authApi } = useContext(AuthContext);
  const location = useLocation();
  // const { location } = useRouter();
  const { search } = location;
  const params = useMemo(() => queryString.parse(search), [search]);

  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [resetSucceeded, setResetSucceeded] = useState(false);
  const reset = () => {
    authApi
      .resetPassword(
        params.username as string,
        params.code as string,
        newPassword
      )
      .then(r => {
        if (r.result === AjaxResult.Success) {
          setResetSucceeded(true);
          setErrors([]);
        } else {
          if (r.messages) {
            setErrors(r.messages);
          }
        }
      });
  };
  const classes = useStyles();

  return (
    <div>
      <div className={classes.loginWrapper}>
        <img className={classes.img} src={logo} alt="Logo" />
        {resetSucceeded ? (
          <div>
            Your password has been reset. You can now go to the{" "}
            <Link to="/" style={{ textDecoration: "none" }}>
              Home page
            </Link>{" "}
            and log in.
          </div>
        ) : (
          <form
            className={classes.form}
            onSubmit={e => {
              e.preventDefault();
              reset();
            }}
          >
            <div className={classes.title}>Reset Password</div>
            <Mui.FormControl className={classes.inputBox} variant="outlined">
              <Mui.InputLabel htmlFor="outlined-adornment-password">
                Password
              </Mui.InputLabel>
              <Mui.OutlinedInput
                id="outlined-adornment-password"
                type={"password"}
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
                endAdornment={
                  <Mui.InputAdornment position="end">
                    <LockOutlined />
                  </Mui.InputAdornment>
                }
                labelWidth={70}
              />
            </Mui.FormControl>
            <Mui.Button
              classes={{
                root: classes.loginButtonRoot,
                disabled: classes.loginButtonDisabled
              }}
              type="submit"
              variant="contained"
            >
              Update Password
            </Mui.Button>
          </form>
        )}
        <ul>
          {errors.map(e => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const useStyles = Mui.makeStyles((theme: ThemeOptions) => ({
  loginWrapper: {
    backgroundColor: theme.mainBackgroundColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20%",
    minHeight: "100vh"
  },
  form: {
    maxWidth: "80%"
  },
  img: {
    marginBottom: "2em"
  },
  title: {
    color: theme.selectedItemColor,
    width: "100%",
    marginBottom: theme.standardMargin,
    fontWeight: "bold",
    fontSize: "1.25em"
  },

  inputBox: {
    backgroundColor: theme.whiteColor,
    width: "100%",
    marginBottom: theme.standardMargin,
    "& input:-internal-autofill-selected": {
      backgroundColor: `${theme.whiteColor}`
    },
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.whiteColor} inset`
    }
  },
  loginButtonRoot: {
    minWidth: "100%",
    background:
      "transparent linear-gradient(97deg, #60bef6 0%, #2991fb 100%) 0% 0% no-repeat padding-box",
    color: `${theme.whiteColor}`,
    boxShadow: theme.standardBoxShadow,
    borderRadius: theme.shape?.borderRadius,
    opacity: 1,
    textTransform: "none",
    height: "3em",
    fontSize: "1em",
    marginBottom: theme.standardMargin
  },
  loginButtonDisabled: {
    "&.MuiButton-contained.Mui-disabled": {
      color: `${theme.whiteColor}`
    }
  },
  forgotPasswordButton: {
    color: theme.textColor,
    width: "100%",
    cursor: "pointer"
  }
}));
