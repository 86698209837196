export enum PumpSensorType {
  Error = 0,
  State = 1,
  Speed = 2,
  BatteryLevel = 3,
  Flow = 4,
  AmbientTemp = 5,
  WaterPressure = 6,
  WaterTemp = 7,
  OilPressure = 8,
  FuelLevel = 9
}
