import React, { useContext, useState } from "react";
import { AuthContext } from "./authContext";
import * as Mui from "@material-ui/core";
import { PersonOutline, LockOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { ThemeOptions } from "@material-ui/core";

let logo = require("../images/aquarius-logo-large.svg");

export const LoginForm = () => {
  const { login, loginInProgress } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const submit = () => {
    login({ username, password });
  };

  const classes = useStyles();
  return (
    <div className={classes.loginWrapper}>
      <a href="https://www.aquarius.ag/" target="_blank" rel="noopener noreferrer">
        <img className={classes.img} src={logo} alt="Logo" />
      </a>
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <div className={classes.title}>Login</div>
        <Mui.FormControl className={classes.inputBox} variant="outlined">
          <Mui.InputLabel htmlFor="outlined-adornment-username">
            Username
          </Mui.InputLabel>
          <Mui.OutlinedInput
            id="outlined-adornment-username"
            value={username}
            onChange={event => setUsername(event.target.value)}
            endAdornment={
              <Mui.InputAdornment position="end">
                <PersonOutline />
              </Mui.InputAdornment>
            }
            labelWidth={70}
          />
        </Mui.FormControl>
        <Mui.FormControl className={classes.inputBox} variant="outlined">
          <Mui.InputLabel htmlFor="outlined-adornment-password">
            Password
          </Mui.InputLabel>
          <Mui.OutlinedInput
            id="outlined-adornment-password"
            value={password}
            type={"password"}
            onChange={event => setPassword(event.target.value)}
            endAdornment={
              <Mui.InputAdornment position="end">
                <LockOutlined />
              </Mui.InputAdornment>
            }
            labelWidth={70}
          />
        </Mui.FormControl>
        <Mui.Button
          classes={{
            root: classes.loginButtonRoot,
            disabled: classes.loginButtonDisabled
          }}
          type="submit"
          variant="contained"
          disabled={loginInProgress}
        >
          {loginInProgress ? "Logging in..." : "Log In"}
        </Mui.Button>
        <div className={classes.recoveryRow}>
          <Link to="/forgotUsername" style={{ textDecoration: "none" }}>
            Forgot Username?
          </Link>
          <Link to="/forgotPassword" style={{ textDecoration: "none" }}>
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
};

const useStyles = Mui.makeStyles((theme: ThemeOptions) => ({
  loginWrapper: {
    backgroundColor: theme.mainBackgroundColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20%",
    minHeight: "100vh"
  },
  form: {
    maxWidth: "80%"
  },
  img: {
    marginBottom: "2em"
  },
  title: {
    color: theme.selectedItemColor,
    width: "100%",
    marginBottom: theme.standardMargin,
    fontWeight: "bold",
    fontSize: "1.25em"
  },

  inputBox: {
    backgroundColor: theme.whiteColor,
    width: "100%",
    marginBottom: theme.standardMargin,
    "& input:-internal-autofill-selected": {
      backgroundColor: `${theme.whiteColor}`
    },
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.whiteColor} inset`
    }
  },
  loginButtonRoot: {
    minWidth: "100%",
    background:
      "transparent linear-gradient(97deg, #60bef6 0%, #2991fb 100%) 0% 0% no-repeat padding-box",
    color: `${theme.whiteColor}`,
    boxShadow: theme.standardBoxShadow,
    borderRadius: theme.shape?.borderRadius,
    opacity: 1,
    textTransform: "none",
    height: "3em",
    fontSize: "1em",
    marginBottom: theme.standardMargin
  },
  loginButtonDisabled: {
    "&.MuiButton-contained.Mui-disabled": {
      color: `${theme.whiteColor}`
    }
  },
  recoveryRow: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  forgotPasswordButton: {
    color: theme.textColor,
    width: "100%",
    cursor: "pointer"
  }
}));
