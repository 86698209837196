import React, { useState, useEffect, useRef } from "react";
import { makeStyles, ThemeOptions } from "@material-ui/core/styles";
import { HistoricalDataChart } from "../shared/historicalDataChart";
import { PumpSensorSelector } from "./pumpSensorSelector";
import { PumpSensor } from "../../dtos/pumpSensor";
import { PumpSensorData } from "../../dtos/pumpSensorData";
import { ChartSettings } from "../../dtos/chartSettings";
import { useParams } from "react-router";
import { useApi } from "../apiService";
import { AjaxResult } from "../../enums/ajaxResult";
import * as Mui from "@material-ui/core";
import { useMessage } from "../messageService";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { unitStateDisplayColor } from "../../enums/unitState";
import { PumpDataLog } from "../../dtos/pumpDataLog";
import { THEME_VARIABLES } from "../../themeColors";
import { Pump } from "../../dtos/pump";
import { useWindowWidth } from "@react-hook/window-size";
import * as linq from "linq";
import { getPumpSensorUnitsLabel } from "../../utility/getPumpSensorUnits";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { PumpExportData } from "../../dtos/pumpExportData";
import JSZip from "jszip";
import saveAs from "file-saver";

interface PumpHistoryPageData {
  pumpSensors: PumpSensor[];
  chartSettings: ChartSettings[];
  historicalData: PumpSensorData[];
}

interface DownloadBlob {
  data: any;
  fileType: string;
}

interface DownloadFile {
  data: any;
  fileName: string;
  fileType: string;
}

const MAXIMUM_SELECTED = 4;

export const PumpHistoryPage = () => {
  let { pumpId } = useParams();
  const { pumpSensorsApi, pumpsApi } = useApi();
  const { showMessage } = useMessage();

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [pump, setPump] = useState<PumpDataLog | null>(null);
  const [selectedCount, setSelectedCount] = useState(0);

  const width = useWindowWidth();

  const classes = useStyles({
    pump: pump,
    width: width,
    unitStateDisplayColor: unitStateDisplayColor
  });

  const [startDate, setStartDate] = useState<Date>(yesterday);
  const [endDate, setEndDate] = useState<Date>(today);
  const [pumpInfo, setPumpInfo] = useState<Pump>();
  const [pumpHistoryPageData, setPumpHistoryPageData] = useState<
    PumpHistoryPageData
  >({
    pumpSensors: [],
    chartSettings: [],
    historicalData: []
  });
  const [chartData, setChartData] = useState<{
    labels: string[];
    dataSets: {
      label: string;
      unitLabel: string;
      dataPoints: any[];
    }[];
  }>();
  const [csvData, setCsvData] = useState<PumpExportData[]>([]);

  const [open, setOpen] = useState(false);
  const anchorRefExport = useRef<HTMLButtonElement>(null);
  const [disableExport, setDisableExport] = useState(true);

  const updateSelection = (pumpSensorId: number) => {
    var selected = !pumpHistoryPageData.chartSettings.find(
      c => c.pumpSensorId === pumpSensorId
    );

    // if user is attempting to select more than allowed number, cancel selection, pop up notification
    // Update count of selected before data is returned to account for quick clicking
    if (selected) {
      if (selectedCount >= MAXIMUM_SELECTED) {
        showMessage("WARNING: You can only select up to 4 sensors at a time.");
        return;
      }
      setSelectedCount(selectedCount + 1);
    } else {
      setSelectedCount(selectedCount - 1);
    }

    // Set client-side chart settings
    var chartSettings: ChartSettings[] = [...pumpHistoryPageData.chartSettings];
    if (selected) {
      chartSettings.push({
        pumpId: Number(pumpId),
        pumpSensorId: pumpSensorId,
        sensorId: null,
        userId: null,
        valveId: null
      });
    } else {
      chartSettings = linq
        .from(chartSettings)
        .where(c => c.pumpSensorId !== pumpSensorId)
        .toArray();
    }

    setPumpHistoryPageData({
      ...pumpHistoryPageData,
      chartSettings: chartSettings
    });

    // Send chart settings to backend
    pumpSensorsApi.updateChartSetting(Number(pumpId), pumpSensorId, selected);
  };

  const updateDateSelection = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getPumpSensors = () => {
    if (pumpId && startDate !== null && endDate !== null) {
      try {
        showMessage("Fetching Pump Sensors...");
        pumpSensorsApi
          .updatePumpHistoryPageData(Number(pumpId), startDate, endDate)
          .then(r => {
            if (r.result === AjaxResult.Success && r.data) {
              console.log(r.data);
              setPumpHistoryPageData({
                pumpSensors: r.data.pumpSensors,
                historicalData: r.data.pumpHistoricalData,
                chartSettings: r.data.chartSettings
              });
              setCsvData(r.data.pumpExportData);
              showMessage("Sensors Fetched.");
              setDisableExport(false);
            }
          });
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  useEffect(() => {
    getPumpSensors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pumpId, startDate, endDate]);

  // Update count of selected to actual data when received
  useEffect(() => {
    setSelectedCount(pumpHistoryPageData.chartSettings.length);
  }, [pumpHistoryPageData.chartSettings]);

  useEffect(() => {
    const getPump = () => {
      if (pumpId) {
        showMessage("Fetching Pump Info...");
        pumpsApi.getById(Number(pumpId)).then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            setPumpInfo(r.data);
            showMessage("Pump Info Fetched.");
          }
        });
      }
    };

    getPump();
  }, [pumpId, showMessage, pumpsApi]);

  useEffect(() => {
    const getPump = () => {
      if (pumpId) {
        showMessage("Fetching pump information...");
        pumpsApi.getPumpDataLogById(Number(pumpId)).then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Pump information fetched!");
            setPump(r.data);
          }
        });
      }
    };
    getPump();
  }, [pumpId, showMessage, pumpsApi]);

  useEffect(() => {
    const pumpSensorList = pumpHistoryPageData.pumpSensors.filter(s =>
      pumpHistoryPageData.chartSettings.find(c => c.pumpSensorId === s.id)
    );
    // list of unique timestamps
    const dateStrings = Array.from(
      new Set([
        ...pumpHistoryPageData.historicalData
          .filter(
            h => !!h.tstamp && !!pumpSensorList.find(s => s.type === h.name)
          )
          .map(h => new Date(h.tstamp || "").getTime())
      ])
    );
    const dates = dateStrings.map(d => new Date(d));
    dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    // Check that sesor data has entry for each time. If not, use previous
    let sensorData = pumpSensorList.map(s => ({
      label: s.type,
      unitLabel: getPumpSensorUnitsLabel(s.type),
      pumpSensorId: linq
        .from(pumpHistoryPageData.pumpSensors)
        .first(ps => ps.type === s.type).id,
      dataPoints: dates.map(d => {
        const dataPoint = pumpHistoryPageData.historicalData.find(
          h =>
            h.name === s.type &&
            h.tstamp != null &&
            new Date(h.tstamp).getTime() === d.getTime()
        );
        if (!!dataPoint) {
          return dataPoint.engData || 0;
        } else {
          const relevantDataPoints = pumpHistoryPageData.historicalData
            .filter(h => h.name === s.type)
            .sort(
              (a, b) =>
                new Date(a.tstamp || "").getTime() -
                new Date(b.tstamp || "").getTime()
            );
          let pointBefore: any;
          let pointAfter: any;
          relevantDataPoints.forEach(r => {
            if (new Date(r.tstamp || "").getTime() < d.getTime()) {
              pointBefore = r;
            }
            if (
              new Date(r.tstamp || "").getTime() > d.getTime() &&
              !pointAfter
            ) {
              pointAfter = r;
            }
          });

          return (
            (!!pointBefore && pointBefore.engData) ||
            (!!pointAfter && pointAfter.engData) ||
            0
          );
        }
      })
    }));

    var orderedSensorData: {
      label: string;
      unitLabel: string;
      dataPoints: any[];
    }[] = [];

    var orderedPumpSensorIds = linq
      .from(pumpHistoryPageData.chartSettings)
      .orderBy(s => s.id)
      .select(s => s.pumpSensorId)
      .toArray();
    orderedPumpSensorIds.forEach((pumpSensorId, i) => {
      var data = sensorData.find(s => s.pumpSensorId === pumpSensorId);
      if (data) {
        data.label = `${data.label} (${i + 1})`;
        data.unitLabel = `${data.unitLabel} (${i + 1})`;
        orderedSensorData.push(data);
      }
    });

    setChartData({
      labels: [
        ...dates.map(d => `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`)
      ],
      dataSets: orderedSensorData
    });
  }, [pumpHistoryPageData]);

  const handleExportToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleExportClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRefExport.current && anchorRefExport.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleExportListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRefExport.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const downloadFile = ({data, fileName, fileType}: DownloadFile) => {
    const blob = new Blob([data], {type: fileType});

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const getDownloadBlob = ({data, fileType}: DownloadBlob): Blob => {
    return new Blob([data], {type: fileType});
  }

  const getDownloadFileName = (): string => {
    var pumpName = pumpInfo?.name;
    return pumpName ? `${pumpName}_historicalData`.replace(/\.|\/|\\/g,'_') : "historicalData";
  }

  const getImage = (): string | null => {
    var chartCanvasEl = document.getElementsByClassName('chartjs-render-monitor');
    if(chartCanvasEl && chartCanvasEl.length === 1) {
      var canvas = chartCanvasEl[0] as HTMLCanvasElement;
      return canvas.toDataURL("image/png");
    }
    return null;
  }

  const handleImageExport = (event: React.MouseEvent<EventTarget>) => {
    var base64data = getImage();

    if(base64data) {
      var a = document.createElement('a');
      a.href = base64data.toString();
      let imageName = `${getDownloadFileName()}.png`;
      a.download = imageName;
      a.click();
    }    
    setOpen(false);
  }

  const getCSV = (): string[] => {
    var convertedCSV = csvData.reduce<string[]>((readings, reading) => {
      const { start, stop, hours, mins, sessionGallons, accumulatedGallons, acreFt, acreFtAcre, acreInAcre } = reading;
      readings.push([start, stop, hours, mins, sessionGallons, accumulatedGallons, acreFt, acreFtAcre, acreInAcre].join(','));
      return readings;
    }, []);

    return convertedCSV;
  }

  const handleCSVExport = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault();

    var convertedCSV = getCSV();

    var downloadName = `${getDownloadFileName()}.csv`;

    downloadFile({
      data: [...csvHeaders, ...convertedCSV].join('\n'),
      fileName: downloadName,
      fileType: 'text/csv'
    });
    setOpen(false);
  }

  const getPDF = (): jsPDF => {
    let doc = new jsPDF({putOnlyUsedFonts: true, orientation: "landscape"});
    let pumpName = pumpInfo?.name ?? "";
    autoTable(doc, { 
      body: csvData as any[],
      columns: pdfHeaders,
      theme: 'grid',
      styles: {halign: 'center'},
      headStyles: { fillColor: 'powderblue', textColor: 'black' },
      bodyStyles: { fillColor: 'yellowgreen'},
      alternateRowStyles: { fillColor: '#dddd00'},
      didParseCell: function (data) {
        if (data.section !== "head" && data.column.dataKey !== "start" && data.column.dataKey !== "stop") {
          var convertToNum = Number.parseFloat(data.cell.text[0]);
          var numOfDec = data.column.dataKey === "acreFt" 
                      || data.column.dataKey === "acreFtAcre"
                      || data.column.dataKey === "acreInAcre"
                      ? 4
                      : data.column.dataKey === "sessionGallons" || data.column.dataKey === "accumulatedGallons" ? 0 :2;
          if (convertToNum) {
            data.cell.text[0] = convertToNum.toFixed(numOfDec);
          }
        }
      },
      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text(pumpName, data.settings.margin.left ,15);
        var str = `Page ${data.pageNumber} of {total_pages_count_string}`;
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        doc.setFontSize(10);
        doc.text(str, (pageWidth - (data.settings.margin.right / 2)), pageHeight - 10, { align: "center"});
      },
      margin: { top: 20 }
    });
    doc.putTotalPages('{total_pages_count_string}');
    return doc;
  }

  const handlePDFExport = (event: React.MouseEvent<EventTarget>) => {
    var pdf = getPDF();
    var pdfFileName = `${getDownloadFileName()}.pdf`
    pdf.save(pdfFileName);
    setOpen(false);
  };

  const handleZipExport = (event: React.MouseEvent<EventTarget>) => {
    var zip = new JSZip();
    
    // Get image data
    var image = getImage()?.replace("data:image/png;base64","");

    // Get CSV Data
    var csvData = getCSV();
    var csvBlob = getDownloadBlob({
      data: [...csvHeaders, ...csvData].join('\n'),
      fileType: "text/csv"
    });

    // Get PDF Data
    var pdfDoc = getPDF();

    if (image) {
      // Add image to zip
      zip.file(`${getDownloadFileName()}.png`, image, {base64: true});
    }

    // Add CSV to zip
    zip.file(`${getDownloadFileName()}.csv`, csvBlob);

    // Add PDF to zip
    zip.file(`${getDownloadFileName()}.pdf`, pdfDoc.output('blob'));

    // Generate the zip
    zip.generateAsync({type: "blob"}).then(function(content: string | Blob) {
      saveAs(content, `${getDownloadFileName()}.zip`);
    });
  }


  const csvHeaders = ['Start,Stop,Hours,Mins,Session Gallons,Accumulated Gallons,Acre-Ft,Acre-Ft/Acre,Acre-In/Acre'];

  const pdfHeaders = [
    { header: "Start", key: "start"},
    { header: "Stop", key: "stop"},
    { header: "Hours", key: "hours"},
    { header: "Mins", key: "mins"},
    { header: "Session Gallons", key: "sessionGallons"},
    { header: "Accumulated Gallons", key: "accumulatedGallons"},
    { header: "Acre-Ft", key: "acreFt"},
    { header: "Acre-Ft/Acre", key: "acreFtAcre"},
    { header: "Acre-In/Acre", key: "acreInAcre"}
  ];
  
  return (
    <div className={classes.fieldPageContainer}>
      <Mui.Grid container spacing={1}>
        <Mui.Grid item xs={12} md={9}>
          <div className={classes.historicalChartContainer}>
            <div className={classes.headerContainer}>
              <h2>{`${pumpInfo?.name}`}</h2>
              <div className={classes.headerButtons}>
                <Mui.Button
                  ref={anchorRefExport}
                  aria-controls={open ? 'export-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleExportToggle}
                  variant="contained"
                  className={classes.exportButton}
                  endIcon={<SystemUpdateAltIcon />}
                  disabled={disableExport}
                >
                  Export
                </Mui.Button>
                <Mui.Popper
                  open={open}
                  anchorEl={anchorRefExport.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement}) => (
                    <Mui.Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                      <Mui.Paper>
                        <Mui.ClickAwayListener onClickAway={handleExportClose}>
                          <Mui.MenuList autoFocusItem={open} id="export-list-grow" onKeyDown={handleExportListKeyDown}>
                            <Mui.MenuItem onClick={handleImageExport}>Chart Image</Mui.MenuItem>
                            <Mui.MenuItem onClick={handleCSVExport} disabled={csvData.length > 0 ? false : true}>CSV</Mui.MenuItem>
                            <Mui.MenuItem onClick={handlePDFExport} disabled={csvData.length > 0 ? false : true}>PDF</Mui.MenuItem>
                            <Mui.MenuItem onClick={handleZipExport} disabled={csvData.length > 0 ? false : true}>ZIP</Mui.MenuItem>
                          </Mui.MenuList>
                        </Mui.ClickAwayListener>
                      </Mui.Paper>
                    </Mui.Grow>
                  )}
                </Mui.Popper>
              </div>
            </div>
            {chartData && (
              <HistoricalDataChart
                chartData={chartData}
                updateDateSelection={updateDateSelection}
              />
            )}
          </div>
        </Mui.Grid>
        <Mui.Grid item xs={12} md={3}>
          <div className={classes.sensorSelectorContainer}>
            <PumpSensorSelector
              pumpSensors={pumpHistoryPageData.pumpSensors}
              chartSettings={pumpHistoryPageData.chartSettings}
              updateSelection={updateSelection}
              refreshPumpSensorData={getPumpSensors}
            />
          </div>
        </Mui.Grid>
      </Mui.Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  fieldPageContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerButtons: {
    display: "flex",
    maxWidth: "900px",
    justifySelf: "center",
    zIndex: 10,
    marginBottom: theme.standardMargin,
    "& div": {
      marginLeft: ".5em"
    },
    "& button": {
      marginLeft: ".5em"
    }
  },
  historicalChartContainer: {
    minWidth: (props: any) =>
      theme.minWidthFullScreen && props.width < theme.minWidthFullScreen
        ? "100%"
        : "70%",
    marginRight: "25px"
  },
  sensorSelectorContainer: {
    flex: "1"
  },
  exportLink: { textDecoration: "none", width: "100%", color: 'inherit' },
  exportButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  },
  pumpDisplayButton: {
    backgroundColor: theme.selectedItemColor,
    color: theme.whiteColor,
    textTransform: "none"
  },
  pumpStatus: {
    color: (props: any) =>
      `${
        props.pump && props.pump.unitData
          ? props.unitStateDisplayColor(props.pump.unitData?.state)
          : THEME_VARIABLES.COLOR_RED
      }`
  }
}));
