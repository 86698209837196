import React, { useState, useEffect, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Customer } from "../../../dtos/customer";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeOptions
} from "@material-ui/core";
import { ApiResult } from "../../../rest/baseApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { ErrorMessage } from "../../shared/errorMessage";

interface AddEditProps {
  isVisible: boolean;
  customer: Customer | null;
  addCustomer: (customer: Customer) => Promise<ApiResult<any>>;
  updateCustomer: (customer: Customer, id: number) => Promise<ApiResult<any>>;
  close: () => void;
}

const getDefaultCustomer = () =>
  ({
    custno: 0,
    name: "",
    addr: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    contact: "",
    email: "",
    lat: 0,
    lon: 0,
    apiKey: ""
  } as Customer);

const AddEditCustomers = (props: AddEditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [customer, setCustomer] = useState<Customer>(getDefaultCustomer());

  useEffect(() => {
    setCustomer(props.customer ? props.customer : getDefaultCustomer());
  }, [props.customer]);

  useEffect(() => {
    if (!props.isVisible) {
      setCustomer(getDefaultCustomer());
      setMessages([]);
    }
  }, [props.isVisible]);

  const addOrUpdateCustomer = async () => {
    var result: ApiResult<any>;
    if (props.customer) {
      result = await props.updateCustomer(customer, customer.id);
    } else {
      result = await props.addCustomer(customer);
    }

    if (result.result === AjaxResult.Success) {
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editCustomer = (operation: (c: Customer) => void) => {
    var editedCustomer: Customer = { ...customer };
    operation(editedCustomer);
    setCustomer(editedCustomer);
  };

  const inputsValidated = useMemo(
    () =>
      parseInt(customer.phone) > 999999999 &&
      parseInt(customer.phone) < 10000000000 &&
      customer.email.length > 0,
    [customer]
  );

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${
        props.customer ? "Update" : "Add"
      } Customer`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="Customer Number"
            variant="outlined"
            value={customer.custno}
            type="number"
            onChange={value =>
              editCustomer(c => (c.custno = parseInt(value.target.value)))
            }
          />
          <TextField
            label="Name"
            variant="outlined"
            value={customer.name}
            onChange={value => editCustomer(c => (c.name = value.target.value))}
          />
          <TextField
            label="Address Line 1"
            variant="outlined"
            value={customer.addr}
            onChange={value => editCustomer(c => (c.addr = value.target.value))}
          />
          <TextField
            label="Address Line 2"
            variant="outlined"
            value={customer.addr2}
            onChange={value =>
              editCustomer(c => (c.addr2 = value.target.value))
            }
          />
          <TextField
            label="City"
            variant="outlined"
            value={customer.city}
            onChange={value => editCustomer(c => (c.city = value.target.value))}
          />
          <TextField
            label="State"
            variant="outlined"
            value={customer.state}
            onChange={value =>
              editCustomer(c => (c.state = value.target.value))
            }
          />
          <TextField
            label="Zip"
            variant="outlined"
            value={customer.zip}
            onChange={value => editCustomer(c => (c.zip = value.target.value))}
          />
          <TextField
            label="Phone"
            variant="outlined"
            value={customer.phone}
            helperText={
              parseInt(customer.phone) > 999999999 &&
              parseInt(customer.phone) < 10000000000
                ? null
                : "Invalid. Use Format: XXXXXXXXXX"
            }
            onChange={value =>
              editCustomer(c => (c.phone = value.target.value))
            }
          />
          <TextField
            label="Contact"
            variant="outlined"
            value={customer.contact}
            onChange={value =>
              editCustomer(c => (c.contact = value.target.value))
            }
          />
          <TextField
            label="Email"
            variant="outlined"
            value={customer.email}
            onChange={value =>
              editCustomer(c => (c.email = value.target.value))
            }
          />
          <TextField
            label="Latitude"
            variant="outlined"
            value={customer.lat}
            type="number"
            onChange={value =>
              editCustomer(c => (c.lat = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Longitude"
            variant="outlined"
            value={customer.lon}
            type="number"
            onChange={value =>
              editCustomer(c => (c.lon = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="ApiKey"
            variant="outlined"
            value={customer.apiKey}
            onChange={value =>
              editCustomer(c => (c.apiKey = value.target.value))
            }
          />
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!inputsValidated}
          variant="contained"
          color="secondary"
          onClick={addOrUpdateCustomer}
        >
          {props.customer ? "Update Customer" : "Create Customer"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  root: {
    "& > *": {
      margin: (theme.spacing as (factor: number) => string | number)(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: theme.standardMargin
    }
  }
}));

export default AddEditCustomers;
