import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-zoom";
import { ChartData } from "./chartData";
import { makeStyles } from "@material-ui/core/styles";
import { DateSelector } from "./dateSelector";
import * as Mui from "@material-ui/core";

interface AxisOptions{
    ticks: {
        suggestedMax: number;
        suggestedMin: number;
        maxTicksLimit: number;
    };
    id: string;
    type: string;
    position: string;
    gridLines: {
        display: boolean;
    };
    scaleLabel: {
        display: boolean;
        labelString: string;
        beginAtZero: boolean;
    };
}

export const HistoricalDataChart = ({
  chartData,
  updateDateSelection
}: {
  chartData: ChartData;
  updateDateSelection: (startDate: Date, endDate: Date) => void;
}) => {
  const classes = useStyles();
  const colors: any[] = [
    [230, 128, 108],
    [245, 186, 103],
    [83, 116, 78],
    [106, 153, 149],
    [235, 170, 167]
  ];

  const zoomReset = () => {
    (lineCanvas.current as any).chartInstance.resetZoom();
  };

  const getChartColors = (ctx: any, colorsNeeded: number) => {
    const baseColors = colors.map(c => {
      const gradient = ctx.createLinearGradient(0, 0, 0, 650);
      gradient.addColorStop(0, `rgba(${c[0]}, ${c[1]}, ${c[2]}, 0.2)`);
      gradient.addColorStop(1, `rgba(${c[0]}, ${c[1]}, ${c[2]}, 0)`);
      return { gradient, baseColor: `rgba(${c[0]}, ${c[1]}, ${c[2]}, 1)` };
    });
    const additionalColors = [];
    while (baseColors.length + additionalColors.length < colorsNeeded) {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const gradient = ctx.createLinearGradient(0, 0, 0, 650);
      gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.2)`);
      gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`);
      additionalColors.push({
        gradient,
        baseColor: `rgba(${r}, ${g}, ${b}, 1)`
      });
    }
    return baseColors.concat(additionalColors);
  };
// Need to only make an axis if the unitLabel is unique
  var unitLabels : string[]=[];
  var j;
  var yAxes:AxisOptions[]=[];
  chartData.dataSets.forEach((dataset, i) => {
    const maxValue = Math.max(...dataset.dataPoints);
    const minValue = Math.min(...dataset.dataPoints);
    const suggestedMax =  maxValue > 50 ? maxValue + 10 : Math.ceil(maxValue * 1.1);
    const suggestedMin =  minValue - 10 >= 0 ? minValue - 10 : 0;
    //Logic if unique
    if (!unitLabels.includes(dataset.unitLabel)) {
      j = unitLabels.push(dataset.unitLabel);
      yAxes.push({
        ticks: {
        suggestedMax: suggestedMax,
        suggestedMin: suggestedMin,
        maxTicksLimit: 10
        },
        id: dataset.unitLabel,
        type: "linear",
        position: j <= 2 ? "left" : "right",
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: dataset.unitLabel,
          beginAtZero: true
        }
      });
    }
    // Logic if instance already exists
    else {
      j = unitLabels.indexOf(dataset.unitLabel);
      yAxes[j].ticks.suggestedMax =  Math.max(suggestedMax, yAxes[j].ticks.suggestedMax);
      yAxes[j].ticks.suggestedMin =  Math.min(suggestedMin, yAxes[j].ticks.suggestedMin);
    }
  });

  const options = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            displayFormats: {
              hour: "MMM D hA"
            }
          },
          distribution: "linear",
          gridLines: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5
          }
        }
      ],
      yAxes: yAxes
    },
    zoom: {
      enabled: true,
      mode: "x"
    },
    pan: {
      enabled: true,
      mode: "x"
    },
    animation: {
      duration: 0
    }
  };

  const plugins = [{
    id: 'custom_canvas_background_color',
    beforeDraw: (chart: any) => {
      const ctx = chart.canvas.getContext('2d');
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'white';
      ctx.fillRect(0,0, chart.width, chart.height);
      ctx.restore();
    }
  }];

  const lineCanvas = useRef(null);

  const data = (canvas: any) => {
    const ctx = canvas.getContext("2d");
    const colors = getChartColors(ctx, chartData.dataSets.length);
    return {
      labels: chartData.labels,
      datasets: chartData.dataSets.map((d, di) => ({
        label: d.label,
        data: d.dataPoints,
        yAxisID: d.unitLabel,
        lineTension: 0,
        backgroundColor: colors[di].gradient,
        borderColor: colors[di].baseColor,
        pointRadius: 4, //d.dataPoints.map((p, i) => (!(i % 3) ? 4 : 0)),
        pointHoverRadius: 6,
        pointBackgroundColor: colors[di].baseColor,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 2,
        pointBorderColor: colors[di].baseColor
      }))
    };
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2 className={classes.title}>Historical Data</h2>
        <div className={classes.buttonSection}>
          <DateSelector updateDateSelection={updateDateSelection} />
          <Mui.Button className={`${classes.resetButton}`} onClick={zoomReset}>
            Reset Zoom
          </Mui.Button>
        </div>
      </div>
      <Line redraw data={data} options={options} plugins={plugins} ref={lineCanvas} />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: "#fff",
    padding: "20px",
    border: "2px solid #e1e1e1",
    borderRadius: "8px",
    position: "relative"
  },
  title: {
    margin: "0"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  timeSelector: {
    width: "100%",
    height: "100%",
    display: "block",
    position: "absolute",
    top: "56px"
  },
  resetButton: {
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#2991FB",
    "&:hover": {
      color: "#000"
    },
    marginLeft: "10px"
  },
  buttonSection: {
    display: "flex"
  }
});
