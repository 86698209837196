import React from "react";
import { makeStyles, ThemeOptions } from "@material-ui/core";

export const ErrorMessage = ({ messages }: { messages: string[] }) => {
  const classes = useStyles();

  return (
    <>
      {messages.map(m => (
        <div className={classes.errorMessage} key={m}>
          {m}
        </div>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  errorMessage: {
    color: theme.errorColor
  }
}));
