// src/utils/fuelConversion.js
import { Pump } from "../dtos/pump";


const fuelPumps = [
	'Natural Gas',
	'Diesel',
	'aQ2 Lite - Diesel'
];

/**
 * Converts fuel levels from eighths to percentages.
 * @param {number} pump - Fuel level in eighths (0 to 8).
 * @returns {number} - Fuel level in percentage (0 to 100).
 */
export function convertFuelToPercentage(pump: Pump): number | null {
	if (!fuelPumps.includes(pump.typeName)) {
		return null;
	}
	const eighths = pump.data4;
	if (!(eighths >= 0 && eighths <= 8)) {
		return null
	}
	return Math.round((eighths / 8) * 100);
}

export function isFuelPump(pump: Pump): boolean {
	const fuel = pump.data4;
	if (fuel == 255) {
		return false;
	}
	return fuelPumps.includes(pump.typeName);
}
