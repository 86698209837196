import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Mui from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Pump } from "../../dtos/pump";
import { useApi } from "../apiService";
import { useMessage } from "../messageService";
import { AjaxResult } from "../../enums/ajaxResult";

interface PumpCommandsProps {
  isVisible: boolean;
  pump: Pump | null;
  fieldNumber: number;
  close: () => void;
}

const PumpCommandsModal = (props: PumpCommandsProps) => {
  const classes = useStyles();
  const { pumpsApi } = useApi();
  const { showMessage } = useMessage();

  const [targetRpm, setTargetRpm] = useState(0);
  const [runtimeMinutes, setRuntimeMinutes] = useState(0);
  const [gatePercentage, setGatePercentage] = useState(0);
  const [fieldNumber, setFieldNumber] = useState(props.fieldNumber);

  useEffect(() => {
    setFieldNumber(props.fieldNumber);
  }, [props.fieldNumber]);

  const stopPump = async () => {
    if (props.pump) {
      pumpsApi
        .stopPump({
          customerId: props.pump.customerId,
          pumpSerialNumber: props.pump.serialNumber
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Stop command received.");
          }
        });
    }
  };

  const startPump = async () => {
    if (props.pump) {
      pumpsApi
        .startPump({
          customerId: props.pump.customerId,
          pumpSerialNumber: props.pump.serialNumber,
          runtimeMinutes: runtimeMinutes
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Start command received.");
          }
        });
    }
  };

  const smartStart = async () => {
    if (props.pump) {
      pumpsApi
        .smartStart({
          customerId: props.pump.customerId,
          pumpSerialNumber: props.pump.serialNumber,
          runtimeMinutes: runtimeMinutes,
          targetRpm: targetRpm,
          valveNumber: fieldNumber,
          valveGatePercentage: gatePercentage
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Smart Start command received.");
          }
        });
    }
  };

  const shutValve = async () => {
    if (props.pump) {
      pumpsApi
        .shutValve({
          customerId: props.pump.customerId,
          pumpSerialNumber: props.pump.serialNumber,
          valveNumber: props.fieldNumber
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Shut valve command received.");
          }
        });
    }
  };

  const openValve = async () => {
    if (props.pump) {
      pumpsApi
        .openValve({
          customerId: props.pump.customerId,
          pumpSerialNumber: props.pump.serialNumber,
          valveNumber: props.fieldNumber
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Open valve command received.");
          }
        });
    }
  };

  const setPumpRpm = async () => {
    if (props.pump) {
      pumpsApi
        .setPumpRpm({
          customerId: props.pump.customerId,
          pumpSerialNumber: props.pump.serialNumber,
          targetRpm: targetRpm
        })
        .then(r => {
          console.log(r);
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Target RPM received.");
          }
        });
    }
  };

  return (
    <Mui.Dialog open={props.isVisible} onClose={props.close}>
      <StyledDialogTitle disableTypography>
        <h2>Pump Commands</h2>
      </StyledDialogTitle>
      {props.pump && (
        <Mui.DialogContent>
          <div className={classes.label}>Field Commands</div>

          <Mui.Grid className={classes.commandSection} container>
            <Mui.Grid item xs={6}>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => openValve()}
                className={classes.button}
              >
                Open Valve
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid className={classes.commandSection} container>
            <Mui.Grid item xs={6}>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => shutValve()}
                className={classes.button}
              >
                Shut Valve
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
          <StyledDivider />
          <div className={classes.label}>Pump Commands</div>

          <Mui.Grid className={classes.commandSection} container>
            <Mui.Grid item xs={6}>
              <Mui.TextField
                label="Target RPM"
                variant="outlined"
                value={targetRpm}
                className={classes.commandInput}
                onChange={value => {
                  if (!isNaN(parseInt(value.target.value))) {
                    setTargetRpm(parseInt(value.target.value));
                  }
                }}
              />
            </Mui.Grid>
            <Mui.Grid item xs={6}>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => setPumpRpm()}
                className={classes.button}
              >
                Set Target RPM
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid className={classes.commandSection} container>
            <Mui.Grid item xs={6}>
              <Mui.TextField
                label="Runtime (minutes)"
                variant="outlined"
                value={runtimeMinutes}
                className={classes.commandInput}
                onChange={value => {
                  if (!isNaN(parseInt(value.target.value))) {
                    setRuntimeMinutes(parseInt(value.target.value));
                  }
                }}
              />
            </Mui.Grid>
            <Mui.Grid item xs={6}>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => startPump()}
                className={classes.button}
              >
                Set Pump Runtime
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid className={classes.commandSection} container>
            <Mui.Grid item xs={6}>
              <Mui.TextField
                label="Valve Gate %"
                variant="outlined"
                className={classes.commandInput}
                value={gatePercentage}
                onChange={value => {
                  if (!isNaN(parseInt(value.target.value))) {
                    setGatePercentage(parseInt(value.target.value));
                  }
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid className={classes.commandSection} container>
            <Mui.Grid className={classes.label} item xs={6}>
              <Mui.FormControl
                className={classes.commandInput}
                variant="outlined"
              >
                <Mui.InputLabel id="field-number">Field Number</Mui.InputLabel>
                <Mui.Select
                  labelId="field-number"
                  label="Field Number"
                  value={fieldNumber}
                  onChange={(e: any) =>
                    setFieldNumber(parseInt(e.target.value))
                  }
                >
                  <Mui.MenuItem value="1">1</Mui.MenuItem>
                  <Mui.MenuItem value="2">2</Mui.MenuItem>
                  <Mui.MenuItem value="3">3</Mui.MenuItem>
                  <Mui.MenuItem value="4">4</Mui.MenuItem>
                  <Mui.MenuItem value="5">5</Mui.MenuItem>
                  <Mui.MenuItem value="6">6</Mui.MenuItem>
                </Mui.Select>
              </Mui.FormControl>
            </Mui.Grid>
            <Mui.Grid className={classes.label} item xs={6}>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => smartStart()}
                className={classes.button}
              >
                Smart Start
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Button
            variant="contained"
            color="primary"
            onClick={() => stopPump()}
            className={classes.button}
          >
            Stop Pump
          </Mui.Button>
        </Mui.DialogContent>
      )}

      <Mui.DialogActions>
        <Mui.Button variant="contained" onClick={props.close}>
          Ok
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

const StyledDialogTitle = withStyles((theme: Mui.ThemeOptions) => ({
  root: {
    color: theme.selectedItemColor,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",

    "& .update-date": {
      color: theme.textColor
    }
  }
}))(Mui.DialogTitle);

const StyledDivider = withStyles((theme: Mui.ThemeOptions) => ({
  root: {
    marginTop: "2em",
    marginBottom: "2em"
  }
}))(Mui.Divider);

const useStyles = makeStyles((theme: Mui.ThemeOptions) => ({
  root: {
    "& > *": {
      width: "35ch"
    }
  },
  label: {
    fontWeight: "bold"
  },
  button: {
    backgroundColor: theme.selectedItemColor,
    color: theme.whiteColor,
    textTransform: "none"
  },
  commandSection: {
    marginBottom: theme.smallMargin,
    marginTop: theme.smallMargin,
    display: "flex",
    alignItems: "flex-end",

    "& button": {
      marginLeft: theme.smallMargin
    }
  },
  commandInput: {
    width: "100%"
  }
}));

export default PumpCommandsModal;
