import React, { useContext, useMemo } from "react";
import NavigationBar from "./navigationBar";
import { createGlobalStyle } from "styled-components";
import { LoginForm } from "../auth/loginForm";
import { MessageService } from "./messageService";
import { AuthService } from "../auth/authService";
import { AuthContext } from "../auth/authContext";
import { StylesProvider } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApiService } from "./apiService";
import { SnackbarProvider } from "notistack";
import { ConfirmDialogService } from "./confirmDialogService";
import { THEME } from "../theme";
import { createMuiTheme } from "@material-ui/core";
import { SignUpForm } from "../auth/signUpForm";

const theme = createMuiTheme(THEME);

function App({ children }: { children?: any }) {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <MessageService>
          <ConfirmDialogService>
            <AuthService>
              <ApiService>
                <AppContent>{children}</AppContent>
              </ApiService>
            </AuthService>
          </ConfirmDialogService>
        </MessageService>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const AppContent = ({ children }: { children?: any }) => {
  const { username } = useContext(AuthContext);
  const { pathname } = useLocation();
  const classes = useStyles();
  const isResetOrForgotPasswordPage = useMemo(
    () =>
      pathname.toLowerCase().indexOf("resetpassword") !== -1 ||
      pathname.toLowerCase().indexOf("forgotpassword") !== -1,
    [pathname]
  );

  const signUpPage = useMemo(
    () =>
      pathname.toLowerCase().indexOf("signup") !== -1,
    [pathname]
  );

  const isUsernameForgotPage = useMemo(
    () =>
      pathname.toLowerCase().indexOf("forgotusername") !== -1,
    [pathname]
  );

  return (
    <StylesProvider injectFirst>
      {isResetOrForgotPasswordPage || isUsernameForgotPage ? (
        <>
          <GlobalStyle />
          {children}
        </>
      ) : null}
      {username ? (
        <>
          <NavigationBar />
          <div className={classes.appBody}>
            <GlobalStyle />
            {children}
          </div>
        </>
      ) : null}
      {signUpPage && !username ? (
        <>
          <GlobalStyle />
          <SignUpForm />
        </>
      ) : null}
      {!isResetOrForgotPasswordPage && !isUsernameForgotPage && !username && !signUpPage ? (
        <>
          <GlobalStyle />
          <LoginForm />
        </>
      ) : null}
    </StylesProvider>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    min-height: 100%;
    background-color: ${THEME.mainBackgroundColor};
  }
  html {
    height: 100%;
    #root {
      height: 100%;
    }
  }
`;

const useStyles = makeStyles({
  appBody: {
    padding: "1em 1em 1em 1em"
  }
});

export default App;
