import { BaseApi } from "./baseApi";
import { Sensor } from "../dtos/sensor";

export class SensorsApi extends BaseApi<Sensor> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "sensors", logout, enqueueSnackbar);
  }
  public getAllByPump = (pumpId: number) =>
    this.genericRequest({
      extraPath: `${pumpId}/pumpSensors`,
      method: "GET"
    });
  public getAllByField = (fieldId: number) =>
    this.genericRequest({
      extraPath: `${fieldId}/fieldSensors`,
      method: "GET"
    });
}
