import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PumpSensor } from "../../dtos/pumpSensor";
import { PumpSensorType } from "../../enums/pumpSensorType";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { ApiResult } from "../../rest/baseApi";
import { AjaxResult } from "../../enums/ajaxResult";
import { ErrorMessage } from "../shared/errorMessage";

interface EditProps {
  isVisible: boolean;
  pumpSensor: PumpSensor | null;
  pumpId: number;
  updatePumpSensor: (
    pumpSensor: PumpSensor,
    id: number
  ) => Promise<ApiResult<any>>;
  close: () => void;
}

const getDefaultPumpSensor = (pumpId: number) =>
  ({
    type: PumpSensorType[PumpSensorType.State],
    pumpId: pumpId,
    alarmPriority: 0
  } as PumpSensor);

const EditSensor = (props: EditProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<string[]>([]);
  const [pumpSensor, setPumpSensor] = useState<PumpSensor>(
    getDefaultPumpSensor(props.pumpId)
  );

  useEffect(() => {
    if (props.pumpSensor != null) {
      setPumpSensor(props.pumpSensor);
    }
  }, [props.pumpSensor]);

  useEffect(() => {
    if (!props.isVisible) {
      setPumpSensor(getDefaultPumpSensor(props.pumpId));
      setMessages([]);
    }
  }, [props.isVisible, props.pumpId]);

  const updatePumpSensor = async () => {
    var result: ApiResult<any>;
    result = await props.updatePumpSensor(pumpSensor, pumpSensor.id);
    if (result.result === AjaxResult.Success) {
      props.close();
    } else {
      setMessages(result.messages ? result.messages : []);
    }
  };

  const editPumpSensor = (operation: (c: PumpSensor) => void) => {
    var editedPumpSensor: PumpSensor = { ...pumpSensor };
    operation(editedPumpSensor);
    setPumpSensor(editedPumpSensor);
  };

  return (
    <Dialog open={props.isVisible} onClose={props.close}>
      <DialogTitle>{`${
        props.pumpSensor ? "Update" : "Add"
      } Sensor`}</DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            label="Sensor Name"
            variant="outlined"
            disabled={true}
            value={pumpSensor.type}
            onChange={value =>
              editPumpSensor(f => (f.type = value.target.value))
            }
          />
          <FormControl variant="outlined">
            <InputLabel id="role-select">Alarm Priority</InputLabel>
            <Select
              labelId="role-select"
              label="Alarm Priority"
              value={pumpSensor.alarmPriority}
              onChange={(e: any) =>
                editPumpSensor(
                  c => (c.alarmPriority = parseInt(e.target.value))
                )
              }
            >
              <MenuItem value="0">Disabled</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Alarm Limit High"
            variant="outlined"
            type="number"
            value={pumpSensor.alarmHigh}
            onChange={value =>
              editPumpSensor(
                f => (f.alarmHigh = parseFloat(value.target.value))
              )
            }
          />
          <TextField
            label="Alarm Reset High"
            variant="outlined"
            type="number"
            value={pumpSensor.alarmHighReset}
            onChange={value =>
              editPumpSensor(
                f => (f.alarmHighReset = parseFloat(value.target.value))
              )
            }
          />
          <TextField
            label="Alarm Limit Low"
            variant="outlined"
            type="number"
            value={pumpSensor.alarmLow}
            onChange={value =>
              editPumpSensor(f => (f.alarmLow = parseFloat(value.target.value)))
            }
          />
          <TextField
            label="Alarm Reset Low"
            variant="outlined"
            type="number"
            value={pumpSensor.alarmLowReset}
            onChange={value =>
              editPumpSensor(
                f => (f.alarmLowReset = parseFloat(value.target.value))
              )
            }
          />
        </form>

        <ErrorMessage messages={messages} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={updatePumpSensor}
        >
          {"Update Sensor"}
        </Button>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    "& button": {
      marginLeft: "1em"
    }
  }
}));

export default EditSensor;
