import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../auth/authContext";
import { UserRole } from "../../../enums/userRoles";

import { User } from "../../../dtos/user";
import { useMessage } from "../../messageService";
import MaterialTable, { HeaderCells } from "../../shared/materialTable";
import AddEditUsers from "./addEditUserModal";
import { useApi } from "../../apiService";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../confirmDialogService";

const UsersPage = () => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      {role === UserRole.User ? (
        <div>You do not have permissions to view this page.</div>
      ) : (
        <UsersTable />
      )}
    </div>
  );
};

const UsersTable = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const { showConfirmDialog } = useConfirmDialog();
  const { usersApi } = useApi();
  const { showMessage } = useMessage();

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const addUpdateUser = (user: User | null) => {
    setSelectedUser(user);
    setShowAddNew(true);
  };

  const finishOperation = () => {
    setShowAddNew(false);
    setSelectedUser(null);
    fetchData();
  };

  const tryDeleteUser = (user: User) => {
    showConfirmDialog(
      `Are you sure you want to delete user${
        user.username ? " " + user.username : ""
      }?`
    ).then(confirmed => {
      if (confirmed) {
        usersApi.delete(user.id).then(result => {
          if (result.result === AjaxResult.Success) {
            showMessage("User deleted");
            finishOperation();
          } else if (result.messages) {
            result.messages.forEach(m => {
              showMessage(m);
            });
          }
        });
      }
    });
  };

  const fetchData = async () => {
    showMessage("Fetching users...");
    usersApi.getAll().then(r => {
      if (r.result === AjaxResult.Success && r.data) {
        showMessage("Users fetched!");
        setUsers(r.data);
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells: HeaderCells<User>[] = [
    {
      id: "customer",
      numeric: false,
      disablePadding: true,
      label: "Customer",
      accessor: h => h.customerName
    },
    {
      id: "username",
      numeric: false,
      disablePadding: true,
      label: "Username",
      accessor: h => h.username
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone",
      accessor: h => h.phoneNumber
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      accessor: h => h.email
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: "Role",
      accessor: h => h.role
    }
  ];

  return (
    <>
      <MaterialTable
        title="Users"
        headerCells={headCells}
        rows={users}
        showAddButton={true}
        defaultRowsPerPage={5}
        idPropertyAccessor={r => r.id}
        handleAddOrUpdate={addUpdateUser}
        handleDelete={tryDeleteUser}
      />
      <AddEditUsers
        isVisible={showAddNew}
        close={finishOperation}
        user={selectedUser}
        addUser={usersApi.postOne}
        updateUser={usersApi.putOne}
      />
    </>
  );
};

export default UsersPage;
