import React, { createContext, useContext, useCallback } from "react";
import { useSnackbar } from "notistack";

const MessageContext = createContext<{
  showMessage: (message: string) => void;
}>({ showMessage: (message: string) => {} });

export const MessageService = ({ children }: { children?: any }) => {
  const { enqueueSnackbar } = useSnackbar();
  const showMessage = useCallback(
    (message: string) => enqueueSnackbar(message),
    [enqueueSnackbar]
  );
  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

/**
 * The function provided by this hook (via context) will display a pop-up-like message
 */
export const useMessage = () => {
  const val = useContext(MessageContext);
  return val;
};
