import { THEME_VARIABLES } from "../themeColors";

export enum FieldStatus {
  Stopped = 0,
  Running = 1
}
export const fieldStateDisplayColor = (position? : number, online? : boolean) =>
  online! || position == null 
    ? THEME_VARIABLES.COLOR_DARK_GRAY
    : position < 11.0 
    ? THEME_VARIABLES.COLOR_RED
    : position > 74.9 
    ? THEME_VARIABLES.COLOR_GREEN 
    : THEME_VARIABLES.COLOR_BLUE_1;
  

export const fieldStateDisplay = (position? : number, online? : boolean) =>
    online! || position == null 
      ? "Offline"
      : position < 11.0 
      ? "Closed"
      : position > 74.9 
      ? "Valve " + position + "% open" // could be changed to just open
      : position > 11.0 && position < 74.9
      ? "Valve " + position + "% open":
      "Unknown";

