import { BaseApi, ApiResult } from "./baseApi";

export class AuthApi extends BaseApi<{}> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "auth", logout, enqueueSnackbar);
  }

  public getToken = (
    username: string,
    password: string
  ): Promise<ApiResult<{ jwt: string; jwtExpiration: string }>> =>
    this.genericRequest({
      data: { username, password },
      extraPath: "token",
      method: "POST",
      ignoreRefreshFlow: true
    });

  public sendUsernameEmails = (userEmail: string) =>
    this.genericRequest({
      data: { userEmail },
      extraPath: "sendUsernameEmail",
      method: "POST",
      ignoreRefreshFlow: true
    });

  public sendPasswordResetEmails = (userIdentifier: string) =>
    this.genericRequest({
      data: { userIdentifier },
      extraPath: "sendPasswordResetEmail",
      method: "POST",
      ignoreRefreshFlow: true
    });

  public resetPassword = (
    username: string,
    code: string,
    newPassword: string
  ) =>
    this.genericRequest({
      method: "POST",
      extraPath: "resetPassword",
      data: { username, code, newPassword },
      ignoreRefreshFlow: true
    });
}
