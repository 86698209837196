import { ApiResult, BaseApi } from "./baseApi";
import { Customer } from "../dtos/customer";

export class CustomersApi extends BaseApi<Customer> {
  constructor(baseUri: string, enqueueSnackbar?: any, logout?: () => void) {
    super(baseUri, "customers", logout, enqueueSnackbar);
  }

  public getAllCustomers = (): Promise<ApiResult<Customer[]>> =>
    this.genericRequest({ method: "GET", ignoreRefreshFlow: true });

  public createCustomer = (customer: Customer): Promise<any> =>
    this.genericRequest({ method: "POST", data: customer, ignoreRefreshFlow: true });
}
