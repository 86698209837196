import React, { useState, useEffect, useRef } from "react";
import { makeStyles, ThemeOptions } from "@material-ui/core/styles";
import { HistoricalDataChart } from "../shared/historicalDataChart";
import { SensorSelector } from "./sensorSelector";
import { Sensor } from "../../dtos/sensor";
import { AutoTecData } from "../../dtos/autoTecData";
import { Field } from "../../dtos/field";
import { ChartSettings } from "../../dtos/chartSettings";
import { useParams } from "react-router";
import { useApi } from "../apiService";
import { AjaxResult } from "../../enums/ajaxResult";
import * as Mui from "@material-ui/core";
import { useMessage } from "../messageService";
import CircularProgress from '@material-ui/core/CircularProgress';
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PumpCommandsModal from "./pumpCommandsModal";
import { unitStateDisplay, unitStateDisplayColor } from "../../enums/unitState";
import { PumpDataLog } from "../../dtos/pumpDataLog";
import { THEME_VARIABLES } from "../../themeColors";
import { useWindowWidth } from "@react-hook/window-size";
import * as linq from "linq";
import { getFieldSensorUnitsLabel } from "../../utility/getFieldSensorUnitsLabel";
import { useMemo } from "react";
import { FieldExportSummary } from "../../dtos/fieldExportSummary";

interface FieldPageData {
  sensors: Sensor[];
  chartSettings: ChartSettings[];
  historicalData: AutoTecData[];
  exportSummary: FieldExportSummary | null;
}

interface DownloadFile {
  data: any;
  fileName: string;
  fileType: string;
}

const MAXIMUM_SELECTED = 4;

export const FieldPage = () => {
  let { fieldId, pumpId } = useParams();
  const { fieldsApi, pumpsApi } = useApi();
  const { showMessage } = useMessage();

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [pumpCommandsModalIsVisible, setpumpCommandsModalIsVisible] = useState(
    false
  );

  const [pump, setPump] = useState<PumpDataLog | null>(null);
  const [selectedCount, setSelectedCount] = useState(0);

  const width = useWindowWidth();

  const classes = useStyles({
    pump: pump,
    width: width,
    unitStateDisplayColor: unitStateDisplayColor
  });

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  const [fieldInfo, setFieldInfo] = useState<Field>();
  const [activeSensors, setActiveSensors] = useState<Sensor[]>([]);
  const [exportType, setExportType] = useState<"ZIP" | "CSV" | "PDF" | undefined>();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [exportResultBlob, setExportResultBlob] = useState<Blob>();
  const [fieldPageData, setFieldPageData] = useState<FieldPageData>({
    sensors: [],
    chartSettings: [],
    historicalData: [],
    exportSummary: null
  });
  const [chartData, setChartData] = useState<{
    labels: string[];
    dataSets: {
      label: string;
      unitLabel: string;
      dataPoints: any[];
    }[];
  }>();

  const anchorRefExport = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [disableExport, setDisableExport] = useState(true);


  // Selected Sensor list used for counting, ordering sensor types (max 4 allowed)
  const selectedSensors = useMemo(()=> {
    return linq.from(fieldPageData.chartSettings)
      .join(fieldPageData.sensors,
        c=> c.sensorId,
        s=> s.id,
        (c,s) => ({chartSetting:c, sensor:s})).toArray();
  },[fieldPageData.sensors,fieldPageData.chartSettings]);

  const updateSelection = (sensorId: number, sensorType: number) => {
    var selected = !fieldPageData.chartSettings.find(
      c => c.sensorId === sensorId
    );

    if (selected) {
      //check if sensor type already exists. If it does not, then isNewType = true
      var isNewType = !selectedSensors.find(
        s=> s.sensor.type === sensorType && s.sensor.id !== sensorId
      );
      if (isNewType){
        // selectedCount is updated through a useeffect
        if (selectedCount >= MAXIMUM_SELECTED) {
          showMessage("WARNING: You can only select up to 4 sensor types at a time.");
          return;
        }
      }
    }

    // Set client-side chart settings
    var chartSettings: ChartSettings[] = [...fieldPageData.chartSettings];
    if (selected) {
      chartSettings.push({
        pumpId: Number(pumpId),
        pumpSensorId: null,
        sensorId: sensorId,
        userId: null,
        valveId: null
      });
    } else {
      chartSettings = linq
        .from(chartSettings)
        .where(c => c.sensorId !== sensorId)
        .toArray();
    }

    setFieldPageData({
      ...fieldPageData,
      chartSettings: chartSettings
    });

    // Send chart settings to backend
    fieldsApi.updateChartSetting(Number(fieldId), sensorId, selected);
  };

  const updateDateSelection = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getSensors = () => {
    if (fieldId && startDate !== null && endDate !== null) {
      try {
        showMessage("Fetching Sensors...");
        fieldsApi
          .updateFieldPageData(Number(fieldId), startDate, endDate)
          .then(r => {
            if (r.result === AjaxResult.Success && r.data) {
              var orderedSensors = r.data.sensors.sort(function(a: Sensor, b: Sensor) {

                // Average always needs to be on the top so
                // always return 1 if the name is Average
                // so it bubbles to the top
                if (b.name.trim() === 'Average') {
                  return 1;
                }

                return a.name.trim().localeCompare(b.name.trim(), undefined, {
                  numeric: true,
                  sensitivity: 'base'
                });
              });

              setFieldPageData({
                sensors: orderedSensors,
                historicalData: r.data.historicalData,
                chartSettings: r.data.chartSettings,
                exportSummary: r.data.exportSummary
              });
              showMessage("Sensors Fetched.");
              if(fieldInfo) {
                setDisableExport(false);
              }
            }
          });
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  useEffect(() => {
    getSensors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, startDate, endDate]);

  useEffect(() => {
    const getField = () => {
      if (fieldId) {
        showMessage("Fetching Info...");
        fieldsApi.getField(Number(fieldId)).then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            setFieldInfo(r.data);
            if(fieldPageData) {
              setDisableExport(false);
            }
            showMessage("Field Info Fetched.");
          }
        });
      }
    };

    getField();
  }, [fieldId, showMessage, fieldsApi, fieldPageData]);

  useEffect(() => {
    const getPump = () => {
      if (pumpId) {
        showMessage("Fetching pump information...");
        pumpsApi.getPumpDataLogById(Number(pumpId)).then(r => {
          if (r.result === AjaxResult.Success && r.data) {
            showMessage("Pump information fetched!");
            setPump(r.data);
          }
        });
      }
    };
    getPump();
  }, [pumpId, showMessage, pumpsApi]);

  // Update count of selected to actual data when received
  useEffect(() => {
    setSelectedCount(
      // Count all distinct, non-null sensor types
      linq.from(selectedSensors).where(s=> s.sensor.type !== null).distinct(s=>s.sensor.type).count()
      +
      // count all null sensors individually
      linq.from(selectedSensors).where(s=>s.sensor.type===null).count());
  }, [selectedSensors]);

  useEffect(() => {
    //create sensor list
    setActiveSensors(
      fieldPageData.sensors.filter(s =>
        fieldPageData.chartSettings.find(c => c.sensorId === s.id)
      )
    );
    const sensorList = fieldPageData.sensors.filter(s =>
      fieldPageData.chartSettings.find(c => c.sensorId === s.id)
    );
    // list of unique timestamps
    const dateStrings = Array.from(
      new Set([
        ...fieldPageData.historicalData
          .filter(
            h =>
              !!h.timestamp &&
              !!sensorList.find(
                s => s.serial === h.serial && s.channel === h.channel
              )
          )
          .map(h => new Date(h.timestamp || "").getTime())
      ])
    );
    const dates = dateStrings.map(d => new Date(d));
    dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    // Check that sesor data has entry for each time. If not, use previous
    let sensorData = sensorList.map(s => ({
      label: s.name,
      unitLabel: getFieldSensorUnitsLabel(s.type, s.name),
      type: s.type,
      channel: s.channel,
      serial: s.serial,
      dataPoints: dates.map(d => {
        const dataPoint = fieldPageData.historicalData.find(
          h =>
            h.serial === s.serial &&
            h.channel === s.channel &&
            h.timestamp != null &&
            new Date(h.timestamp).getTime() === d.getTime()
        );
        if (!!dataPoint) {
          return dataPoint.engData || 0;
        } else {
          const relevantDataPoints = fieldPageData.historicalData
            .filter(h => h.serial === s.serial && s.channel === h.channel)
            .sort(
              (a, b) =>
                new Date(a.timestamp || "").getTime() -
                new Date(b.timestamp || "").getTime()
            );
          let pointBefore: any;
          let pointAfter: any;
          relevantDataPoints.forEach(r => {
            if (new Date(r.timestamp || "").getTime() < d.getTime()) {
              pointBefore = r;
            }
            if (
              new Date(r.timestamp || "").getTime() > d.getTime() &&
              !pointAfter
            ) {
              pointAfter = r;
            }
          });

          return (
            (!!pointBefore && pointBefore.engData) ||
            (!!pointAfter && pointAfter.engData) ||
            0
          );
        }
      })
    }));

    var orderedSensorData: {
      label: string;
      unitLabel: string;
      dataPoints: any[];
    }[] = [];

    var orderedSensorIds = linq
      .from(fieldPageData.chartSettings)
      .join(
        fieldPageData.sensors,
        c => c.sensorId,
        s => s.id,
        (c, s) => ({
          sortOrder: c.id,
          sensorId: c.sensorId,
          serial: s.serial,
          channel: s.channel,
          type: s.type
        })
      )
      .orderBy(s => s.sortOrder)
      .toArray();

    var types : number[] = []; // create blank array to check types
    orderedSensorIds.forEach((sensor, i) => {
      var data = sensorData.find(
        s => s.serial === sensor.serial && s.channel === sensor.channel
      );
      var type = sensor.type || (sensor.sensorId ||0)%100 + 100; // use sensorId + 100 to created unique number of type counts
      var j : number;
      if (!types.includes(type)) { //new type, add to array
        j = types.push(type)-1;
      } else {
        j = types.indexOf(type); // only type, use existing array index
      }
      if (data) {
        data = { ...data };
        data.label = `${data.label} (${j + 1})`; // Becomes Top Label in chart
        data.unitLabel = `${data.unitLabel} (${j + 1})`; // Becomes Y-axis label
        orderedSensorData.push(data);
      }
    });

    setChartData({
      labels: [
        ...dates.map(d => `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`)
      ],
      dataSets: orderedSensorData
    });
  }, [fieldPageData]);

  const handleExportToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleExportClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRefExport.current && anchorRefExport.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleExportListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRefExport.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const downloadFile = ({ data, fileName, fileType }: DownloadFile) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const getDownloadFileName = (): string => {
    var fieldName = fieldInfo?.name;
    var sensorName = '';
    if(selectedSensors.length === 1) {
      sensorName = `-${selectedSensors[0].sensor.name}`;
    }
    return fieldName ? `${fieldName}${sensorName}`.replace(/\.|\/|\\/g,'_') : "historicalData";
  }

  const getImage = (): string | null => {
    var chartCanvasEl = document.getElementsByClassName('chartjs-render-monitor');
    if(chartCanvasEl && chartCanvasEl.length === 1) {
      var canvas = chartCanvasEl[0] as HTMLCanvasElement;
      return canvas.toDataURL("image/png");
    }
    return null;
  }

  const handleImageExport = (event: React.MouseEvent<EventTarget>) => {
    var base64data = getImage();

    if(base64data) {
      var a = document.createElement('a');
      a.href = base64data.toString();
      let imageName = `${getDownloadFileName()}.png`;
      a.download = imageName;
      a.click();
    }
    setOpen(false);
  }

  const handleCSVExport = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault();
    setExportType("CSV");
  }

  const handlePDFExport = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault();
    setExportType("PDF");
  };

  const handleZipExport = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault();
    setExportType("ZIP");
  };

  useEffect(() => {
    if (exportType) {
      setOpen(false);
      showMessage("Fetching export data. This may take a few minutes depending on size of data set.");
      setLoadingExport(true);

      let pumpName = fieldInfo?.pumpName ?? "";
      let fieldName = fieldInfo?.name ?? "";
      let imgString = exportType === "ZIP" ? getImage()?.split(';base64,')[1] ?? null : null; //split because image scheme is at the beginning, but we want only base 64 data here

      fieldsApi.getFullFieldHisoricalData(Number(fieldId), fieldName, pumpName, startDate, endDate, getDownloadFileName(), imgString, activeSensors.map(x => x.id), exportType, fieldPageData.exportSummary)
        .then(r => {
          console.log(r.data)
          if (r.result === AjaxResult.Success && r.data) {
            setExportResultBlob(r.data);
          }
          else {
            showMessage("Error retrieving data from server.");
            setLoadingExport(false);
            setExportType(undefined);
          }
        })
        .catch(error => console.log(error));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportType]);

  useEffect(() => {
    try {
      if (exportType && exportResultBlob) {
        let downloadName = `${getDownloadFileName()}.${exportType.toLocaleLowerCase()}`;

        downloadFile({
          data: exportResultBlob,
          fileName: downloadName,
          fileType: exportResultBlob.type
        });
      }
    }
    finally {
      setLoadingExport(false);
      setOpen(false);
      setExportType(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportResultBlob])

  return (
    <div className={classes.fieldPageContainer}>
      <div className={classes.historicalChartContainer}>
        <div className={classes.headerContainer}>
          <Mui.Grid container spacing={1}>
            <Mui.Grid item xs={12} sm={7} md={7}>
              <h2>{`${fieldInfo?.name}`}</h2>
              {(fieldPageData?.exportSummary?.totalAcreInchesAcres ||
                fieldInfo?.inchWater || (fieldPageData?.exportSummary?.flowSensorTotals &&
                  fieldPageData.exportSummary.flowSensorTotals.length === 1)
              ) ?
                <div style={{ display: "flex", marginBottom: "18px", }}>
                  {(fieldPageData?.exportSummary?.flowSensorTotals &&
                    fieldPageData.exportSummary.flowSensorTotals.length === 1) ? (
                    <Mui.Tooltip title="For current date range">
                      <h3 style={{ margin: "0px"}}>
                        {"Total Acre-Inch Water: " + (fieldPageData.exportSummary.flowSensorTotals[0].totalAcreInchesAcres.toFixed(2))}
                      </h3>
                    </Mui.Tooltip>
                  ) : (fieldPageData?.exportSummary?.totalAcreInchesAcres) ?
                    (<Mui.Tooltip title="For current date range">
                      <h3 style={{ margin: "0px"}}>
                        {"Total Acre-Inch Water: " + (fieldPageData?.exportSummary?.totalAcreInchesAcres).toFixed(2)}
                      </h3>
                    </Mui.Tooltip>
                    ) : null}
                  {fieldInfo?.inchWater ? (
                    <Mui.Tooltip title="Since valve was last closed">
                      <h3 style={{ margin: "0px", paddingLeft: "15px"}}>
                        {"Acre-Inch Water: " + (fieldInfo?.inchWater).toFixed(2)}
                      </h3>
                    </Mui.Tooltip>
                  ) : null}
                </div>
                : "" }
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={5} md={5}>
              <div className={classes.headerButtons}>
                <Mui.Button
                  ref={anchorRefExport}
                  aria-controls={open ? 'export-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleExportToggle}
                  variant="contained"
                  className={classes.exportButton}
                  endIcon={<SystemUpdateAltIcon />}
                  startIcon={loadingExport ? <CircularProgress style={{ width: 20, height: 20 }} /> : null}
                  disabled={disableExport || loadingExport}
                >
                  Export
                </Mui.Button>
                <Mui.Popper
                  open={open}
                  anchorEl={anchorRefExport.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{zIndex: 20}}
                >
                  {({ TransitionProps, placement}) => (
                    <Mui.Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                      <Mui.Paper>
                        <Mui.ClickAwayListener onClickAway={handleExportClose}>
                          <Mui.MenuList autoFocusItem={open} id="export-list-grow" onKeyDown={handleExportListKeyDown}>
                            <Mui.MenuItem onClick={handleImageExport}>Chart Image</Mui.MenuItem>
                            <Mui.MenuItem onClick={handleCSVExport} disabled={fieldPageData.historicalData.length > 0 ? false : true}>CSV</Mui.MenuItem>
                            <Mui.MenuItem onClick={handlePDFExport} disabled={fieldPageData.historicalData.length > 0 || (fieldPageData.exportSummary && fieldPageData.exportSummary.waterEvents.length > 0) ? false : true}>PDF</Mui.MenuItem>
                            <Mui.MenuItem onClick={handleZipExport} disabled={fieldPageData.historicalData.length > 0 || (fieldPageData.exportSummary && fieldPageData.exportSummary.waterEvents.length > 0) ? false : true}>ZIP</Mui.MenuItem>
                          </Mui.MenuList>
                        </Mui.ClickAwayListener>
                      </Mui.Paper>
                    </Mui.Grow>
                  )}
                </Mui.Popper>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => setpumpCommandsModalIsVisible(true)}
                  className={classes.pumpCommandsButton}
                  startIcon={
                    <FiberManualRecordIcon className={classes.pumpStatus} />
                  }
                >
                  {`Pump ${
                    pump && pump.unitData
                    ? unitStateDisplay(pump.unitData?.state)
                    : "Commands"
                    }`}
                </Mui.Button>
              </div>
            </Mui.Grid>
          </Mui.Grid>
        </div>
        {chartData && (
          <HistoricalDataChart
            chartData={chartData}
            updateDateSelection={updateDateSelection}
          />
        )}
      </div>
      <div className={classes.sensorSelectorContainer}>
        <SensorSelector
          sensors={fieldPageData.sensors}
          chartSettings={fieldPageData.chartSettings}
          updateSelection={updateSelection}
          refreshSensorData={getSensors}
        />
      </div>
      <PumpCommandsModal
        isVisible={pumpCommandsModalIsVisible}
        pump={pump ? pump.pump : null}
        fieldNumber={fieldInfo ? fieldInfo.pumpChannel : 0}
        close={() => setpumpCommandsModalIsVisible(false)}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
  fieldPageContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerButtons: {
    display: "flex",
    maxWidth: "900px",
    justifySelf: "center",
    zIndex: 10,
    justifyContent: "flex-end",
    marginTop: "1em",
    marginBottom: theme.standardMargin,
    "& div": {
      marginLeft: ".5em"
    },
    "& button": {
      marginLeft: ".5em"
    }
  },
  historicalChartContainer: {
    minWidth: (props: any) =>
      theme.minWidthFullScreen && props.width < theme.minWidthFullScreen
        ? "100%"
        : "60%",
    marginRight: "25px"
  },
  sensorSelectorContainer: {
    flex: "1"
  },
  exportLink: { textDecoration: "none", width: "100%", color: 'inherit' },
  exportButton: {
    backgroundColor: theme.mainBackgroundColor,
    color: theme.selectedItemColor,
    textTransform: "none"
  },
  pumpCommandsButton: {
    backgroundColor: theme.selectedItemColor,
    color: theme.whiteColor,
    textTransform: "none"
  },
  pumpStatus: {
    color: (props: any) =>
      `${
        props.pump && props.pump.unitData
        ? props.unitStateDisplayColor(props.pump.unitData?.state)
        : THEME_VARIABLES.COLOR_RED
      }`
  }
}));
