import React, { useState } from "react";
import * as Mui from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import pumpIconGreen from "../../images/pump-green.png";
import pumpIconYellow from "../../images/pump-yellow.png";
import pumpIconRed from "../../images/pump-red.png";
import pumpIconBlue from "../../images/pump-blue.png";
import pumpIconGray from "../../images/pump-gray.png";
import radioIconBlue from "../../images/radio-blue.png";
import radioIconRed from "../../images/radio-red.png";
import radioIconGreen from "../../images/radio-green.png";
import radioIconGray from "../../images/radio-gray.png";
import valveIconBlue from "../../images/valve-blue.png";
import valveIconGreen from "../../images/valve-green.png";
import valveIconGray from "../../images/valve-gray.png";
import valveIconRed from "../../images/valve-red.png";
import {
  unitStateDisplay,
  unitStateDisplayColor,
  UnitState
} from "../../enums/unitState";
import { Redirect } from "react-router";
import { ThemeOptions } from "@material-ui/core";
import { MarkerCategory } from "../../enums/markerCategory";
import { sensorStateDisplay, sensorStateDisplayColor } from "../../enums/sensorAlarmStatus";
import { THEME_VARIABLES } from "../../themeColors";
import { fieldStateDisplay, fieldStateDisplayColor } from "../../enums/fieldStatus";

export const MapMarker = ({
  lat,
  lng,
  id,
  name,
  serialNumber,
  markerImageType,
  category,
  status,
  unitState,
  online,
  currentEngData,
  fuelLevel
}: {
  lat: number;
  lng: number;
  id: number;
  name: string;
  serialNumber: number;
  markerImageType: number;
  category: MarkerCategory;
  status: number | null;
  unitState: UnitState | undefined;
  online: boolean | null;
  currentEngData: number | null;
  fuelLevel: number | null;
}) => {
  const [redirectActive, setRedirectActive] = useState(false);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openHandler = () => {
    setTooltipIsOpen(true);
  };
  const statusColor = unitStateDisplayColor(unitState);
  const sensorColor = sensorStateDisplayColor(status !== null ? status : 0);
  const fieldColor = fieldStateDisplayColor(currentEngData !== null
    ? currentEngData : undefined,
    online !== null ? online : false);

  const classes = useStyles();

  var mapMarkerSource =
    markerImageType !== 99 && markerImageType !== 98 && statusColor === THEME_VARIABLES.COLOR_BLUE_1 // change Icon based on status color
      ? pumpIconBlue
      : markerImageType !== 99 && markerImageType !== 98 && statusColor === THEME_VARIABLES.COLOR_GREEN
        ? pumpIconGreen
        : markerImageType !== 99 && markerImageType !== 98 && statusColor === THEME_VARIABLES.COLOR_YELLOW
          ? pumpIconYellow
          : markerImageType !== 99 && markerImageType !== 98 &&
            statusColor === THEME_VARIABLES.COLOR_DARK_GRAY
            ? pumpIconGray
            : markerImageType !== 99 && markerImageType !== 98 //Red and all other cases
              ? pumpIconRed
              : markerImageType === 98 && fieldColor === THEME_VARIABLES.COLOR_DARK_GRAY
                ? valveIconGray // valve comms lost disconnected or no position
                : markerImageType === 98 && fieldColor === THEME_VARIABLES.COLOR_RED
                  ? valveIconRed // position is less than 11 (closed)
                  : markerImageType === 98 && fieldColor === THEME_VARIABLES.COLOR_GREEN
                    ? valveIconGreen // valve is open
                    : markerImageType === 98 && fieldColor === THEME_VARIABLES.COLOR_BLUE_1
                      ? valveIconBlue // No valve position does not make sense 11 - 75
                      : markerImageType === 99 && sensorColor === THEME_VARIABLES.COLOR_DARK_GRAY
                        ? radioIconGray // Not online
                        : sensorColor === THEME_VARIABLES.COLOR_GREEN
                          ? radioIconGreen
                          : sensorColor === THEME_VARIABLES.COLOR_BLUE_1
                            ? radioIconBlue
                            : sensorColor === THEME_VARIABLES.COLOR_RED
                              ? radioIconRed
                              : radioIconGray; // No Data

  return (
    <>
      {redirectActive && <Redirect to={`/pumps/${id}`} />}
      <Mui.Tooltip
        interactive
        open={tooltipIsOpen}
        onClick={openHandler}
        onDoubleClick={() =>
          category === MarkerCategory.Pump && setRedirectActive(true)
        }
        title={
          <div>
            <div className={classes.toolTipHeader}>
              <Mui.IconButton
                className={classes.closeIcon}
                onClick={() => setTooltipIsOpen(false)}
              >
                <CloseIcon fontSize={"small"} />
              </Mui.IconButton>
            </div>
            <div className={classes.toolTipContent}>
              <p>Name: {name}</p>
              <p>SN#: {serialNumber}</p>
              <p>
                Status:{" "}
                {(category === MarkerCategory.Pump && (
                  <span>{unitStateDisplay(unitState)}</span>
                )) ||
                  (category === MarkerCategory.Field && (
                    <span color={
                      fieldColor === THEME_VARIABLES.COLOR_DARK_GRAY ?
                        THEME_VARIABLES.COLOR_WHITE :
                        fieldColor
                    }
                    >{fieldStateDisplay(currentEngData !== null ? currentEngData : undefined, online !== null ? online : false)}
                    </span>
                  )) ||
                  (category === MarkerCategory.Sensor && (
                    <span color={
                      sensorColor === THEME_VARIABLES.COLOR_DARK_GRAY ?
                        THEME_VARIABLES.COLOR_WHITE :
                        sensorColor
                    }
                    >
                      {sensorStateDisplay}
                    </span>
                  ))}
              </p>
              {currentEngData !== null && <p> Current Data: {currentEngData}</p>}
              {fuelLevel != null &&
                <p>Fuel Level: {fuelLevel}%</p>
              }
            </div>
          </div>
        }
      >
        <img
          style={{
            position: "relative",
            right: "26px",
            bottom: "52px"
          }}
          src={mapMarkerSource}
          alt="map marker"
        />
      </Mui.Tooltip>
    </>
  );
};

const useStyles = Mui.makeStyles((theme: ThemeOptions) => ({
  closeIcon: {
    padding: 0,
    margin: 0,
    "& svg": {
      color: theme.whiteColor
    }
  },
  toolTipHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  toolTipContent: {
    padding: "0em 1em"
  },
  redStatus: {
    color: THEME_VARIABLES.COLOR_RED
  },
  yellowStatus: {
    color: THEME_VARIABLES.COLOR_YELLOW
  },
  blueStatus: {
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  greenStatus: {
    color: THEME_VARIABLES.COLOR_GREEN
  },
  grayStatus: {
    color: THEME_VARIABLES.COLOR_WHITE
  },
  whiteStatus: {
    color: THEME_VARIABLES.COLOR_WHITE
  }
}));
