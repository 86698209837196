import { THEME_VARIABLES } from "../themeColors";
//import { FormatListNumberedRounded } from "@material-ui/icons";
//import linq from "linq";

export enum UnitState {
  Idle = 0,
  Running = 1,
  Startup = 2,
  WarmUp = 3,
  Cooldown = 4,
  Lockout = 5,
  EmergencySwitchActivated = 6,
  Charging = 7,
  LostComms = 8,
  IgnitionOff = 9,
  SolarDripEnabled = 10,
  BackflushDelayActive = 11,
  LostPower = 50,
  CommsTimeout = 100,
  OutofService = 127
}

const valueMap = {
  [UnitState.Idle]: { name: "Idle", color: THEME_VARIABLES.COLOR_BLUE_1 },
  [UnitState.Running]: { name: "Running", color: THEME_VARIABLES.COLOR_GREEN },
  [UnitState.Startup]: { name: "Startup", color: THEME_VARIABLES.COLOR_BLUE_1 },
  [UnitState.WarmUp]: { name: "Warm Up", color: THEME_VARIABLES.COLOR_BLUE_1 },
  [UnitState.Cooldown]: {
    name: "Cooldown",
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [UnitState.Lockout]: { name: "Lockout", color: THEME_VARIABLES.COLOR_BLUE_1 },
  [UnitState.EmergencySwitchActivated]: {
    name: "ESTOP Activated",
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [UnitState.Charging]: {
    name: "Charging",
    color: THEME_VARIABLES.COLOR_GREEN
  },
  [UnitState.LostComms]: {
    name: "Modbus Timeout",
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [UnitState.IgnitionOff]: {
    name: "Ignition Off",
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [UnitState.SolarDripEnabled]: {
    name: "Solar Drip Enabled",
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [UnitState.BackflushDelayActive]: {
    name: "Backflush Delay",
    color: THEME_VARIABLES.COLOR_BLUE_1
  },
  [UnitState.LostPower]: {
    name: "Lost Power",
    color: THEME_VARIABLES.COLOR_YELLOW
  },
  [UnitState.CommsTimeout]: {
    name: "Comms Timeout",
    color: THEME_VARIABLES.COLOR_DARK_GRAY
  },
  [UnitState.OutofService]: {
    name: "Out of Service",
    color: THEME_VARIABLES.COLOR_DARK_GRAY
  }
};

export const unitStateDisplay = (state?: UnitState) =>
  state !== undefined && state !== null && valueMap[state]
    ? valueMap[state].name
    : state
    ? "State: " + state
    : "Unknown";

export const unitStateDisplayColor = (state?: UnitState) =>
  state !== undefined && state !== null && valueMap[state]
    ? valueMap[state].color
    : THEME_VARIABLES.COLOR_RED;
